import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import ScoreCard from "../assets/images/score-card.svg";
import avtarImg from "../assets/images/avtarImg.png";
import trophyIcon from "../assets/images/trophyIcon.png";
import backArrow from "../assets/images/backArrow.png";
import closeIconDark from "../assets/images/closeIconDark.png";
import tabIcon1 from "../assets/images/tabIcon1.png";
import noteIcon from "../assets/images/noteIcon.png";
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import logoVerticaleTheme from "../assets/images/logoVerticaleTheme.png";
import scoreCardModalImg from "../assets/images/scoreCardModalImg.png";
import golfIcon from "../assets/images/golfIcon.png";
import shareIcon from "../assets/images/shareIcon.png";
import golfIconDark from "../assets/images/golfIconDark.png";
import handicapRangeIcon from "../assets/images/handicapRangeIcon.png";
import { useNavigate, useLocation } from "react-router-dom";

function Stats() {

  const navigate = useNavigate();

  const [show, scoreCardStepModal] = useState(false);
  const scoreCardStepModalClose = () => scoreCardStepModal(false);
  const scoreCardStepModalOpen = () => scoreCardStepModal(true);
  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3">
          <div className="col-lg-12">
            <div className="pagreHeader">
              <div className="pageHeadign d-flex align-items-center gap-3">
                <button onClick={() => navigate("/career")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                  <img className="h-100" src={backArrow} />
                </button>
                <div className="txt">Stats</div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row g-3">
              <div className="col-xxl-8">
                <div className="sticky-post statOuter bg-white rounded-3 position-relative top-0 flex-row justify-content-start">
                  <div className="inner w-100 d-flex flex-column gap-4">
                    <div className="stickyPostTrophy">
                      <div className="stickyPostTrophyHeader mb-3 d-flex align-items-center justify-content-between w-100">
                        <div className="title">Records Per Round</div>
                      </div>
                      <div className="stickyPostTrophyOuter stat">
                        <div className="statInner d-flex flex-nowrap overflow-auto gap-12 align-items-center">
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Best Score</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Best Round</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Best Net</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Best Putts</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Best GIR%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="stickyPostTrophy">
                      <div className="stickyPostTrophyHeader mb-3 d-flex align-items-center justify-content-between w-100">
                        <div className="title">Historic Achievements</div>
                      </div>
                      <div className="stickyPostTrophyOuter stat">
                        <div className="statInner d-flex flex-nowrap overflow-auto gap-12 align-items-center">
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Hole in one</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Albatross</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Eagle</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Birdies</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Bogey Free</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Eagle</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Birdies</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Bogey Free</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="stickyPostTrophy">
                      <div className="stickyPostTrophyHeader mb-3 d-flex align-items-center justify-content-between w-100">
                        <div className="title">Accumulated Stats</div>
                      </div>
                      <div className="stickyPostTrophyOuter stat">
                        <div className="statInner d-flex flex-nowrap overflow-auto gap-12 align-items-center">
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Round</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">60’s Score</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">70’s Score</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">80’s Score</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">90’s Score</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="stickyPostTrophy">
                      <div className="stickyPostTrophyHeader mb-3 d-flex align-items-center justify-content-between w-100">
                        <div className="title">Most Per Round</div>
                      </div>
                      <div className="stickyPostTrophyOuter stat">
                        <div className="statInner d-flex flex-nowrap overflow-auto gap-12 align-items-center">
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Pars</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Birdies</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Eagles</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Eagles</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Par Saves</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Eagles</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">1-Putts</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Par Saves</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Par Saves</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Eagles</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">1-Putts</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Par Saves</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="stickyPostTrophy">
                      <div className="stickyPostTrophyHeader mb-3 d-flex align-items-center justify-content-between w-100">
                        <div className="title">Records Per Round</div>
                      </div>
                      <div className="stickyPostTrophyOuter stat">
                        <div className="statInner d-flex flex-nowrap overflow-auto gap-12 align-items-center">
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Best Score</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Best Round</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Best Net</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Best Putts</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Best GIR%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="stickyPostTrophy">
                      <div className="stickyPostTrophyHeader mb-3 d-flex align-items-center justify-content-between w-100">
                        <div className="title">Historic Achievements</div>
                      </div>
                      <div className="stickyPostTrophyOuter stat">
                        <div className="statInner d-flex flex-nowrap overflow-auto gap-12 align-items-center">
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Hole in one</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Albatross</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Eagle</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Birdies</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Bogey Free</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Eagle</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Birdies</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Bogey Free</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="stickyPostTrophy">
                      <div className="stickyPostTrophyHeader mb-3 d-flex align-items-center justify-content-between w-100">
                        <div className="title">Accumulated Stats</div>
                      </div>
                      <div className="stickyPostTrophyOuter stat">
                        <div className="statInner d-flex flex-nowrap overflow-auto gap-12 align-items-center">
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Round</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">60’s Score</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">70’s Score</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">80’s Score</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">90’s Score</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="stickyPostTrophy">
                      <div className="stickyPostTrophyHeader mb-3 d-flex align-items-center justify-content-between w-100">
                        <div className="title">Most Per Round</div>
                      </div>
                      <div className="stickyPostTrophyOuter stat">
                        <div className="statInner d-flex flex-nowrap overflow-auto gap-12 align-items-center">
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Pars</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Birdies</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Eagles</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Eagles</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Par Saves</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Eagles</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">1-Putts</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Par Saves</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Par Saves</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Eagles</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">1-Putts</div>
                          </div>
                          <div className="stickyPostTrophyItem text-center">
                            <div className="imgOuter mx-auto position-relative">
                              <img className="w-100" src={trophyIcon} />
                              <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">85</div>
                            </div>
                            <div className="title">Par Saves</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4">
                <div className="handicapRange p-3 bg-white rounded-3">
                  <div className="handicapRangeHeader pb-3 mb-3 border-bottom d-flex align-items-center justify-content-between">
                    <div className="text-theme1 lh-sm fs-6 fw-medium">Handicap Range</div>
                    <div className="text-theme1 lh-sm fs-6 fw-medium">24-26</div>
                  </div>
                  <div className="handicapRangeBody d-flex flex-column gap-12">
                    <div className="d-flex align-items-center gap-2">
                      <div className="imgOuter">
                        <img src={handicapRangeIcon} alt="Handicap Range Icon" />
                      </div>
                      <div className="">
                        <div className="fs-6 fw-medium text-dark">How to Read The Data</div>
                        <div className="dec text-muted fs-14">
                          Below we explain how to use this graph. To Manage your handicap, Visit{" "}
                          <a href="" className="text-decoration-underline">
                            My Handicap
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* Example Handicap Range Item */}
                    <div className="handicapRangeItem">
                      <div className="d-flex align-items-center gap-2 fs-6 fw-medium text-dark lh-sm">
                        <img src={golfIconDark} alt="Golf Icon" />
                        Handicap
                      </div>
                      <div className="dec fs-14 text-muted mt-1 lh-sm">
                        Handicap refers to a golfer's playing ability. The lower the handicap,
                        the better the player.
                      </div>
                    </div>

                    {/* Example Best Handicap Item */}
                    <div className="handicapRangeItem">
                      <div className="d-flex align-items-center gap-2 fs-6 fw-medium text-dark lh-sm">
                        <img src={golfIconDark} alt="Golf Icon" />
                        Best Handicap
                      </div>
                      <div className="dec fs-14 text-muted mt-1 lh-sm">
                        Best Handicap represents the best handicap score achieved by a player in a
                        certain period.
                      </div>
                    </div>

                    {/* Example Score Value Avg Item */}
                    <div className="handicapRangeItem">
                      <div className="d-flex align-items-center gap-2 fs-6 fw-medium text-dark lh-sm">
                        <img src={golfIconDark} alt="Golf Icon" />
                        Score Value Avg
                      </div>
                      <div className="dec fs-14 text-muted mt-1 lh-sm">
                        Score Value Avg represents the average score value obtained by a player
                        over multiple games.
                      </div>
                    </div>

                    {/* Example Anti Handicap Item */}
                    <div className="handicapRangeItem">
                      <div className="d-flex align-items-center gap-2 fs-6 fw-medium text-dark lh-sm">
                        <img src={golfIconDark} alt="Golf Icon" />
                        Anti Handicap
                      </div>
                      <div className="dec fs-14 text-muted mt-1 lh-sm">
                        Anti Handicap is a theoretical concept representing the opposite of a
                        player's handicap, used for comparison purposes.
                      </div>
                    </div>

                    {/* View Details Button */}
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btnStyle1 viewDtlBtn btnTheme1 btnWidth shadow-none px-sm-5"
                        style={{ width: "100%" }}
                      >
                        View Details
                      </button>
                    </div>
                  </div>;

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>

  );
}
export default Stats;