import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import ScoreCard from "../assets/images/score-card.svg";
import avtarImg from "../assets/images/avtarImg.png";
import trophyIcon from "../assets/images/trophyIcon.png";
import backArrow from "../assets/images/backArrow.png";
import closeIconDark from "../assets/images/closeIconDark.png";
import tabIcon1 from "../assets/images/tabIcon1.png";
import noteIcon from "../assets/images/noteIcon.png";
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import gameImg from "../assets/images/gameImg.png";
import { useNavigate, useLocation } from "react-router-dom";
import useRequest from "../hooks/useRequest";
function EventCreated2() {
  const { request } = useRequest();
  const location = useLocation();
  const gameDetailData = location?.state?.response

 const handleNextButtonClick = ()=>{
  const requestBody = {
    id:gameDetailData?._id,
    players:[gameDetailData?.user?._id]
  };
  request({
    url:`/app/game/joinPlayer`,
    method:"PUT",
    data:requestBody,
    onSuccess:(data)=>{
      console.log("player joined successfully",data);
      navigate("/players-setup",  { state: { response: data.data } })
    },
    onError:(error)=>{
      console.log("Error in joining player",error)
    }
  })
 }



  const navigate = useNavigate();
  return (
    <Main>
      <section className="home-wrap vh100 px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3 h-100 flex-column flex-nowrap">
          <div className="col-lg-12">
            <div className="pageHeadign d-flex align-items-center gap-3">
              <button onClick={()=>navigate("/event-created")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                <img className="h-100" src={backArrow}/>        
              </button> 
              <div className="txt">Event Created</div>
            </div>
          </div>
          <div className="col-12">
            <Form className="">
              <div className="tabsCardInner rounded-3 p-3 bg-white">
                <div className="row g-2">
                  <Form.Label className="col-12 mb-0">Course Details</Form.Label>
                  <Form.Group className="col-12">
                    <div className="eventcard p-0">
                      <div className="gameDetail d-flex align-items-center gap-2">
                        <div className="imgOuter">
                          <img className="w-100 h-100" src={gameImg}/>
                        </div>
                        <div className="detail">
                          <div className="gameTitle">{gameDetailData?.courseData?.name}</div>
                          <div className="gameScore d-flex align-items-center gap-1 my-1">{gameDetailData?.courseTee?.name}</div>
                          <div className="competitionSize">18 Hole</div>
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group className="col-sm-6">
                    <Form.Control className="sahdow-none" type="date" placeholder="MM/DD/YYYY" />
                  </Form.Group>
                  <Form.Label className="col-12 mb-0 mt-3">Tracking Options</Form.Label>
                  <Form.Group className="col-sm-6">
                    <Form.Select aria-label="Default select example">
                      <option>Select stat</option>
                      <option value="1">stat1</option>
                      <option value="2">stat2</option>
                      <option value="3">stat3</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="col-sm-6">
                    <Form.Select aria-label="Default select example">
                      <option>Select score</option>
                      <option value="1">Live score to</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="col-12">
                    <div className=" border rounded-3 px-3 py-2">
                      <Form.Check
                        className="switchCheck p-0 py-2 rounded-0 shadow-none mb-0 d-flex align-items-sm-center justify-content-between gap-2 h-100" style={{borderRadius:"6px"}}
                          type="switch"
                          id="practiceRound"
                          label={
                            <>
                            <div className="fs-6 fw-medium text-dark">Practice Round</div>
                            <div className="text-muted">Doesn’t count towards Handicap. Use when playing alone or offseason rounds.</div>
                            </>
                          }
                        />
                      <hr className="my-1 border"/>
                      <Form.Check
                        className="switchCheck p-0 py-2 rounded-0 shadow-none mb-0 d-flex align-items-sm-center justify-content-between gap-2 h-100" style={{borderRadius:"6px"}}
                          type="switch"
                          id="tournamentScore"
                          label={
                            <>
                            <div className="fs-6 fw-medium text-dark">Tournament Score</div>
                            <div className="text-muted">Turn on when playing in a tournament.</div>
                            </>
                          }
                        />
                        <hr className="my-1 border"/>
                        <Form.Check
                          className="switchCheck p-0 py-2 rounded-0 shadow-none mb-0 d-flex align-items-sm-center justify-content-between gap-2 h-100" style={{borderRadius:"6px"}}
                            type="switch"
                            id="gpsSecreen"
                            label={
                              <>
                              <div className="fs-6 fw-medium text-dark">Keep GPS Screen ON</div>
                              <div className="text-muted">This is the dummy Text.</div>
                              </>
                            }
                          />
                    </div>
                  </Form.Group>
                  <Form.Label className="col-12 mb-0 mt-3">Info Displayed</Form.Label>
                  <Form.Group className="col-lg-4 col-sm-6">
                    <Form.Select aria-label="Default select example">
                      <option>Select stat</option>
                      <option value="1">stat1</option>
                      <option value="2">stat2</option>
                      <option value="3">stat3</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="col-lg-4 col-sm-6">
                    <Form.Select aria-label="Default select example">
                      <option>Select distances</option>
                      <option value="1">10</option>
                      <option value="2">20</option>
                      <option value="3">30</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="col-lg-4 col-sm-6">
                    <Form.Select aria-label="Default select example">
                      <option>Total scores</option>
                      <option value="1">101</option>
                      <option value="2">102</option>
                      <option value="3">103</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="col-12 text-end mt-sm-4 mt-3">
                    <button type="button" onClick={handleNextButtonClick}className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-5">Next</button>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </Main>
  );
}



export default EventCreated2;
