import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import { AppContextProvider } from "./context/AppContext";
import React, { useContext, Suspense, lazy } from "react";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import Loader from "./components/Loader";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

// import Home from "./pages/Home";
// import Login from "./pages/Login";
// import SignUp from "./pages/SignUp";


import NotificationSetting from "./pages/NotificationSetting";
import Notification from "./pages/Notification";
import LeaderBoard from "./pages/LeaderBoard";
import PostScore from "./pages/PostScore";
import EventCreated from "./pages/EventCreated";
import EventCreated2 from "./pages/EventCreated2";
import PlayersSetup from "./pages/PlayersSetup";
import PlayersSetup2 from "./pages/PlayersSetup2";
import EventRyderFormatSetup from "./pages/EventRyderFormatSetup";
import RyderEventCreated from "./pages/RyderEventCreated";
import Career from "./pages/Career";
import Scorecard from "./pages/Scorecard";
import GameDetails from "./pages/GameDetails";
import Stats from "./pages/Stats";
import FriendsRanking from "./pages/FriendsRanking";
import TrophyRoom from "./pages/TrophyRoom";
import Chat from "./pages/Chat";
import Play from "./pages/Play";
import CurrentLeaderboard from "./pages/CurrentLeaderboard";
import ScoreKeeping from "./pages/ScoreKeeping";
import Courses from "./pages/Courses";
import AddScore from "./pages/AddScore";
import Handicap from "./pages/Handicap";
import Posts from "./pages/Post"
import Friends from "./pages/Friend";
import AllScoreData from "./pages/allScoreCard";
import Comment from "./pages/Comment";
import Terms from "./pages/Terms";


// Lazy load Home, Login, and SignUp components
const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Login />} />
      <Route path="/sign-up" element={<SignUp />} /> */}

      <Route path="/" element={
        <Suspense fallback={<Loader />}>
          <Login />
        </Suspense>
      } />
      <Route path="/sign-up" element={
        <Suspense fallback={<Loader />}>
          <SignUp />
        </Suspense>
      } />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      ></Route>



      <Route path="/post" element={<Posts />} />
      {/* <Route path="/" element={<Home />} /> */}
      <Route path="/" element={
          <Suspense fallback={<Loader />}>
            <Home />
          </Suspense>
        } />
      <Route path="/home" element={
          <Suspense fallback={<Loader />}>
            <Home />
          </Suspense>
        } />
      {/* <Route path="/home" element={<Home />} /> */}
      <Route path="/notification-setting" element={<NotificationSetting />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/leader-board" element={<LeaderBoard />} />
      <Route path="/post-score" element={<PostScore />} />
      <Route path="/event-created" element={<EventCreated />} />
      <Route path="/event-created2" element={<EventCreated2 />} />
      <Route path="/players-setup" element={<PlayersSetup />} />
      <Route path="/players-setup2" element={<PlayersSetup2 />} />
      <Route path="/event-ryder" element={<EventRyderFormatSetup />} />
      <Route path="/ryder-event" element={<RyderEventCreated />} />
      <Route path="/career" element={<Career />} />
      <Route path="/career-scorecard" element={<Scorecard />} />
      <Route path="/career-scorecard-detail" element={<GameDetails />} />
      <Route path="/career-stat" element={<Stats />} />
      <Route path="/career-friends-ranking" element={<FriendsRanking />} />
      <Route path="/trophy-room" element={<TrophyRoom />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/play" element={<Play />} />
      <Route path="/current-leaderboard" element={<CurrentLeaderboard />} />
      <Route path="/score-keeping/:gameId" element={<ScoreKeeping />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/add-score" element={<AddScore />} />
      <Route path="/handicap" element={<Handicap />} />
      <Route path="/friends" element={<Friends />} />
      <Route path="/scorecard" element={<Scorecard />} />
      <Route path="/gameDetail/:id" element={<GameDetails />} />
      <Route path="/allScoreData/:id" element={<AllScoreData />} />
      <Route path="/comment/:id" element={<Comment />} />
      <Route path="/terms" element={<Terms />} />
    </Routes>
  );
};

const Layout = () => {
  return (
    <>
      {" "}
      <Outlet />{" "}
    </>
  );
};

export default App;
