import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import {
    Row,
    Col,
    Button,
    message,
    Avatar,
    Input,
    Upload,
    Typography,
    Form,
    Modal,
    Select,
    Switch,
    Spin
} from "antd";

import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import { ShowToast, Severty } from "../../helper/toast";
import Notfound from "../../assets/images/not-found.png";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";

const { Option } = Select;


const S3_BUCKET = 'b2btobacco';
const REGION = 'us-east-1';
const ACCESS_KEY = 'AKIAXHA23FMK7MWDNCHL';
const SECRET_ACCESS_KEY = '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';

// const S3_BUCKET = "b2btobacco";
// const REGION = "us-east-1";
// const ACCESS_KEY = "AKIAXHA23FMK7QMDAILJ";
// const SECRET_ACCESS_KEY = "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";

const config = {
    dirName: "golf/profile",
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
};

const ProfileUpdateModal = ({ open, close }) => {
    const [yesterdayDate, setYesterdayDate] = useState(getYesterdayDate());
    const [form] = Form.useForm();
    const { request } = useRequest();
    const [loading, setLoading] = useState(false);
    const [show, personalDetailModal] = useState(false);
    const [formData, setFormData] = useState({
        subject: "",
        description: "",
    });
    const [courseList, setCourseList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [file, setFile] = useState([]);
    const [image, setImage] = useState("");
    const [profileData, setProfileData] = useState({
        gender: "",
        homeCourse: "",
        favCourse: "",
        country: "",
        name: "",
        dob: "",
        state: "",
        profilePic: "",
    });
    const [profile, setProfile] = useState({});
    const [countries, setCountries] = useState([]);
    // const [currentDate, setCurrentDate] = useState(getCurrentDate());
    const [state, setState] = useState([])

    function getYesterdayDate() {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
    
        const year = yesterday.getFullYear();
        let month = yesterday.getMonth() + 1;
        let day = yesterday.getDate();
    
        // Ensure month and day are formatted with leading zeros if necessary
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
    
        return `${year - 8}-${month}-${day}`; // Subtract 8 years from the year
      }

    //   function handlePChange(e) {
    //     setCurrentDate(e.target.value);
    //     // Handle any additional logic here if needed
    //   }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // const personalDetailModalClose = () => personalDetailModal(false);
    // const personalDetailopen = () => personalDetailModal(true);

    const handleRemove = (values) => {
        setFile([]);
        setImage('');
        let val = values
        console.log("val????????????????",val)
        form.setFieldValue({val:null})
    };

    useEffect(() => {
        request({
            url: `/common/getNewcourse?search=${(searchQuery)}`,
            method: "GET",
            onSuccess: (data) => {
                console.log(data);
                // ShowToast(data.message, Severty.SUCCESS)
                setCourseList(data.data.docs);
            },
            onError: (error) => {
                console.log(error);
                //ShowToast(error, Severty.ERROR);
            },
        });
    }, [searchQuery]);

    const handleSearchChange = (values) => {
        console.log(values.target.value, "values")
        setSearchQuery(values.target.value);
    };

    const handlePChange = (value) => {
        // console.log("check ",event)
        setProfileData({ ...profileData, profilePic: null });
      //  setCurrentDate(value);

    };

    const addProfile = (values) => {
        console.log("profileData", values);
        values.profilePic = values.image && file.length > 0 ? file[0].url : null
        request({
            url: "app/user/update-profile",
            method: "POST",
            data: values,
            onSuccess: (data) => {
                console.log("Profile updated successfully:", data);
                close()
                ShowToast("Profile updated successfully", Severty.SUCCESS);
                window.location.reload();
            },
            onError: (error) => {
                console.error("Error updating profile:", error);
                ShowToast("Error updating profile", Severty.ERROR);
            },
        });
    };

    useEffect(() => {
        setUserProfile();
        fetchCountry();
        console.log(open, "modal open<<<<<<<<<<<")
    }, []);

    const setUserProfile = () => {
        request({
            url: "app/user/get-profile",
            method: "GET",
            onSuccess: (data) => {
                console.log("Profile data:", data.data.name);
                form.setFieldsValue({ ...data.data });
                fetchState(data.data.country);
                                // data.data.profilePic && setFile([data.data.profilePic])
                if (data.data.profilePic) {
                    // Add the prefix to the profile picture URL
                    const profilePicUrl = `https://b2btobacco.s3.amazonaws.com/${data.data.profilePic}`;
                    setFile([{ url: profilePicUrl }]);
                }
                else {
                    // If profilePic is null, set the profile picture URL to use initials
                    const initials = data.data.name.split(' ').map(part => part.charAt(0)).join('').toUpperCase();
                    const profilePicUrl = `https://ui-avatars.com/api/?name=${encodeURIComponent(initials)}&size=256`;
                    setFile([{ url: profilePicUrl }]);
                }
                setProfile(data.data); // Assuming the API response contains the user profile data
            },
            onError: (error) => {
                console.log("Error fetching profile:", error);
            },
        });
    };


    const fetchCountry = () => {
        request({
            url: "/common/all-country",
            method: "GET",
            onSuccess: (data) => {
                setCountries(data.data.data);
            },
            onError: (error) => {
                console.log("Error fetching countries Data:", error);
            },
        });
    };

    const fetchState = (country_id) => {
        request({
            url: `common/state/${country_id}`,
            method: "GET",
            onSuccess: (data) => {
                setState(data.data.data);
            },
            onError: (error) => {
                console.log("Error fetching countries Data:", error);
            },
        });
    };
    const handleCountryChange = (value) => {
        // Fetch states based on the selected country
        fetchState(value);
    };

    const handleChange = async (event) => {
        const { file } = event;
        setFile([file]);

        uploadFile(file, config)
            .then((data) => {
                const fileData = {
                    uid: file.uid,
                    name: file.name,
                    status: "done",
                    url: data.key,
                    thumbUrl: data.location,
                };
                setFile([fileData]);
                setImage(data.location);
            })
            .catch((err) => console.error(err));
    };

    return (
        <>
            <Modal
                width={750}
                open={open}
                title="Profile"
                okText="Ok"
                onCancel={close}
                okButtonProps={{
                    form: "create",
                    htmlType: "submit",
                }}
                className="headerModals"
            >
                <Form
                    form={form}
                    onFinish={addProfile}
                    labelCol={{ span: 24 }}
                    id="create"
                >
                    <div className="profileImgOuter">
                        <Form.Item
                            name="image"
                            label=""
                        >
                            <Upload
                                listType="picture"
                                maxCount={1}
                                //   beforeUpload={beforeUpload}
                                fileList={file}
                                onRemove={handleRemove}
                                customRequest={handleChange}
                            >
                                {file.length > 0 ? null : (
                                    <div className="editi-icons"> 
                                    <Button className="uploadFileBtn editicon-up">
                                        <EditOutlined />
                                    </Button>
                                    </div>
                                )}
                                {/* <Button className="upload-btn" icon={<UploadOutlined />}>Upload</Button>  */}


                            </Upload>
                        </Form.Item>
                        <div className="imgPreview mt-3" style={{ maxHeight: "200px", overflow: "hidden" }}>
                            <img className=" up-img" src={Notfound} alt="Uploaded" style={{ maxWidth: "50px", maxHeight: "100%" }} />
                        </div>
                    </div>
                    <Form.Item className="mb-0 mt-3" label="Personal Details" >
                        <Row>
                            <Col span={24} md={12}>
                                <Form.Item
                                    name="name"
                                    label="Name"

                                    rules={[{ required: true, message: "Please enter your name" }]}
                                >
                                    <Input onChange={handlePChange} />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={12}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        { required: true, message: "Please enter your email" },
                                        { type: "email", message: "Please enter a valid email" },
                                    ]}
                                >
                                    <Input onChange={handlePChange} />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={12}>
                                <Form.Item
                                    name="dob"
                                    label="Date of Birth"
                                    rules={[{ required: true, message: "Please select your DOB" }]}
                                >
                                    <Input type="date"  max={yesterdayDate} onChange={handlePChange} />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={12}>
                                <Form.Item
                                    name="gender"
                                    label="Gender"
                                    rules={[{ required: true, message: "Please select your gender" }]}
                                >
                                    <Select onChange={handlePChange}>
                                        <Select.Option value="Male">Male</Select.Option>
                                        <Select.Option value="Female">Female</Select.Option>
                                        <Select.Option value="Other">Other</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item className="mb-0 mt-3" label="Address Details">
                        <Row>
                            <Col span={24} md={12}>
                                <Form.Item
                                    name="country"
                                    label="Country"
                                    rules={[
                                        { required: true, message: "Please select your country" },
                                    ]}
                                >
                                    <Select
                                        showSearch  // Enable search functionality
                                        optionFilterProp="children"  // Specify which property of options will be used for filtering
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }  // Custom filter function
                                        onChange={handleCountryChange}
                                    >
                                        {countries?.map((course) => (
                                            <Option key={course._id} value={course.name}>
                                                {course.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24} md={12}>
                                <Form.Item
                                    name="state"
                                    label="State"
                                    rules={[
                                        { required: true, message: "Please enter your state" },
                                    ]}
                                >
                                    <Select
                                        showSearch  // Enable search functionality
                                        optionFilterProp="children"  // Specify which property of options will be used for filtering
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        } 
                                    >
                                        {state
                                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort the state array alphabetically
                                            .map((state) => (
                                                <Option key={state._id} value={state.name}>
                                                    {state.name}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item className="mb-0 mt-3" label="Preferences">
                        <Row>
                            <Col span={24} md={12}>
                                <Form.Item
                                    name="searchQuery"
                                    label="Search for a course"
                                    rules={[
                                        {
                                            // required: true,
                                            message: "Please search and select a course",
                                        },
                                    ]}
                                >
                                    <Input onChange={(e) => handleSearchChange(e)} />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={12}>
                                <Form.Item
                                    name="homeCourse"
                                    label="Home Course"
                                    rules={[
                                        { required: true, message: "Please select your home course" },
                                    ]}
                                >
                                    <Select>
                                        {courseList?.map((course) => (
                                            <Option key={course._id} value={course.name}>
                                                {course.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24} md={12}>
                                <Form.Item
                                    name="favCourse"
                                    label="Favorite Course"
                                    rules={[
                                        { required: true, message: "Please enter your favorite course" },
                                    ]}
                                >
                                    <Input onChange={handlePChange} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="publicProfile"
                                    label="Public Profile"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>


                    {/* <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btnStyle1 btnTheme1 shadow-none px-5"
                        >
                            Save
                        </Button>
                    </Form.Item> */}
                </Form>
            </Modal>


        </>
    );
};

export default ProfileUpdateModal;
