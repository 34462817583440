import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Spin } from "antd";
import unfinishedRoundIcon from "../assets/images/unfinishedRoundIcon.png";
import { useNavigate } from "react-router-dom";
import logoVerticaleTheme from "../assets/images/logoVerticaleTheme.png";
import Modal from "react-bootstrap/Modal";
import golfBigIcon from "../assets/images/golfBigIcon.png";
import starFillIcon from "../assets/images/starFillIcon.png";
import gameImg3 from "../assets/images/gameImg3.png";
import gameImg4 from "../assets/images/gameImg4.png";
import gameImg5 from "../assets/images/gameImg5.png";
import gameImg6 from "../assets/images/gameImg6.png";
import gameImg7 from "../assets/images/gameImg7.png";
import gameImg8 from "../assets/images/gameImg8.png";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";

import useGeolocation from './useGeolocation';


function Courses() {
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [courseShow, courseModal] = useState(false);
  const [selectedCourseData, setSelectedCourseData] = useState({});
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [played, coursePlayed] = useState([])
  const [page, setPage] = useState(1);

  const courseModalClose = () => {
    setSelectedCourseData({});
    courseModal(false);
  };

  const courseModalOpen = (data) => {
    courseModal(true);
    setSelectedCourseData(data);
  };

  const { request } = useRequest();
  const [course, setCourse] = useState([]);
  const { location, error } = useGeolocation();
  useEffect(() => {
    handleplayed()
    handleSearch()
  }, [searchFieldValue]);

  const handleSearchFieldChange = (e) => {
    setSearchFieldValue(e.target.value);
  };

  const handleSearch = () => {
    console.log(page, "page  ")
    setPage(1);
    setLoading(true)
    let latitude = null;
    let longitude = null; 
    
    const storedLocation = JSON.parse(localStorage.getItem('userLocation'));
    if (storedLocation) {
      // Reassign latitude and longitude if storedLocation exists
      latitude = storedLocation.latitude;
      longitude = storedLocation.longitude;
    } else {
      // Otherwise, assign from location (make sure location is defined)
      latitude = location.latitude; 
      longitude = location.longitude;
    }

    request({
      url: `/common/getNewcourse?limit=10&search=${searchFieldValue}&page=${page}&latitude=${latitude}&longitude=${longitude}`,
      method: "GET",
      onSuccess: (data) => {
        console.log("result x",data);
        setTotalCount(data.data.totalDocs)
        setCourse(data.data.docs);
        setLoading(false);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
        setLoading(false)
      },
    });
  };

  const handleplayed = () => {

    request({
      url: `/app/game/gameCourse`,
      method: "GET",
      onSuccess: (data) => {
        coursePlayed(data.data.data)
      },
      onError: (error) => {
        console.log(error);

      },
    });
  };


  const handleNextPage = () => {
    handleSearch()
    setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      handleSearch()
      setPage(page - 1);
    }
  };
  // Perform search functionality here

  return (
    <>
      <Main>
        <section className="home-wrap playPageOuter h-100 px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
          <div className="row g-3 h-100">
            <div className="col-xl-8 h-100">
              <div className="customCard rounded-3 bg-white h-100 d-flex flex-column flex-nowrap">
                <div className="customCardHeader pb-0">
                  <div className="searchLocation d-flex align-items-center gap-2">
                    <input
                      type="text"
                      value={searchFieldValue}
                      onChange={handleSearchFieldChange}
                      placeholder="Search courses..."
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="customCardBody flex-fill h-100 overflow-auto">
                  {loading ? (
                    <div style={{ textAlign: 'center' }}>
                      <Spin size="large" tip="Loading..." style={{ color: '#04070c' }} />
                    </div>
                  ) : (
                    <>
                      {course.length === 0 ? (
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                          <p className="nodata-fo">No courses found</p>
                        </div>
                      ) : (
                        <>
                          {course.map((data) => (
                            <a
                              key={data._id} // Assuming each data item has a unique identifier
                              role="button"
                              onClick={() => courseModalOpen(data)}
                              className="coursesItem d-flex align-items-center gap-2"
                            >
                              <div className="imgOuter">
                                <img
                                  className="w-100 h-100"
                                  src={
                                    data?.imageUrl
                                      ? `https://b2btobacco.s3.amazonaws.com/${data?.imageUrl}`
                                      : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                        data?.name
                                      )}&size=256`
                                  }
                                  alt="Course Image"
                                />
                              </div>
                              <div className="detail">
                                <div className="title">{data.name}</div>
                                <div className="dec">{data.location}</div>
                              </div>
                            </a>
                          ))}
                        </>
                      )}
                    </>
                  )}
                  {course.length > 0 && (
                    <div className="pagination-container">
                      <div className="pagination-total">
                        Total Count: {totalCount}
                      </div>
                      <div className="pagination-text-page">
                        <button className="pagination-btn" onClick={handlePreviousPage}>&lt;</button>
                        <span className="pagination-tital"> Page: {page}</span>
                        <button className="pagination-btn" onClick={handleNextPage}>&gt;</button>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            </div>
            <div className="col-xl-4 h-100">
              <div className="customCard rounded-3 bg-white h-100 d-flex flex-column flex-nowrap">
                <div className="customCardHeader p-3 pb-0">
                  <div className="d-flex gap-12 pb-3 border-bottom">
                    <div className="icon">
                      <img className="w-100" src={unfinishedRoundIcon} alt="Unfinished Round Icon" />
                    </div>
                    <div className="detail">
                      <div className="name">Courses Played</div>
                      {/* <div className="dec mt-2">
                        This is the dummy text. This is the dummy text. This is
                        the dummy text.
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="customCardBody p-3 flex-fill h-100 overflow-auto">
                  {played.length === 0 ? (
                    <div className="emptyState text-center">
                      <div className="imgOuter mx-auto">
                        <img className="w-100" src={golfBigIcon} alt="Golf Big Icon" />
                      </div>
                      <div className="title fs-6 text-dark my-1">No Courses Player</div>
                      <div className="dec text-muted fs-14">
                        You have not played or added any course. Tap to add a favourite course or home course.
                      </div>
                    </div>
                  ) : (
                    <>
                      {played?.map((course, index) => (
                        <div className="course-topbar course-sticky" key={index}>
                          <div className="d-flex gap-12 pb-3 border-bottom">
                            <div className="icon">
                              {/* Assuming you have an icon for each played course */}
                              <img
                                className="w-10 h-10"
                                src={
                                  course?.imageUrl
                                    ? `https://b2btobacco.s3.amazonaws.com/${course?.imageUrl}`
                                    : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                      course?.name
                                    )}&size=24`
                                }
                                alt="Course Image"
                              />
                            </div>
                            <div className="course-detail">
                              <div className="name">{course?.name}</div>
                              <div className="dec">{course?.location}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>

          </div>
        </section>

      </Main>

      {/* Courses Detail Modal  */}
      <Modal
        show={courseShow}
        size="lg"
        className="headerModals"
        onHide={courseModalClose}
        centered
      >
        <Modal.Header
          className="p-sm-4 p-3 position-relative justify-content-center"
          closeButton
        >
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <div className="courseDetail">
            <div className="row">
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-lg-4 col-sm-6">
                    <div className="mainImgOuter h-100">
                      <img
                        className="w-100 h-100"
                        src={selectedCourseData.imageUrl}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 col-sm-6">
                    <div className="courseContent">
                      <div className="basicDetail">
                        <div className="rating d-flex align-items-center gap-1">
                          <img src={starFillIcon} />
                          <div className="fs-14 fw-medium text-dark">
                            {selectedCourseData.rating}
                          </div>
                          <div className="fs-14 fw-medium text-muted">
                            ({selectedCourseData.userRating})
                          </div>
                        </div>
                        <div className="courseTitle">
                          {selectedCourseData.name}
                        </div>
                        <div className="subTitle">
                          {selectedCourseData.location}
                        </div>
                        <div className="dec">
                          {selectedCourseData.isPrivarte
                            ? "Private"
                            : "Semi-private"}
                        </div>
                      </div>
                      <div className="aboutDetail">
                        <div className="title text-dark">
                          About <span className="text-theme2">Event</span>
                        </div>
                        <div className="pera">
                          <div className="txt">
                            Enjoy your favourite dished and a lovely your
                            friends and family and have a great time. Food from
                            local food trucks will be available for
                          </div>
                          <button
                            type="button"
                            className="btn txtBtnTheme2 p-0 border-0 bg-transparent ms-2"
                          >
                            Read More...
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="courseImages">
                  <div className="title fs-6 fw-medium text-dark">Media</div>
                  <div className="imagesOuter">
                    <div className="row g-3">
                      <div className="col-sm-6">
                        <div className="imgOuter horizontalImg w-100">
                          <img className="w-100" src={gameImg3} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="imgOuter horizontalImg w-100">
                          <img className="w-100" src={gameImg4} />
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="imgOuter w-100">
                          <img className="w-100" src={gameImg5} />
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="imgOuter w-100">
                          <img className="w-100" src={gameImg6} />
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="imgOuter w-100">
                          <img className="w-100" src={gameImg7} />
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="imgOuter w-100">
                          <img className="w-100" src={gameImg8} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Courses;
