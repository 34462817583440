import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Icon1 from "../../assets/images/ic-1.svg";
import Icon2 from "../../assets/images/ic-2.svg";
import Icon3 from "../../assets/images/ic-3.svg";
import Icon4 from "../../assets/images/ic-4.svg";
import Icon6 from "../../assets/images/ic-6.svg";

const Navigation = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const NavList = styled.ul`
  display: flex;
  width: 350px;
  padding: 0;
  margin: 0;
  position: relative;
`;

const NavItem = styled.li`
  position: relative;
  list-style: none;
  width: 70px;
  height: 70px;
  z-index: 1;
`;

const NavLink = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
`;

const Icon = styled.span`
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 1.5em;
  text-align: center;
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;

  img {
    width: 24px;
    height: 24px;
    transition: filter 0.3s ease-in-out;
  }

  ${NavItem}.active & {
    transform: translateY(-36px);
  }

  ${NavItem}.active & img {
    filter: brightness(0) invert(1);
  }
`;

const Text = styled.span`
  position: absolute;
  color: #ebb42c;
  font-weight: 600;
  font-size: 1.0em;
  letter-spacing: 0.05em;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 0;
  transform: translateY(20px);

  ${NavItem}.active & {
    opacity: 1;
    transform: translateY(10px);
  }
`;

const Indicator = styled.div`
  position: absolute;
  top: -50%;
  width: 70px;
  height: 70px;
  background: #ebb42c;
  border-radius: 50%;
  border: 6px solid #fff;
  transition: transform 0.5s ease-in-out; /* Adjust duration to control speed */
`;

const MagicMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    { id: 'home', icon: Icon1, label: 'Home', path: '/home' },
    { id: 'career', icon: Icon2, label: 'Career', path: '/career' },
    { id: 'play', icon: Icon3, label: 'Play', path: '/play' },
    { id: 'courses', icon: Icon4, label: 'Courses', path: '/courses' },
    { id: 'handicap', icon: Icon6, label: 'Handicap', path: '/handicap' },
  ];

  const getTabIndexByPath = (path) => {
    const index = menuItems.findIndex(item => item.id === (path || 'home'));
    return index !== -1 ? index : 0; // Default to 'home' if no match
  };

  const [activeTab, setActiveTab] = useState(getTabIndexByPath(location.pathname.split('/')[1]));
  const [translateX, setTranslateX] = useState(activeTab * 70);

  // Sync with URL (history changes)
  useEffect(() => {
    const newTabIndex = getTabIndexByPath(location.pathname.split('/')[1]);

    if (newTabIndex !== activeTab) {
      setTranslateX(newTabIndex * 70);
      setActiveTab(newTabIndex);
    }
  }, [location.pathname]); // Run this effect when the path changes

  const handleClick = (index, path) => {
    if (index !== activeTab) {
      setTranslateX(index * 70);
      setActiveTab(index);
      navigate(path);
    }
  };

  return (
    <Navigation>
      <NavList>
        {menuItems.map((item, index) => (
          <NavItem key={item.id} className={activeTab === index ? 'active' : ''}>
            <NavLink onClick={() => handleClick(index, item.path)}>
              <Icon>
                <img src={item.icon} alt={item.label} />
              </Icon>
              <Text>{item.label}</Text>
            </NavLink>
          </NavItem>
        ))}
        <Indicator style={{ transform: `translateX(${translateX}px)` }} />
      </NavList>
    </Navigation>
  );
};

export default MagicMenu;
























// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import styled from 'styled-components';
// import Icon1 from "../../assets/images/ic-1.svg";
// import Icon2 from "../../assets/images/ic-2.svg";
// import Icon3 from "../../assets/images/ic-3.svg";
// import Icon4 from "../../assets/images/ic-4.svg";
// import Icon6 from "../../assets/images/ic-6.svg";

// const Navigation = styled.div`
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   width: 100%;
//   height: 70px;
//   background: #fff;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 1px;

//   @media (min-width: 768px) {
//     display: none;
//   }
// `;

// const NavList = styled.ul`
//   display: flex;
//   width: 350px;
//   padding: 0;
//   margin: 0;
//   position: relative;
// `;

// const NavItem = styled.li`
//   position: relative;
//   list-style: none;
//   width: 70px;
//   height: 70px;
//   z-index: 1;
// `;

// const NavLink = styled.a`
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   width: 100%;
//   text-align: center;
//   font-weight: 500;
// `;

// const Icon = styled.span`
//   position: relative;
//   display: block;
//   line-height: 75px;
//   font-size: 1.5em;
//   text-align: center;
//   transition: 0.5s;
//   color: #fff;

//   img {
//     width: 24px;
//     height: 24px;
//     transition: 0.5s;
//   }

//   ${NavItem}.active & {
//     transform: translateY(-36px);
//   }

//   ${NavItem}.active & img {
//     filter: brightness(0) invert(1);
//   }
// `;

// const Text = styled.span`
//   position: absolute;
//   color: #ebb42c;
//   font-weight: 600;
//   font-size: 1.0em;
//   letter-spacing: 0.05em;
//   transition: 0.5s;
//   opacity: 0;
//   transform: translateY(20px);

//   ${NavItem}.active & {
//     opacity: 1;
//     transform: translateY(10px);
//   }
// `;

// const Indicator = styled.div`
//   position: absolute;
//   top: -50%;
//   width: 70px;
//   height: 70px;
//   background: #ebb42c;
//   border-radius: 50%;
//   border: 6px solid #fff;
//   transition: 0.5s ease-in-out;

//   &::before, &::after {
//     display: none;
//   }
// `;

// const MagicMenu = () => {
//   const [activeTab, setActiveTab] = useState(0);
//   const [indicatorStyle, setIndicatorStyle] = useState({});
//   const navigate = useNavigate();
//   const location = useLocation();

//   const menuItems = [
//     { id: 'home', icon: Icon1, label: 'Home', path: '/home' },
//     { id: 'career', icon: Icon2, label: 'Career', path: '/career' },
//     { id: 'play', icon: Icon3, label: 'Play', path: '/play' },
//     { id: 'courses', icon: Icon4, label: 'Courses', path: '/courses' },
//     { id: 'handicap', icon: Icon6, label: 'Handicap', path: '/handicap' },
//   ];

//   useEffect(() => {
//     const path = location.pathname.split('/')[1];
//     const index = menuItems.findIndex(item => item.id === (path || 'home'));
//     setActiveTab(index !== -1 ? index : 0);
//   }, [location]);

//   useEffect(() => {
//     setIndicatorStyle({
//       transform: `translateX(${activeTab * 70}px)`,
//       transition: 'transform .5s ease-in-out',
//     });
//   }, [activeTab]);

//   const handleClick = (index, path) => {
//     setActiveTab(index);
//     navigate(path);
//   };

//   return (
//     <Navigation>
//       <NavList>
//         {menuItems.map((item, index) => (
//           <NavItem key={item.id} className={activeTab === index ? 'active' : ''}>
//             <NavLink onClick={() => handleClick(index, item.path)}>
//               <Icon>
//                 <img src={item.icon} alt={item.label} />
//               </Icon>
//               <Text>{item.label}</Text>
//             </NavLink>
//           </NavItem>
//         ))}
//         <Indicator style={indicatorStyle} />
//       </NavList>
//     </Navigation>
//   );
// };

// export default MagicMenu;
















// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import styled from 'styled-components';
// import Icon1 from "../../assets/images/ic-1.svg";
// import Icon2 from "../../assets/images/ic-2.svg";
// import Icon3 from "../../assets/images/ic-3.svg";
// import Icon4 from "../../assets/images/ic-4.svg";
// import Icon6 from "../../assets/images/ic-6.svg";

// const Navigation = styled.div`
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   width: 100%;
//   height: 70px;
//   background: #fff;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 1px;

//   @media (min-width: 768px) {
//     display: none;
//   }
// `;

// const NavList = styled.ul`
//   display: flex;
//   width: 350px;
//   padding: 0;
//   margin: 0;
//   position: relative;
// `;

// const NavItem = styled.li`
//   position: relative;
//   list-style: none;
//   width: 70px;
//   height: 70px;
//   z-index: 1;
// `;

// const NavLink = styled.a`
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   width: 100%;
//   text-align: center;
//   font-weight: 500;
// `;

// const Icon = styled.span`
//   position: relative;
//   display: block;
//   line-height: 75px;
//   font-size: 1.5em;
//   text-align: center;
//   transition: 0.5s;
//   color: #fff;

//   img {
//     width: 24px;
//     height: 24px;
//     transition: 0.5s;
//   }

//   ${NavItem}.active & {
//     transform: translateY(-36px);
//   }

//   ${NavItem}.active & img {
//     filter: brightness(0) invert(1);
//   }
// `;

// const Text = styled.span`
//   position: absolute;
//   color: #ebb42c;
//   font-weight: 600;
//   font-size: 1.0em;
//   letter-spacing: 0.05em;
//   transition: 0.5s;
//   opacity: 0;
//   transform: translateY(20px);

//   ${NavItem}.active & {
//     opacity: 1;
//     transform: translateY(10px);
//   }
// `;

// const Indicator = styled.div`
//   position: absolute;
//   top: -50%;
//   width: 70px;
//   height: 70px;
//   background: #ebb42c;
//   border-radius: 50%;
//   border: 6px solid #fff;
//   transition: 0.5s;

//   &::before, &::after {
//     display: none;
//   }
// `;

// const MagicMenu = () => {
//   const [activeTab, setActiveTab] = useState(0);
//   const [indicatorStyle, setIndicatorStyle] = useState({});
//   const navigate = useNavigate();
//   const location = useLocation();

//   const menuItems = [
//     { id: 'home', icon: Icon1, label: 'Home', path: '/home' },
//     { id: 'career', icon: Icon2, label: 'Career', path: '/career' },
//     { id: 'play', icon: Icon3, label: 'Play', path: '/play' },
//     { id: 'courses', icon: Icon4, label: 'Courses', path: '/courses' },
//     { id: 'handicap', icon: Icon6, label: 'Handicap', path: '/handicap' },
//   ];

//   useEffect(() => {
//     const path = location.pathname.split('/')[1];
//     const index = menuItems.findIndex(item => item.id === (path || 'home'));
//     setActiveTab(index !== -1 ? index : 0);
//   }, [location]);

//   useEffect(() => {
//     setIndicatorStyle({
//       transform: `translateX(${activeTab * 70}px)`,
//       transition: 'transform 0.5s',
//     });
//   }, [activeTab]);

//   const handleClick = (index, path) => {
//     setActiveTab(index);
//     navigate(path);
//   };

//   return (
//     <center>
//       <Navigation>
//         <NavList>
//           {menuItems.map((item, index) => (
//             <NavItem 
//               key={item.id} 
//               className={index === activeTab ? 'active' : ''}
//             >
//               <NavLink onClick={() => handleClick(index, item.path)}>
//                 <Icon>
//                   <img src={item.icon} alt={item.label} />
//                 </Icon>
//                 <Text>{item.label}</Text>
//               </NavLink>
//             </NavItem>
//           ))}
//           <Indicator style={indicatorStyle} />
//         </NavList>
//       </Navigation>
//     </center>
//   );
// };

// export default MagicMenu;
























// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import styled from 'styled-components';
// import Icon1 from "../../assets/images/ic-1.svg";
// import Icon2 from "../../assets/images/ic-2.svg";
// import Icon3 from "../../assets/images/ic-3.svg";
// import Icon4 from "../../assets/images/ic-4.svg";
// import Icon6 from "../../assets/images/ic-6.svg";

// const Navigation = styled.div`
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   width: 100%;
//   height: 70px;
//   background: #fff;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 1px;

//   @media (min-width: 768px) {
//     display: none;
//   }
// `;

// const NavList = styled.ul`
//   display: flex;
//   width: 350px;
//   padding: 0;
//   margin: 0;
// `;

// const NavItem = styled.li`
//   position: relative;
//   list-style: none;
//   width: 70px;
//   height: 70px;
//   z-index: 1;
// `;

// const NavLink = styled.a`
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   width: 100%;
//   text-align: center;
//   font-weight: 500;
// `;

// const Icon = styled.span`
//   position: relative;
//   display: block;
//   line-height: 75px;
//   font-size: 1.5em;
//   text-align: center;
//   transition: 0.5s;
//   color: #fff;

//   img {
//     width: 24px;
//     height: 24px;
//     transition: 0.5s;
//   }

//   ${NavItem}.active & {
//     transform: translateY(-36px);
//   }

//   ${NavItem}.active & img {
//     filter: brightness(0) invert(1);
//   }
// `;

// const Text = styled.span`
//   position: absolute;
//   color: #ebb42c;
//   font-weight: 600;
//   font-size: 1.0em;
//   letter-spacing: 0.05em;
//   transition: 0.5s;
//   opacity: 0;
//   transform: translateY(20px);

//   ${NavItem}.active & {
//     opacity: 1;
//     transform: translateY(10px);
//   }
// `;

// const Indicator = styled.div`
//   position: absolute;
//   top: -50%;
//   width: 70px;
//   height: 70px;
//   background: #ebb42c;
//   border-radius: 50%;
//   border: 6px solid #fff;
//   transition: 0.5s;

//   /* Remove the pseudo-elements that were causing the black lines */
//   &::before, &::after {
//     display: none;
//   }
// `;


// const MagicMenu = () => {
//   const [activeTab, setActiveTab] = useState(0);
//   const navigate = useNavigate();
//   const location = useLocation();

//   const menuItems = [
//     { id: 'home', icon: Icon1, label: 'Home', path: '/home' },
//     { id: 'career', icon: Icon2, label: 'Career', path: '/career' },
//     { id: 'play', icon: Icon3, label: 'Play', path: '/play' },
//     { id: 'courses', icon: Icon4, label: 'Courses', path: '/courses' },
//     { id: 'handicap', icon: Icon6, label: 'Handicap', path: '/handicap' },
//   ];
//   useEffect(() => {
//     const path = location.pathname.split('/')[1];
//     const index = menuItems.findIndex(item => item.id === (path || 'home'));
//     setActiveTab(index !== -1 ? index : 0);
//   }, [location]);

//   const handleClick = (index, path) => {
//     setActiveTab(index);
//     navigate(path);
//   };

//   return (
//     <center>
//     <Navigation>
//       <NavList >
//         {menuItems.map((item, index) => (
//           <NavItem 
//             key={item.id} 
//             className={index === activeTab ? 'active' : ''}
//           >
//             <NavLink onClick={() => handleClick(index, item.path)}>
//               <Icon>
//                 <img src={item.icon} alt={item.label} />
//               </Icon>
//               <Text>{item.label}</Text>
//             </NavLink>
//           </NavItem>
//         ))}
//         <Indicator style={{ transform: `translateX(calc(70px * ${activeTab}))` }} />
//       </NavList>
//     </Navigation>
//     </center>
//   );
// };

// export default MagicMenu;








