import React, { useState, useEffect } from "react";
import Main from "../components/layout/Main";
import backArrow from "../assets/images/backArrow.png";
import tabIcon1 from "../assets/images/tabIcon1.png";
import noteIcon from "../assets/images/noteIcon.png";
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import useRequest from "../../src/hooks/useRequest"
import logoVerticaleTheme from "../assets/images/logoVerticaleTheme.png";
import scoreCardModalImg from "../assets/images/scoreCardModalImg.png";
import golfIcon from "../assets/images/golfIcon.png";
import { useNavigate, Link } from "react-router-dom";
import { Severty, ShowToast } from "../helper/toast";


function PostScore() {
  const { request } = useRequest();
  const navigate = useNavigate();
  const [show, scoreCardStepModal] = useState(false);
  const scoreCardStepModalClose = () => scoreCardStepModal(false);
  const scoreCardStepModalOpen = () => scoreCardStepModal(true);
  const [selectedValue, setSelectedValue] = useState('');
  const [TeeData, setTeeData] = useState([])
  const [courseListData, setCourseListData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCourses, setFilteredCourses] = useState([]);

  const [postData, setPostData] = useState({
    course: '',
    CourseTee: '',
    Date: '',
    holes: '',
    totalScore: null,
    isPrivate: true,
  })

  const handlechange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox" && name === "isPrivate") {
      setPostData({ ...postData, [name]: checked });
    } else {
      setPostData({ ...postData, [name]: value });
    }
  }

  const fetchTeeData = (id) => {
    request({
      url: `/common/getCourseTeeData/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setTeeData(data.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    })
  }

  const addPostScore = (event) => {
    event.preventDefault()
    postData['holes'] = selectedValue;
    request({
      url: "app/score/add-post-score",
      method: "POST",
      data: postData,
      onSuccess: (data) => {
        ShowToast("post-score data added successfully",Severty.SUCCESS)
        console.log("add-post-score data successfully:", data)
        navigate("/post")
      },
      onError: (error) => {
        console.log("Error in post score", error)
      }
    })
  }

  useEffect(() => {
  }, [searchTerm, request]);

  const fetctData = (value) => {
    try {
      request({
        url: `/common/getNewcourse?search=${value}`,
        method: "GET",
        onSuccess: (data) => {
          setCourseListData(data.data.docs);
        },
        onError: (error) => {
          console.log("Error in post score", error)
        }
      });

    } catch (error) {
      console.error("Error fetching course data:", error);
    }
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    fetctData(searchTerm)
  };

  const handleCourseChange = (e) => {
    const selectedCourseId = e.target.value;
    fetchTeeData(e.target.value)
    setPostData({ ...postData, course: selectedCourseId });
  };


  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3">
          <div className="col-lg-12">
            <div className="pagreHeader d-block">
              <div className="pageHeadign d-flex align-items-center gap-3 mb-3">
                <button className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                  <img className="h-100" src={backArrow} />
                </button>
                <div className="txt">Post Score</div>
              </div>
              <div className="title text-white mb-1 d-flex align-items-center gap-1">Score Upload Options <button type="button" onClick={scoreCardStepModalOpen} className="p-0 border-0 p-0 bg-transparent"><img src={noteIcon} /></button></div>
              <div className="dec text-white">Enter score manually or send a pic of your scorecard to auto-transcribe to your account.</div>
            </div>

          </div>
          <div className="col-12">
            <Tabs className="tabsOuter mb-4 border-0 d-flex gap-12 flex-wrap flex-sm-nowrap"
              defaultActiveKey="totalScore"
              id="uncontrolled-tab-example"
            >
              <Tab className="m-0" eventKey="totalScore" title={
                <>
                  <div className="d-flex align-items-center gap-2">
                    <div className="tabIcon">
                      <img className="w-100" src={tabIcon1} />
                    </div>
                    <div className="tabDetail">
                      <div className="title d-flex align-items-center gap-2">Post Total Score</div>
                      <div className="subTitle">Quick manual upload</div>
                    </div>
                  </div>
                </>
              }>
                <div className="tabsCard">
                  <Form className="row g-2" onSubmit={addPostScore}>
                    <div className="tabsCardInner rounded-3 p-3 bg-white">
                      <div className="row g-2">
                        <Form.Label className="col-12 mb-0 mt-3">Course Details</Form.Label>
                        <Form.Group className="col-sm-6">
                          <Form.Control
                            type="text"
                            placeholder="Search Course"
                            value={searchTerm}
                            onChange={handleSearchChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-sm-6" controlId="formCourse">
                          <Form.Select
                            as="select"
                            name="course"
                            required="course"
                            value={postData.course}
                            onChange={handleCourseChange}
                          >
                            <option value="">Select Course</option>
                            {courseListData && courseListData.map((course) => (
                              <option key={course.courseId} value={course.courseId}>
                                {course.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className="col-sm-6">
                          <Form.Select
                            as="select"
                            name="CourseTee"
                            value={postData.tee}
                            onChange={(e) => {
                              // Find the selected tee object from TeeData using its ID
                              const selectedTee = TeeData.find((tee) => tee._id === e.target.value);
                              // Update the tee name instead of its ID in postData
                              setPostData({ ...postData, tee: selectedTee.name });
                              // Handle change as usual
                              handlechange(e);
                            }}
                          >
                            <option value="">Select Tees</option>
                            {TeeData && TeeData?.map((tee) => (
                              <option key={tee.id} value={tee._id}>
                                {tee.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="col-sm-6">
                          <Form.Control className="sahdow-none" type="date" name="Date" placeholder="mm/dd/yyyy" onChange={handlechange} />
                        </Form.Group>
                        <Form.Group className="col-sm-6">
                          <Form.Select
                            aria-label="Default select example"
                            name="holes"
                            onChange={(e) => {
                              setSelectedValue(e.target.value)
                            }}>
                            <option>Select Hole</option>
                            <option value="9">Back 9</option>
                            <option value="18">18 Holes</option>
                            <option value="9">Front 9</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="col-12 mt-3">
                          <Form.Label className="mb-0">Upload Options</Form.Label>
                        </Form.Group>
                        <Form.Group className="col-12">
                          <div className=" border rounded-3 px-3 py-2">
                            <Form.Check
                              className="switchCheck p-0 py-2 rounded-0 shadow-none mb-0 d-flex align-items-center justify-content-between gap-2 h-100" style={{ borderRadius: "6px" }}
                              type="switch"
                              id="privateUpload"
                              name="isPrivate" // Add name attribute
                              checked={postData.isPrivate} // Add checked attribute
                              onChange={handlechange} // Update the change handler
                              label={
                                <>
                                  <div className="fs-6 fw-medium text-dark">Private Upload</div>
                                  <div className="text-muted">Hides score in your Activity Feed</div>
                                </>
                              }
                            />
                            <hr className="my-1 border" />

                          </div>
                        </Form.Group><Form.Label className="col-12 mb-0">Total Score</Form.Label>
                        <Form.Group className="col-6">
                          <Form.Control className="sahdow-none" type="text" name="totalScore" required="totalScore" value={postData.totalScore || ''} placeholder="Enter total score" onChange={handlechange} />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="text-end mt-5">
                      <button type="submit" className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-5">Upload</button>
                    </div>
                  </Form>
                </div>
              </Tab>
              
              <Tab className="m-0"   title={
                <>
                 <Link to="/play" className=" d-flex align-items-center">
                  <div className="d-flex align-items-center gap-2" >
                    {/* <Link to="/play"> */}
                    <div className="tabIcon">
                      <img className="w-100" src={tabIcon1} alt="Play" />
                      </div>
                    {/* </Link> */}
                    <div className="tabDetail">
                     <div className="title d-flex align-items-center gap-2"> Post Hole by Hole</div>
                      <div className="subTitle">Track score while playing</div>
                    </div>
                  </div>
                  </Link>
                </>
              }>
               
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
      {/* Score card Modal  */}
      <Modal show={show} size="lg" className="headerModals" onHide={scoreCardStepModalClose} centered>
        <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <div className="row g-3 align-items-center">
            <div className="col-lg-4 col-md-6 text-center">
              <img src={scoreCardModalImg} />
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="title fs-6 fw-medium">Scorecard Picture Service</div>
              <div className="dec fs-14 mt-1 text-muted">We will convert your physical scorecard digitally and upload it to your profile</div>
              <ul className="p-0 m-0 list-unstyled d-flex flex-column gap-sm-4 gap-3 mt-sm-4 mt-3">
                <li className="">
                  <div className="d-flex align-items-center gap-1 fs-14 fw-medium"><img src={golfIcon} /> STEP 1:</div>
                  <div className="fs-12 text-muted fw-medium">Take a picture of your scorecard once you finish your round?</div>
                </li>
                <li className="">
                  <div className="d-flex align-items-center gap-1 fs-14 fw-medium"><img src={golfIcon} /> STEP 2:</div>
                  <div className="fs-12 text-muted fw-medium">Upload the image of your scorecard to theGolf app.</div>
                </li>
                <li className="">
                  <div className="d-flex align-items-center gap-1 fs-14 fw-medium"><img src={golfIcon} /> STEP 3:</div>
                  <div className="fs-12 text-muted fw-medium">Our team will digitally convert your scorecard and connect it to your account</div>
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Main>

  );
}
export default PostScore;