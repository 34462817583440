import { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import {
  Row,
  Col,
  Button,
  message,
  Avatar,
  Input,
  Upload,
  Typography
} from "antd";

import {
  EditOutlined,
  UploadOutlined
} from "@ant-design/icons";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { ShowToast, Severty } from '../../helper/toast';
import avtar from "../../assets/images/learn.svg";
import logo from "../../assets/images/main-logo.svg";
import NotificationBell from "../../assets/images/notification-bell.svg";
import qrSmallImg from "../../assets/images/qrSmallImg.png";
import userIcon from "../../assets/images/userIcon.png";
import Membership from "../../assets/images/Membership.png";
import ChangePassword from "../../assets/images/ChangePassword.png";
import NotificationPreferences from "../../assets/images/NotificationPreferences.png";
import ManageAccount from "../../assets/images/ManageAccount.png";
import ContactUs from "../../assets/images/ContactUs.png";
import TermConditions from "../../assets/images/TermConditions.png";
import logoVerticaleTheme from "../../assets/images/logoVerticaleTheme.png";
import showPassIcon from "../../assets/images/showPassIcon.png";
import checkTheme1Icon from "../../assets/images/checkTheme1Icon.png";
import checkTheme2Icon from "../../assets/images/checkTheme2Icon.png";
import recommendedIcon from "../../assets/images/recommendedIcon.png";
import ContactUs2 from "../../assets/images/ContactUs2.png";
import addScoreIcon from "../../assets/images/addScoreIcon.png";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";
import ProfileUpdateModal from "./ProfileUpdateModal";
const wifi = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 107 107"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="logo-spotify" fill="#2EBD59" fillRule="nonzero">
        <path
          d="M53.5,0 C23.9517912,0 0,23.9517912 0,53.5 C0,83.0482088 23.9517912,107 53.5,107 C83.0482088,107 107,83.0482088 107,53.5 C107,23.9554418 83.0482088,0.00365063118 53.5,0 Z M78.0358922,77.1597407 C77.0757762,78.7368134 75.0204708,79.2296486 73.4506994,78.2695326 C60.8888775,70.5922552 45.0743432,68.8582054 26.4524736,73.1111907 C24.656363,73.523712 22.8675537,72.3993176 22.458683,70.6032071 C22.0461617,68.8070966 23.1669055,67.0182873 24.9666667,66.6094166 C45.3444899,61.9548618 62.8273627,63.9590583 76.9297509,72.5745479 C78.4995223,73.5419652 78.9996588,75.5899693 78.0358922,77.1597407 L78.0358922,77.1597407 Z M84.5814739,62.5973729 C83.373115,64.5614125 80.8030706,65.1747185 78.8426817,63.9700102 C64.4664961,55.1318321 42.5408052,52.5727397 25.5325145,57.7347322 C23.3275333,58.4027977 20.9984306,57.1579324 20.3267144,54.9566018 C19.6622996,52.7516206 20.9071648,50.4261685 23.1084954,49.7544524 C42.5371546,43.858683 66.6933811,46.7134766 83.2051859,56.8622313 C85.1692255,58.0705902 85.7898328,60.636984 84.5814739,62.5973729 Z M85.1436711,47.4253497 C67.8980894,37.1853292 39.4523712,36.2434664 22.9880246,41.2375299 C20.3449676,42.0406687 17.5485841,40.5475606 16.7490959,37.9045036 C15.9496076,35.2614466 17.4390652,32.4650631 20.0857728,31.6619243 C38.9850904,25.9267827 70.3987718,27.0329239 90.2509041,38.8171614 C92.627465,40.2299556 93.4087001,43.3001365 91.9995565,45.6730467 C90.5940635,48.0532583 87.5165814,48.838144 85.1436711,47.4253497 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>,
];

const credit = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fill="#1890FF"
      d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
    ></path>
    <path
      fill="#1890FF"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
    ></path>
  </svg>,
];

const clockicon = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071L12.1213 13.5355C12.5118 13.9261 13.145 13.9261 13.5355 13.5355C13.9261 13.145 13.9261 12.5118 13.5355 12.1213L11 9.58579V6Z"
      fill="#111827"
    ></path>
  </svg>,
];

const data = [
  {
    title: "New message from Sophie",
    description: <>{clockicon} 2 days ago</>,

    avatar: avtar,
  },
  {
    title: "New album by Travis Scott",
    description: <>{clockicon} 2 days ago</>,

    avatar: <Avatar shape="square">{wifi}</Avatar>,
  },
  {
    title: "Payment completed",
    description: <>{clockicon} 2 days ago</>,
    avatar: <Avatar shape="square">{credit}</Avatar>,
  },
];

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];
const S3_BUCKET = 'b2btobacco';
const REGION =  'us-east-1';
const ACCESS_KEY = 'AKIAXHA23FMK7MWDNCHL';
const SECRET_ACCESS_KEY = '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';

const config = {
  dirName: "golf/profile",
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};
function Header({
  onPress,
}) {
  const { request } = useRequest();
  const { Title, Text } = Typography;

  const [visible, setVisible] = useState(false);
  const [sidenavType, setSidenavType] = useState("transparent");

  useEffect(() => window.scrollTo(0, 0));

  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);

  const [qrCodeShow, qrCodeModal] = useState(false);
  const qrCodeModalClose = () => qrCodeModal(false);
  const qrCodeModalOpen = () => qrCodeModal(true);

  const [show, personalDetailModal] = useState(false);
  const personalDetailModalClose = () => personalDetailModal(false);
  const personalDetailModalOpen = () =>{
    console.log("working personal modal<<<<<<<<<<<<<<,,,,")
    personalDetailModal(true)};

  const [membershipModalshow, membershipModal] = useState(false);
  const membershipModalClose = () => membershipModal(false);
  const membershipModalOpen = () => membershipModal(true);

  const [changePassModalshow, changePassModal] = useState(false);
  const changePassModalClose = () => changePassModal(false);
  const changePassModalOpen = () => changePassModal(true);

  const [manageAccountModalshow, manageAccountModal] = useState(false);
  const manageAccountModalClose = () => manageAccountModal(false);
  const manageAccountModalOpen = () => manageAccountModal(true);

  const [contactUsModalshow, contactUsModal] = useState(false);
  const contactUsModalClose = () => contactUsModal(false);
  const contactUsModalOpen = () => contactUsModal(true);

  const [notifyCount, setNotificationCount] = useState('')

  const [termsConditionsModalshow, termsConditionsModal] = useState(false);
  const termsConditionsModalClose = () => termsConditionsModal(false);
  const termsConditionsModalOpen = () => termsConditionsModal(true);
  const [formData, setFormData] = useState({
    subject: "",
    description: ""
  })
  // Inside your component function
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const [passwordData, setpasswordData] = useState({
    password: "",
    new_password: "",
    confirm_new_password: ""
  })

  const handleData = async (event) => {
    event.preventDefault()
       // Password validation regular expression
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordData.password || !passwordData.new_password) return ShowToast('Please enter password ', Severty.ERROR)
    if (passwordData.new_password !== passwordData.new_password) return ShowToast('Password did not match! ', Severty.ERROR)
    if (!passwordRegex.test(passwordData.new_password)) {
      ShowToast('Password must contain at least 8 characters, including capital letters, small letters, special characters, and numbers.', Severty.ERROR);
      return;
    }
    request({
      url: "app/user/change-password",
      method: "POST",
      data: passwordData,
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS)
        changePassModalClose()
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error.response.data.message, Severty.ERROR)

      }
    })

    console.log("passwordData", passwordData)
  }

  const [terms, setTerms] = useState()
  const handleSubmit = async (event) => {
    event.preventDefault()
    console.log("formData", formData)
    request({
      url: "/app/auth/contact-us",
      method: "POST",
      data: formData,
      onSuccess: (data) => {
        ShowToast("send succesfully", Severty.SUCCESS)
        contactUsModalClose()
      },
      onError: (error) => {
        console.log(error)
      }

    })
  }
  const [courseList, setCourseList] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [file, setFile] = useState([]);
  const [image, setImage] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeActivateConfirmation, setShowDeActivateConfirmation] = useState(false);
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    error: null
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null
          });
        },
        (error) => {
          setLocation({
            latitude: null,
            longitude: null,
            error: error.message
          });
        }
      );
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        error: 'Geolocation is not supported by this browser.'
      });
    }
  }, []);

  const FileType = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/avif',
    'image/webp',
    'image/gif',
  ];

  const beforeUpload = (file) => {
    if (!FileType.includes(file.type)) {
      message.error('File format is not correct');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('Image must be smaller than 5 MB!');
      return false;
    }
    return true;
  };
  const handleRemove = () => {
    setFile([]);
    // setImage('');
  };
  const handleImageChange = async (event) => {
    const { file } = event;
    setFile([file]);
    uploadFile(file, config)
      .then((data) => {
        const fileData = {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: data.location,
          thumbUrl: data.location,
        };
        setFile([fileData]);
        setImage(data.location);
        console.log(data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    request({
      url: `/common/getNewcourse?search=${encodeURIComponent(searchQuery)}&latitude=${location.latitude}&longitude=${location.longitude}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        // ShowToast(data.message, Severty.SUCCESS)
        setCourseList(data.data.docs);
      },
      onError: (error) => {
        console.log(error);
        //ShowToast(error, Severty.ERROR);
      },
    });
  }, [searchQuery]);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const change = (event) => {
    const { name, value } = event.target;

    setpasswordData({ ...passwordData, [name]: value })
  }
  const handlechange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value })
  }

  const [profileData, setProfileData] = useState({
    gender: '',
    // street: '',
    homeCourse: '',
    favCourse: '',
    // isPublicProfile: false,
    country: '',
    name: '',
    dob: '',
    state: '',
    // city: '',
    profilePic: ''
  });
  const handlePChange = (event) => {
    setProfileData({ ...profileData, profilePic: null });
  };

  const addProfile = (event) => {
    console.log("profileData", event.target.value)
    event.preventDefault()
    request({
      url: "app/user/update-profile",
      method: "POST",
      data: profileData,
      onSuccess: (data) => {
        console.log("Profile updated successfully:", data);
        personalDetailModalClose()
        ShowToast("Profile updated successfully", Severty.SUCCESS);
      },
      onError: (error) => {
        console.error("Error updating profile:", error);
        ShowToast("Error updating profile", Severty.ERROR);
      },
    });
  };
  const [profile, setProfile] = useState({})

  useEffect(() => {
    notificationCount()
  }, []);

  useEffect(() => {
    setUserProfile();
    fetchCountry();
    notificationCount()
  }, []);

  const setUserProfile = () => {
    request({
      url: "app/user/get-profile",
      method: "GET",
      onSuccess: (data) => {
        console.log("Profile data:", data.data.name);
        setProfile(data.data); // Assuming the API response contains the user profile data
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };

  const [countries, setCountries] = useState([]);

  const fetchCountry = () => {
    request({
      url: 'https://restcountries.com/v3.1/all',
      method: "GET",
      onSuccess: (data) => {
        // Assuming data is an array of country objects
        console.log("Country Data:", data);

        // Extracting country names from the array of country objects
        const countryNames = data.map(country => country.name.common);

        // Set the country names in the state
        setCountries(countryNames);
      },
      onError: (error) => {
        console.log("Error fetching countries Data:", error);
      }
    });
  }

  const navigate = useNavigate();
  
  const deleteAccount = () => {
    request({
      url: "app/user/delete-account",
      method: "GET",
      onSuccess: (data) => {
        ShowToast("Deleted Succesfully",Severty.SUCCESS)
        navigate("/")
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };

  const handleDeleteAccount = () =>{
    setShowDeleteConfirmation(true);
  }

  const confirmDelete = () => {
    setShowDeleteConfirmation(false);
    deleteAccount()
  };

  const cancelDelete = () =>{
    setShowDeleteConfirmation(false);
  }

  const deactivateAccount = () => {
    request({
      url: "app/user/deactivateAccount",
      method: "PUT",
      onSuccess: (data) => {
        ShowToast("Deactivate Succesfully",Severty.SUCCESS)
        navigate("/")
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };

  const handleDeActivateAccount = () =>{
    setShowDeActivateConfirmation(true);
  }

  const confirmDeActivate = () => {
    setShowDeActivateConfirmation(false);
    deactivateAccount()
  };

  const cancelDeActivate = () =>{
    setShowDeActivateConfirmation(false);
  }

  const notificationCount = () => {
    request({
      url: "app/notification/notificationCount",
      method: "GET",
      onSuccess: (data) => {
        setNotificationCount(data.data)
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };
  const handleChange = async (event) => {
    const { file } = event;
    setFile([file]);

    uploadFile(file, config)
      .then((data) => {

        const fileData = {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: data.key,
          thumbUrl: data.location,
        };
        setFile([fileData]);

        setImage(data.location);
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      {/* <div className="setting-drwer" onClick={showDrawer}>
        {setting}
      </div> */}
      <Row>
        <Col span={24} xs={8} sm={5} md={4}>
          <div className="brand">
            <a href="/home">
              <img src={logo} alt="" />
            </a>
          </div>
        </Col>
        <Col
          span={24}
          xs={12}
          sm={16}
          md={14}
          className="d-flex align-items-center"
        >
          <div className="header-control">
            <Button
              type="link"
              className="sidebar-toggler"
              onClick={() => onPress()}
            >
              {toggler}
            </Button>
          </div>
          {/* <div className="header-middle justify-content-end justify-content-md-center w-100 d-none d-sm-flex">
            <div className="searchWrap d-none d-md-block">
              <Input.Search placeholder="Search by course, friend" allowClear />
            </div>
            <div className="score-btn d-flex align-items-center gap-2">
              <Button
                onClick={() => navigate("/post-score")}
                className="btn px-3 btn_primary"
              >
                <img className="me-2" src={addScoreIcon} />
                Add a Score
              </Button>
              <Button
                onClick={membershipModalOpen}
                className="btn px-3 btn_yellow"
              >
                Become Pro
              </Button>
            </div>
          </div> */}
        </Col>
        <Col span={24} xs={4} sm={3} md={5}>
          <div className="header-end">
            <div className="notification-wrap">
              <div className="notification-ic">
                <a href="/notification">
                  <img src={NotificationBell} />
                  <div className="notification-counts">{notifyCount}</div>
                </a>
              </div>
            </div>
            <div className="myaccount-wrap">
              <Dropdown className="profileDropDown">
                <Dropdown.Toggle
                  className="p-0 bg-transparent shadow-none border-0"
                  id="dropdown-basic"
                >
                  <div className="user-ic">
                    <img className="w-100 h-100" src={
                      profile?.profilePic
                        ? `https://b2btobacco.s3.amazonaws.com/${profile?.profilePic}`
                        : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                          profile?.name
                        )}&size=256`
                    } alt={profile?.name} />

                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="d-flex flex-column gap-2 px-0 shadow">
                  <div className="profileDetail d-flex align-items-center justify-content-between gap-2 w-100 mb-2 py-0 px-3 pt-3">
                    <div className="d-flex align-items-center gap-2">
                      <div className="profileOuter rounded-circle overflow-hidden">
                        <img className="w-100 h-100" src={
                          profile?.profilePic
                            ? `https://b2btobacco.s3.amazonaws.com/${profile?.profilePic}`
                            : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                              profile?.name
                            )}&size=256`
                        } alt={profile?.name} />
                      </div>
                      <div className="profileDetail">
                        <div className="name">{profile.name}</div>
                        <div className="dec text-muted fs-12">Since {new Date(profile.created_at).getFullYear()}</div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn qrCodeBtn border-0 shadow-none bg-transparent p-0"
                      onClick={qrCodeModalOpen}
                    >
                      <img className="w-100 h-100" src={qrSmallImg} />
                    </button>
                  </div>
                  <Dropdown.Item
                    onClick={personalDetailModalOpen}
                    className="px-3 py-2 d-flex align-items-center gap-2"
                    role="button"
                  >
                    <div className="icon">
                      <img src={userIcon} />
                    </div>{" "}
                    Personal Info
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    onClick={membershipModalOpen}
                    className="px-3 py-2 d-flex align-items-center gap-2"
                    role="button"
                  >
                    <div className="icon">
                      <img src={Membership} />
                    </div>{" "}
                    Membership
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={changePassModalOpen}
                    className="px-3 py-2 d-flex align-items-center gap-2"
                    role="button"
                  >
                    <div className="icon">
                      <img src={ChangePassword} />
                    </div>{" "}
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="px-3 py-2 d-flex align-items-center gap-2"
                    href="/notification-setting"
                  >
                    <div className="icon">
                      <img src={NotificationPreferences} />
                    </div>{" "}
                    Notification Preferences
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={manageAccountModalOpen}
                    className="px-3 py-2 d-flex align-items-center gap-2"
                    role="button"
                  >
                    <div className="icon">
                      <img src={ManageAccount} />
                    </div>{" "}
                    Manage Account
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={contactUsModalOpen}
                    className="px-3 py-2 d-flex align-items-center gap-2"
                    role="button"
                  >
                    <div className="icon">
                      <img src={ContactUs} />
                    </div>{" "}
                    Contact Us
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={termsConditionsModalOpen}
                    className="px-3 py-2 d-flex align-items-center gap-2"
                    role="button"
                  >
                    <div className="icon">
                      <img src={TermConditions} />
                    </div>{" "}
                    Term & Conditions
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>

      {/* QrCodeMoal  */}
      <Modal
        show={qrCodeShow}
        className="headerModals"
        onHide={qrCodeModalClose}
        centered
      >
        <Modal.Header
          className="p-sm-4 p-3 position-relative justify-content-center"
          closeButton
        >
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3 text-center">
          <div className="fs-16 fw-medium mb-0">Your QR Code</div>
          <img className="w-90 h-90" src={profile.QrImage} />
          <div className="profile d-flex align-items-center gap-12 justify-content-center">
            <div className="imgOuter rounded-circle overflow-hidden">
              <img className="w-100 h-100" src={profile?.profilePic ? profile?.profilePic : `https://ui-avatars.com/api/?name=${encodeURIComponent(profile?.name)}&size=256`} />
            </div>
            <div className="detail text-start">
              <div className="name fs-6 fw-medium text-dark">
                {profile.name}
              </div>
              <div className="dec text-muted fs-14">{profile.course}</div>
              <div className="dec text-muted fs-12">Since {new Date(profile.created_at).getFullYear()}</div>

            </div>
          </div>
        </Modal.Body>
      </Modal>
      {show ? <ProfileUpdateModal open={show} close={personalDetailModalClose} /> : null}

      {/* Membership Modal  */}
      <Modal
        show={membershipModalshow}
        size="lg"
        className="headerModals"
        onHide={membershipModalClose}
        centered
      >
        <Modal.Header
          className="p-sm-4 p-3 position-relative justify-content-center"
          closeButton
        >
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <Form className="row g-2">
            <Form.Label className="col-12 mb-0">Membership</Form.Label>
            <Form.Group className="col-12 position-relative d-flex flex-column gap-4">
              <Form.Check
                className="membershipItem position-relative p-0 m-0"
                name="membership"
                type="radio"
                id="planOne"
                label={
                  <>
                    <div className="membershipInner rounded-3 border w-100">
                      <div className="p-sm-3 p-2">
                        <div className="title">7 Days Free Plan</div>
                        <div className="price mt-1">
                          $ 39.99<span>/year</span>
                        </div>
                        <ul className="p-0 m-0 list-unstyled pt-2 border-top mt-2">
                          <li className="d-flex gap-2">
                            <img
                              style={{
                                width: "18px",
                                height: "18px",
                                marginTop: "2px",
                              }}
                              src={checkTheme1Icon}
                            />
                            Enjoy a complimentary 7-day trial of our premium
                            features.
                          </li>
                          <li className="d-flex gap-2">
                            <img
                              style={{
                                width: "18px",
                                height: "18px",
                                marginTop: "2px",
                              }}
                              src={checkTheme1Icon}
                            />
                            During the trial period, you can experience the full
                            range of benefits our platform offers.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                }
              />
              <Form.Check
                className="membershipItem recommendedPlan position-relative p-0 m-0"
                name="membership"
                type="radio"
                id="planTwo"
                label={
                  <>
                    <div className="membershipInner rounded-3 border w-100">
                      <div className="recommendedPlan p-sm-3 p-2 border-bottom d-flex align-items-center gap-1">
                        <img
                          style={{
                            width: "24px",
                            height: "24px",
                            marginTop: "2px",
                          }}
                          src={recommendedIcon}
                        />
                        Recommended
                      </div>
                      <div className="p-sm-3 p-2">
                        <div className="title">Pro Membership</div>
                        <div className="price mt-1">
                          $ 39.99<span>/year</span>
                        </div>
                        <ul className="p-0 m-0 list-unstyled pt-2 border-top mt-2">
                          <li className="d-flex gap-2">
                            <img
                              style={{
                                width: "18px",
                                height: "18px",
                                marginTop: "2px",
                              }}
                              src={checkTheme2Icon}
                            />
                            Increased number of likes
                          </li>
                          <li className="d-flex gap-2">
                            <img
                              style={{
                                width: "18px",
                                height: "18px",
                                marginTop: "2px",
                              }}
                              src={checkTheme2Icon}
                            />
                            The option to initiate communication with other
                            users.
                          </li>
                          <li className="d-flex gap-2">
                            <img
                              style={{
                                width: "18px",
                                height: "18px",
                                marginTop: "2px",
                              }}
                              src={checkTheme2Icon}
                            />
                            Chat functionality available in multiple languages.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                }
              />
              <Form.Check
                className="membershipItem position-relative p-0 m-0"
                name="membership"
                type="radio"
                id="planThree"
                label={
                  <>
                    <div className="membershipInner rounded-3 border w-100">
                      <div className="p-sm-3 p-2">
                        <div className="title">7 Days Free Plan</div>
                        <div className="price mt-1">
                          $ 39.99<span>/year</span>
                        </div>
                        <ul className="p-0 m-0 list-unstyled pt-2 border-top mt-2">
                          <li className="d-flex gap-2">
                            <img
                              style={{
                                width: "18px",
                                height: "18px",
                                marginTop: "2px",
                              }}
                              src={checkTheme1Icon}
                            />
                            Enjoy a complimentary 7-day trial of our premium
                            features.
                          </li>
                          <li className="d-flex gap-2">
                            <img
                              style={{
                                width: "18px",
                                height: "18px",
                                marginTop: "2px",
                              }}
                              src={checkTheme1Icon}
                            />
                            Enjoy a complimentary 7-day trial of our premium
                            features.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                }
              />
            </Form.Group>
            <Form.Group className="col-12 mt-4 text-end">
              <button
                type="button"
                className="btn btnStyle1 btnTheme1 shadow-none px-5 text-center"
              >
                Next
              </button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Change Password Modal  */}
      <Modal
        show={changePassModalshow}
        className="headerModals"
        onHide={changePassModalClose}
        centered
      >
        <Modal.Header
          className="p-sm-4 p-3 position-relative justify-content-center"
          closeButton
        >
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <Form className="row g-2" onSubmit={handleData}>
            <Form.Label className="col-12 mb-0">Change Password</Form.Label>
            <Form.Group className="col-12 position-relative">
              <Form.Control
                className="sahdow-none"
                type={showOldPassword ? "text" : "password"} // Toggle visibility based on state
                name="password"
                placeholder="Old Password"
                onChange={change}
              />
              <button
                type="button"
                className="btn passShowHideBtn p-0 border-0 shadow-none bg-transparent position-absolute d-flex align-items-center"
                onClick={toggleOldPasswordVisibility}
              >
                <img className="w-100" src={showPassIcon} alt="Show/Hide Password" />
                {/* <img className="w-100" src={hidePassIcon}/> */}
              </button>
            </Form.Group>
            <Form.Group className="col-12 position-relative">
              <Form.Control
                className="sahdow-none"
                type={showNewPassword ? "text" : "password"} // Toggle visibility based on state
                name="new_password"
                placeholder="New Password"
                onChange={change}
              />
              <button
                type="button"
                className="btn passShowHideBtn p-0 border-0 shadow-none bg-transparent position-absolute d-flex align-items-center"
                onClick={toggleNewPasswordVisibility}
              >
                <img className="w-100" src={showPassIcon} alt="Show/Hide Password" />
                {/* <img className="w-100" src={hidePassIcon}/> */}
              </button>
            </Form.Group>
            <Form.Group className="col-12 position-relative">
              <Form.Control
                className="sahdow-none"
                type={showConfirmPassword ? "text" : "password"} // Toggle visibility based on state
                name="new_password"
                placeholder="Confirm Password"
                onChange={change}
              />
              <button
                type="button"
                className="btn passShowHideBtn p-0 border-0 shadow-none bg-transparent position-absolute d-flex align-items-center"
                onClick={toggleConfirmPasswordVisibility}
              >
                <img className="w-100" src={showPassIcon} alt="Show/Hide Password" />
                {/* <img className="w-100" src={hidePassIcon}/> */}
              </button>
            </Form.Group>
            <Form.Group className="col-12 mt-4 text-end">
              <button
                type="submit"
                className="btn btnStyle1 btnTheme1 shadow-none px-5 text-center"
              >
                Next
              </button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Manage Account Modal  */}
      <Modal
        show={manageAccountModalshow}
        className="headerModals"
        onHide={manageAccountModalClose}
        centered
      >
        <Modal.Header
          className="p-sm-4 p-3 position-relative justify-content-center"
          closeButton
        >
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <div className="row gap-12">
            <Form.Label className="col-12 mb-0">Manage Account</Form.Label>
            <div className="col-12 position-relative">
              <div className="manageAccountItem rounded-3 p-3">
                <div className="title">Deactivate Account:</div>
                <div className="dec mb-lg-4 mb-3">
                  If you deactivate your account, your profile and all your data
                  will be invisible to the public until you reactivate it.
                </div>
                <button
                  type="button"
                  className="btn btnStyle1 btnTheme1 shadow-none px-5 text-center"
                  onClick={handleDeActivateAccount}
                >
                  Deactivate Account
                </button>
              </div>
            </div>
            <div className="col-12 position-relative">
              <div className="manageAccountItem rounded-3 p-3">
                <div className="title">Delete Account:</div>
                <div className="dec mb-lg-4 mb-3">
                  Once your account is deleted, all of your content will be
                  permanently gone, including your profile, score history,
                  stats, picture, post etc. Consider deactivate your account.
                </div>
                <button
                  type="button"
                  className="btn btnStyle1 btnTheme1 shadow-none px-5 text-center"
                  onClick={handleDeleteAccount}
                >
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Contact Us Modal Modal  */}
      <Modal
        show={contactUsModalshow}
        className="headerModals"
        onHide={contactUsModalClose}
        centered
      >
        <Modal.Header
          className="p-sm-4 p-3 position-relative justify-content-center"
          closeButton
        >
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <Form className="row gap-12" onSubmit={handleSubmit}>
            <Form.Label className="col-12 mb-0 text-center">
              Contact Us
            </Form.Label>
            <Form.Group className="col-12">
              <div
                className="imgOuter mx-auto"
                style={{ width: "100%", maxWidth: "320px" }}
              >
                <img src={ContactUs2} />
              </div>
            </Form.Group>
            <Form.Group className="col-12">
              <Form.Control
                className="sahdow-none"
                type="text"
                name="subject"
                placeholder="Subject"
                onChange={handlechange}
              />
            </Form.Group>
            <Form.Group className="col-12 position-relative">
              <Form.Control
                as="textarea"
                className="sahdow-none"
                name="description"
                placeholder="Full Description..."
                onChange={handlechange}
                rows={3}
              />
            </Form.Group>
            <Form.Group className="col-12 mt-4 text-end">
              <button
                type="submit"
                className="btn btnStyle1 btnTheme1 shadow-none px-5 text-center w-100"
              >
                Send
              </button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Terms Condition Modal  */}
      <Modal
        show={termsConditionsModalshow}
        className="headerModals"
        onHide={termsConditionsModalClose}
        centered
      >
        <Modal.Header
          className="p-sm-4 p-3 position-relative justify-content-center"
          closeButton
        >
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <div className="row gap-12">
            <Form.Label className="col-12 mb-0 text-center">
              Terms & Conditions
            </Form.Label>
            <div className="col-12">
              <div className="pera">
                {terms}
                <h4>{data.description}</h4>

                Golfapathy Inc. provides users with a unique therapeutic experience on virtual greens through its online therapy platform. By agreeing to the terms and conditions, users acknowledge their acceptance of the platform's guidelines. The platform facilitates access to swing services offered by independent therapists who are accredited professionals, ensuring a quality therapeutic experience. Golfapathy Inc. prioritizes user privacy and takes measures to safeguard personal information as outlined in its Privacy Policy. While the platform aims to offer flexibility, users are encouraged to seek local emergency services in case of mental health crises. Golfapathy Inc. disclaims liability for third-party content and any consequential damages. Subscription plans may be subject to change, and users retain the right to cancel subscriptions at their discretion. It is the users' responsibility to maintain account security and adhere to platform conduct guidelines. Updates and modifications to the platform will be communicated to users through email or postings, ensuring transparency and informed usage.
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteConfirmation} onHide={cancelDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to Delete?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelDelete}>
              Cancel
            </Button>
            <Button variant="primary" onClick={confirmDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDeActivateConfirmation} onHide={cancelDeActivate}>
          <Modal.Header closeButton>
            <Modal.Title>Deactivate Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to DeActivate?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelDeActivate}>
              Cancel
            </Button>
            <Button variant="primary" onClick={confirmDeActivate}>
              Deactivate
            </Button>
          </Modal.Footer>
        </Modal>


    </>
  );
}

export default Header;
