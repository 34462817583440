import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons"
import useRequest from "../hooks/useRequest";
import avtarImg from "../assets/images/avtarImg.png";
import backArrow from "../assets/images/backArrow.png";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Link from 'react-bootstrap/Form';

function Comment() {
  const [list, setList] = useState([])
  const navigate = useNavigate();
  const { id } = useParams();
  const [posts, setPosts] = useState([])
  const { request } = useRequest();
  const [comment, setComment] = useState()
  const handleChange = (event) => {
    const { name, value } = event.target;
    setComment(event.target.value)
  }
  const addPost = (id) => {
    setComment('')
    request({
      url: `app/post/post-Comment/${id}`,
      method: "PUT",
      data: { 'comment': comment },
      onSuccess: (data) => {
        setComment('')
        getComments(id);

        console.log("check post in post", data);
        // setPosts(data.data.data)
      },
      onError: (error) => {
        console.log(error);

      }
    })
  }

  const getComments = (id) => {
    request({
      url: `app/post/getData/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setList(data.data)
        console.log("check post in post", list);
        // setPosts(data.data.data)
      },
      onError: (error) => {
        console.log(error);

      }
    })
  }

  useEffect(() => {
    getComments(id)
  }, [])

  const handleDeleteComment = (commentId) => {
    console.log("check its comment working or not ")
    request({
      url: `app/post/removeComment/${id}/${commentId}`,
      method: "PUT",
      onSuccess: (data) => {
        getComments(id)
        console.log("Comment deleted successfully");
        // setPosts(data.data.data)
      },
      onError: (error) => {
        console.log(error);

      }
    })
  }
  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3">
          <div className="col-lg-12">
            <div className="pagreHeader">
              <div className="pageHeadign d-flex align-items-center gap-3">
                <button onClick={() => navigate("/post")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                  <img className="h-100" src={backArrow} />
                </button>
                <div className="txt">Comment</div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="chatMainOuter position-relative">
              <div className="row h-100">

                <div className="col-xxl-12 col-md-12 h-100 chatOuter active">
                  <div className="chatInner bg-white rounded-3 overflow-hidden h-100 d-flex flex-column flex-nowrap p-3">
                    <div className="cometnt-header">
                      <h4 className="mb-0">Recent comments</h4>
                    </div>
                    <div className="chatBody flex-fill h-100 overflow-hidden">
                      <div className="h-100 overflow-auto  d-flex flex-column-reverse gap-3">
                        <div className="msgMain recive  w-100">
                          <div className="card text-dark border-0">
                            {list?.commentInfo?.map((commentData, index) => (
                              <div key={index} >
                                <div className="card-body p-4">
                                  <div className="d-flex flex-start">
                                    <img
                                      className="user-img-c "
                                      src={
                                        commentData?.userId?.profilePic
                                          ? `https://b2btobacco.s3.amazonaws.com/${commentData?.userId?.profilePic}`
                                          : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                            commentData?.userId?.name
                                          )}&size=256`
                                      } />
                                    <div>
                                      <h6 className="fw-bold mb-1">{commentData?.userId?.name}</h6>
                                      <div className="d-flex align-items-center mb-2">
                                        <p className="mb-0 grey-color">
                                          {new Date(commentData.created_At).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric'
                                          })}{' '}
                                          {new Date(commentData.created_At).toLocaleTimeString('en-US', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true
                                          })}
                                        </p>
                                        <a className="link-muted ms-2" onClick={() => handleDeleteComment(commentData._id)}>
                                          <DeleteOutlined />       </a>
                                      </div>
                                      <p className="mb-0 cmt-lastMsg">
                                        {commentData.comment}
                                      </p>
                                    </div>

                                  </div>
                                </div>
                                <hr className="my-0" />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="msg-wrap">
                      <div className="msg-field">
                        <Input
                          type="text"
                          name="comment"
                          value={comment}
                          placeholder="Add a comment..."
                          onChange={handleChange}
                          onPressEnter={() => addPost(id)}
                        />
                      </div>
                      <div className="msg-btn">
                        <Button
                          type="submit"
                          className="btn btn_primary btn-inn"
                          onClick={() => addPost(id)}
                        >
                          Post
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>

  );
}

export default Comment;
