import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Modal from 'react-bootstrap/Modal';
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import ScoreCard from "../assets/images/score-card.svg";
import avtarImg from "../assets/images/avtarImg.png";
import logoVerticaleTheme from "../assets/images/logoVerticaleTheme.png";
import backArrow from "../assets/images/backArrow.png";
import Form from 'react-bootstrap/Form';
import shareIcon from "../assets/images/shareIcon.png";
import gameImg from "../assets/images/gameImg.png";
import searchIcon from "../assets/images/searchIcon.png";
import plusTheme2Icon from "../assets/images/plusTheme2Icon.png";
import { useNavigate, useLocation } from "react-router-dom";
import useRequest from "../../src/hooks/useRequest"
function PlayersSetup2() {
  const [addPlayerShow, addPlayerModal] = useState(false);
  const addPlayerModalClose = () => addPlayerModal(false);
  const addPlayerModalOpen = (fetchFriendList) => addPlayerModal(true);
  useEffect(() => {
    fetchFriendList();
    setUserProfile();
  }, []);
  const { request } = useRequest();
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [friendList, setFriendList] = useState([]);
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  const setUserProfile = () => {
    request({
      url: "app/user/get-profile",
      method: "GET",
      onSuccess: (data) => {
        console.log("Profile data:", data.data.name);
        setProfile(data.data); // Set the user profile data in the state
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };
  const fetchFriendList = () => {
    request({
      url: "/app/friend/user-friend-list",
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        setFriendList(data.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  const handleCheckboxChange = (friendId) => {
    console.log("friendId", friendId)
    // Check if the friendId is already in the selectedFriends array
    if (selectedFriends.includes(friendId)) {
      // If it is, remove it from the selectedFriends array
      setSelectedFriends(selectedFriends.filter(id => id !== friendId));
    } else {
      // If it's not, add it to the selectedFriends array
      setSelectedFriends([...selectedFriends, friendId]);
    }
  };
  const addPlayerData = () => {
    // Make the API call only if at least one friend is selected
    if (selectedFriends.length > 0) {
      const requestData = {
        id: profile?._id,
        players: selectedFriends // Pass the array of selected friend IDs to the API
      };

      request({
        url: `/app/game/joinPlayer`,
        method: "PUT",
        data: requestData,
        onSuccess: (data) => {
          console.log("player joined successfully", data);
          navigate("/players-setup", { state: { response: data.data } })
        },
        onError: (error) => {
          console.log("Error in joining player", error)
        }
      })
    } else {
      console.log("Please select at least one friend and provide the group ID.");
    }
  };

  return (
    <Main>
      <section className="home-wrap vh-100 px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3 h-100 flex-column flex-nowrap">
          <div className="col-lg-12">
            <div className="pageHeadign d-flex align-items-center gap-3">
              <button onClick={() => navigate("/event-created2")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                <img className="h-100" src={backArrow} />
              </button>
              <div className="txt">Players Setup</div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-12">
              <div className="organizerProfile d-flex align-items-center justify-content-between">
                <div className="organizerDetail d-flex align-items-center gap-2">
                  <div className="imgOuter rounded-circle overflow-hidden">
                    <img className="w-100 h-100" src={avtarImg} />
                  </div>
                  <div className="detail">
                    <div className="name">John Doe</div>
                    <div className="dec mt-1">Organizer</div>
                  </div>
                </div>
              </div>
              <div className="leaderProfile">
                <div className="row g-2">
                  <div className="col-sm-6 text-start">
                    <div className="value">Dummy Name</div>
                    <div className="title">Leaderboard Name</div>
                  </div>
                  <div className="col-sm-6 text-sm-end">
                    <div className="value">Individual Competition</div>
                    <div className="title">Competition Type</div>
                  </div>
                </div>
              </div>
              <hr className="border-top border-secondary m-0" />
              <div className="gameDetail d-flex align-items-center gap-2">
                <div className="imgOuter">
                  <img className="w-100 h-100" src={gameImg} />
                </div>
                <div className="detail">
                  <div className="gameTitle">Makena Golf & Beach Club l Makena Maka</div>
                  <div className="gameScore d-flex align-items-center gap-1 my-1">Blue 34.80 / 122</div>
                  <div className="competitionSize">18 Hole</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 flex-fill h-100">
            <div className="teamA">
              <div className="sectionHeading fw-medium text-white fs-6 mb-1">Team A</div>
              <div className="row g-3">
                <div className="col-sm-6">
                  <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-3">
                    <div className="organizerProfile d-flex align-items-center justify-content-between">
                      <div className="organizerDetail d-flex align-items-center gap-2">
                        <div className="imgOuter rounded-circle overflow-hidden">
                          <img className="w-100 h-100" src={avtarImg} />
                        </div>
                        <div className="detail">
                          <div className="name">Dennis Callis</div>
                          <div className="dec mt-1">22-26 / 0.0%</div>
                        </div>
                      </div>
                      <button type="button" className="btn btn-close shadow-none rounded-circle p-2 d-flex align-items-center justify-content-between">
                        <img src={shareIcon} />
                      </button>
                    </div>
                    <div className="leaderProfile">
                      <div className="row g-2">
                        <div className="col-6 text-start">
                          <div className="value"><div className="teeBox d-flex align-items-center gap-1">Red</div></div>
                          <div className="title">Teebox</div>
                        </div>
                        <div className="col-6 text-end">
                          <div className="value">20</div>
                          <div className="title">Handicap</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-3">
                    <div className="organizerProfile d-flex align-items-center justify-content-between">
                      <div className="organizerDetail d-flex align-items-center gap-2">
                        <div className="imgOuter rounded-circle overflow-hidden">
                          <img className="w-100 h-100" src={avtarImg} />
                        </div>
                        <div className="detail">
                          <div className="name">Dennis Callis</div>
                          <div className="dec mt-1">22-26 / 0.0%</div>
                        </div>
                      </div>
                      <button type="button" className="btn btn-close shadow-none rounded-circle p-2 d-flex align-items-center justify-content-between">
                        <img src={shareIcon} />
                      </button>
                    </div>
                    <div className="leaderProfile">
                      <div className="row g-2">
                        <div className="col-6 text-start">
                          <div className="value"><div className="teeBox d-flex align-items-center gap-1">Red</div></div>
                          <div className="title">Teebox</div>
                        </div>
                        <div className="col-6 text-end">
                          <div className="value">20</div>
                          <div className="title">Handicap</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="teamA mt-3">
              <div className="sectionHeading fw-medium text-white fs-6 mb-1">Team B</div>
              <div className="row g-3">
                <div className="col-sm-6">
                  <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-3">
                    <div className="organizerProfile d-flex align-items-center justify-content-between">
                      <div className="organizerDetail d-flex align-items-center gap-2">
                        <div className="imgOuter rounded-circle overflow-hidden">
                          <img className="w-100 h-100" src={avtarImg} />
                        </div>
                        <div className="detail">
                          <div className="name">Dennis Callis</div>
                          <div className="dec mt-1">22-26 / 0.0%</div>
                        </div>
                      </div>
                      <button type="button" className="btn btn-close shadow-none rounded-circle p-2 d-flex align-items-center justify-content-between">
                        <img src={shareIcon} />
                      </button>
                    </div>
                    <div className="leaderProfile">
                      <div className="row g-2">
                        <div className="col-6 text-start">
                          <div className="value"><div className="teeBox d-flex align-items-center gap-1">Red</div></div>
                          <div className="title">Teebox</div>
                        </div>
                        <div className="col-6 text-end">
                          <div className="value">20</div>
                          <div className="title">Handicap</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <button type="button" onClick={() => addPlayerModal(true)} className="btn btnStyle1 btnOutlineTheme2 rounded-3 shadow-none d-flex align-items-center gap-1 bg-white"><img src={plusTheme2Icon} />Add Player</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 text-end">
            <button type="button" className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-5">Next</button>
          </div>
        </div>
      </section>

      {/* Add Player Modal  */}
      <Modal show={addPlayerShow} className="headerModals" onHide={addPlayerModalClose} centered>
        <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <Form className="row gap-12">
            <Form.Group className="col-12 position-relative">
              <Form.Label htmlFor="contactSearch" style={{ cursor: "pointer" }} className="position-absolute top-0 bottom-0 my-auto end-0 me-3 d-flex align-items-center justify-content-center">
                <img src={searchIcon} />
              </Form.Label>
              <Form.Control className="pe-5"
                type="search"
                id="contactSearch"
                placeholder="Search by username, email or Handicap ID"
                aria-describedby="passwordHelpBlock"
              />
            </Form.Group>
            <Form.Group className="col-md-12 selectPlayerOuter">
              {friendList?.map((friend) => (
                <Form.Check
                  key={friend?._id}
                  className="selectPlayerCheck m-0 p-0 d-flex align-items-center justify-content-between flex-row-reverse"
                  name="group1"
                  type="checkbox"
                  id={`player${friend?._id}`} // Ensure the id is unique for each friend
                  onChange={() => handleCheckboxChange(friend?._id)} // Pass friend ID to handleCheckboxChange
                  checked={selectedFriends.includes(friend?._id)} // Check if the friend ID is in selectedFriends array
                  label={
                    <div className="playerPro d-flex align-items-center gap-2">
                      <div className="playerImg overflow-hidden rounded-circle">
                        <img
                          className="w-100 h-100"
                          src={
                            friend?.profilePic
                              ? `https://b2btobacco.s3.amazonaws.com/${friend?.profilePic}`
                              : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                friend?.name
                              )}&size=256`
                          }
                          alt="Friend Profile"
                        />
                      </div>
                      <div className="detail">
                        <div className="name">{friend.name}</div> {/* Use friend's name here */}
                        <div className="dec mt-1">{friend.location}</div> {/* Use friend's location here */}
                      </div>
                    </div>
                  }
                />
              ))}
            </Form.Group>
            <Form.Group className="col-12 mt-4 text-end">
              <button type="button" className="btn btnStyle1 btnTheme1 shadow-none px-5 w-100" onClick={addPlayerData}>Add Players</button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </Main>
  );
}



export default PlayersSetup2;
