import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import ScoreCard from "../assets/images/score-card.svg";
import avtarImg from "../assets/images/avtarImg.png";
import trophyIcon from "../assets/images/trophyIcon.png";
import mediaImg1 from "../assets/images/mediaImg1.png";
import screcardIcon from "../assets/images/screcardIcon.png";
import statsIcon from "../assets/images/statsIcon.png";
import friendsRankingIcon from "../assets/images/friendsRankingIcon.png";
import { useNavigate, useLocation } from "react-router-dom";
import useRequest from "../hooks/useRequest";

function Career() {
  const { request } = useRequest();
  const [posts, setPosts] = useState([])
  const [handicap, sethandicap] = useState({})
  const [avgScore, setAvgcap] = useState({})
  useEffect(() => {
    request({
      url: "/app/post/get-post/Public?page=1&limit=1000",
      method: "GET",
      onSuccess: (data) => {
        console.log("check post in post", data);
        setPosts(data.data.data)
      },
      onError: (error) => {
        console.log(error);
      }
    });
    setHandicapProfile()
    setAvg()
  }, []); // Empty dependency array to run the effect only once on component mount

  const setHandicapProfile = () => {
    request({
      url: "app/game/getHandicapScore",
      method: "GET",
      onSuccess: (data) => {
        console.log("Handica[]:", data.data.name);
        sethandicap(data?.data); // Assuming the API response contains the user profile data
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };
  const setAvg = () => {
    console.log("??????????????????", avgScore)
    request({
      url: "app/game/averageTotalScore",
      method: "GET",
      onSuccess: (data) => {
        console.log("Handica[]:", data.data.totalAverage);
        setAvgcap(data.data); // Assuming the API response contains the user profile data
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };
  const navigate = useNavigate();
  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3  container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className=" rounded-3 bg-white d-flex flex-column flex-nowrap mb-3">
              <div className="p-3 fs-6 text-dark fw-medium">Media</div>
              <div className="p-3 pt-0 mediaImgOuter flex-fill h-100">
                <div className="row g-3">
                  {posts.map((data, index) => (
                    // Check if the media field exists and is not null or undefined
                    data.media && (
                      <div key={index} className="col-6">
                        {data?.media && (
                          <div className="post-main-img">
                            {data.media.endsWith('.mp4') ? (
                              <video controls>
                                <source src={`https://b2btobacco.s3.amazonaws.com/${data.media}`} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <img
                                src={`https://b2btobacco.s3.amazonaws.com/${data.media}`}
                                alt="Post"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sticky-post p-0">
              <div className=" rounded-3 p-3 bg-white w-100">
                <div className="stickyPostGamesScoring row row-cols-xxs-2 row-cols-xs-3 row-cols-4 row-cols-sm-6 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4 row-cols-xxxl-5 align-items-center g-3 w-100 justify-content-start">
                  <div className="col scoringItems text-center">
                    <div className="scor d-flex align-items-center justify-content-center rounded-circle text-white mx-auto"> {handicap?.totalAverage?.toString()?.substring(0, 5) || '0.0'}</div>
                    <div className="title mt-1 text-uppercase">Handicap</div>
                  </div>
                  <div className="col scoringItems text-center">
                    <div className="scor d-flex align-items-center justify-content-center rounded-circle text-white mx-auto">{avgScore?.totalAverage?.toString()?.substring(0, 5) || '0.0'}</div>
                    <div className="title mt-1 text-uppercase">AVG SCORE</div>
                  </div>
                  <div className="col scoringItems text-center">
                    <div className="scor d-flex align-items-center justify-content-center rounded-circle text-white mx-auto"> {avgScore?.averagePuttScore?.toString()?.substring(0, 5) || '0.0'}</div>
                    <div className="title mt-1 text-uppercase">Avg Putts</div>
                  </div>
                  {/* <div className="col scoringItems text-center">
                    <div className="scor d-flex align-items-center justify-content-center rounded-circle text-white mx-auto">0</div>
                    <div className="title mt-1 text-uppercase">Scoring</div>
                  </div>
                  <div className="col scoringItems text-center">
                    <div className="scor d-flex align-items-center justify-content-center rounded-circle text-white mx-auto">0%</div>
                    <div className="title mt-1 text-uppercase">GIR</div>
                  </div> */}
                </div>
                <div className="w-100 mt-3">
                  <button type="button" onClick={() => navigate("/post-score")} className="btn btnStyle1 btnTheme2 btnWidth shadow-none px-3 w-100">Post Score</button>
                </div>
              </div>
              <div className="p-3 w-100 bg-white rounded-3">
                <div className="d-flex flex-column gap-3">
                  <a href="/career-scorecard" className="textBtn screCardBtn fw-medium text-dark d-flex align-items-center gap-12 position-relative">
                    <div className="icon">
                      <img src={screcardIcon} />
                    </div>
                    Scorecard
                  </a>
                  {/* <a className="textBtn screCardBtn fw-medium text-dark d-flex align-items-center gap-12 position-relative">
                    <div className="icon">
                      <img src={statsIcon} />
                    </div>
                    Stats
                  </a> */}
                  <a href="/career-friends-ranking" className="textBtn screCardBtn fw-medium text-dark d-flex align-items-center gap-12 position-relative">
                    <div className="icon">
                      <img src={friendsRankingIcon} alt="Friends Ranking Icon" />
                    </div>
                    Friends Ranking
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </Main>
  );
}



export default Career;
