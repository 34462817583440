import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import ScoreCard from "../assets/images/score-card.svg";
import avtarImg from "../assets/images/avtarImg.png";
import trophyIcon from "../assets/images/trophyIcon.png";
import backArrow from "../assets/images/backArrow.png";
import Form from 'react-bootstrap/Form';
import shareIcon from "../assets/images/shareIcon.png";
import gameImg from "../assets/images/gameImg.png";
import golfIcon from "../assets/images/golfIcon.png";
import { useNavigate } from "react-router-dom";
function RyderEventCreated() {
 
  const navigate = useNavigate();
  return (
    <Main>
      <section className="home-wrap vh-100 px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3 h-100 flex-column flex-nowrap">
          <div className="col-lg-12">
            <div className="pageHeadign d-flex align-items-center gap-3">
              <button onClick={()=>navigate("/event-ryder")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                <img className="h-100" src={backArrow}/>        
              </button> 
              <div className="txt">Event Created</div>
            </div>
          </div>
          <Form className="col-12 h-100 flex-fill d-flex flex-column flex-nowrap">
            <div className="h-100 flex-fill">
              <div className="row g-3">
                <div className="col-12">
                  <div className="eventcard p-3 h-100 rounded-3 bg-white d-flex flex-column gap-12">
                    <div className="d-flex flex-column gap-12">
                      <div className="row g-3 align-items-center justify-content-between">
                        <div className="col-auto">
                          <div className="organizerProfile d-flex align-items-center justify-content-between">
                            <div className="organizerDetail d-flex align-items-center gap-2">
                              <div className="imgOuter rounded-circle overflow-hidden">
                                <img className="w-100 h-100" src={avtarImg}/>
                              </div>
                              <div className="detail">
                                <div className="name">John Doe</div>
                                <div className="dec mt-1">Organizer</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md  order-3 order-md-2">
                          <div className="d-flex align-items-center gap-sm-4 gap-3">
                            <div className="teamColorOuter blue d-flex align-items-center gap-2">
                              <div className="teamColor rounded-1"></div>
                              <div className="detail">
                                <div className="title">Team Blue</div>
                                <div className="value">Jonny</div>
                              </div>
                            </div>
                            <div className="fs-6 text-dark">v/s</div>
                            <div className="teamColorOuter red d-flex align-items-center gap-2">
                              <div className="teamColor rounded-1"></div>
                              <div className="detail">
                                <div className="title">Team red</div>
                                <div className="value">Jonny</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto order-2 order-md-3">
                          <button type="button" className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-3 text-center w-auto d-flex align-items-center gap-2">
                            <img style={{}} src={shareIcon}/>
                            <div className="txt d-none d-sm-block">Share Ryder Link</div>
                          </button>
                        </div>
                      </div>
                      <hr className="my-0 border"/>
                      <div className="leaderProfile">
                        <div className="row g-2">
                          <div className="col-md-3 col-sm-6">
                            <div className="value">2 sessions</div>
                            <div className="title">No. of session</div>
                          </div>
                          <div className="col-md-3 col-sm-6">
                            <div className="value">Sep 28, 2023 - Sep 31 2023</div>
                            <div className="title">Session Date</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row g-3">
                    <div className="col-lg-4 col-sm-6">
                      <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-2">
                        <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
                          <div className="sessionName text-theme2 fw-medium fs-6">Session 1: Individual Match</div>
                          <div className="sessionType fw-medium fs-6">Individual</div>
                        </div>
                        <div className="gameDetail d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={gameImg}/>
                          </div>
                          <div className="detail">
                            <div className="gameTitle">Makena Golf & Beach Club l Makena Maka</div>
                            <div className="gameScore d-flex align-items-center gap-1 my-1">Blue 34.80 / 122</div>
                            <div className="competitionSize">18 Hole</div>
                          </div>
                        </div>
                        <div className="btnGroup d-flex align-items-center gap-3 mt-2">
                          <button type="button" onClick={()=>navigate("/players-setup2")} className="btn btnStyle1 btnOutlineTheme1 btnWidth shadow-none px-5 w-100">Join</button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-2">
                        <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
                          <div className="sessionName text-theme2 fw-medium fs-6">Session 1: Individual Match</div>
                          <div className="sessionType fw-medium fs-6">Individual</div>
                        </div>
                        <div className="gameDetail d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={gameImg}/>
                          </div>
                          <div className="detail">
                            <div className="gameTitle">Makena Golf & Beach Club l Makena Maka</div>
                            <div className="gameScore d-flex align-items-center gap-1 my-1">Blue 34.80 / 122</div>
                            <div className="competitionSize">18 Hole</div>
                          </div>
                        </div>
                        <div className="btnGroup d-flex align-items-center gap-3 mt-2">
                          <button type="button" onClick={()=>navigate("/players-setup2")} className="btn btnStyle1 btnOutlineTheme1 btnWidth shadow-none px-5 w-100">Join</button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-2">
                        <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
                          <div className="sessionName text-theme2 fw-medium fs-6">Session 1: Individual Match</div>
                          <div className="sessionType fw-medium fs-6">Individual</div>
                        </div>
                        <div className="gameDetail d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={gameImg}/>
                          </div>
                          <div className="detail">
                            <div className="gameTitle">Makena Golf & Beach Club l Makena Maka</div>
                            <div className="gameScore d-flex align-items-center gap-1 my-1">Blue 34.80 / 122</div>
                            <div className="competitionSize">18 Hole</div>
                          </div>
                        </div>
                        <div className="btnGroup d-flex align-items-center gap-3 mt-2">
                          <button type="button" onClick={()=>navigate("/players-setup2")} className="btn btnStyle1 btnOutlineTheme1 btnWidth shadow-none px-5 w-100">Join</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </Form>
        </div>
      </section>
    </Main>
  );
}



export default RyderEventCreated;
