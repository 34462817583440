import React, { useState, useEffect } from "react";
import Main from "../components/layout/Main";
import closeIconDark from "../assets/images/closeIconDark.png";
import editIcon from "../assets/images/editIcon.png";
import carbonFilter from "../assets/images/carbonFilter.png";
import plusTheme1 from "../assets/images/plusTheme1.png";
import gameImg from "../assets/images/gameImg.png";
import unfinishedRoundIcon from "../assets/images/unfinishedRoundIcon.png";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import logoVerticaleTheme from "../assets/images/logoVerticaleTheme.png";
import Modal from 'react-bootstrap/Modal';
import searchIcon from "../assets/images/searchIcon.png";
import tabIcon1 from "../assets/images/tabIcon1.png";
import scoreCardModalImg from "../assets/images/scoreCardModalImg.png";
import golfIcon from "../assets/images/golfIcon.png";
import noteIcon from "../assets/images/noteIcon.png";
import useRequest from "../../src/hooks/useRequest"
import { Severty, ShowToast } from "../helper/toast";
import useGeolocation from './useGeolocation';


function Play() {
  const [TeeData, setTeeData] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [courseDetails, setCourseDetails] = useState({
    date: "", // Initialize with default values
    course: "",
    courseId: "",
    courseTee: "",
    teeName: "", // Add teeName property
    courseName: "", // Add courseName property
    holes: "",
  });

  const handlechange = (event) => {
    const { name, value } = event.target;
    setCourseDetails({
      ...courseDetails,
      [name]: value,
    });
    if (name === 'course') {
      const selectedCourse = courseListData.find(course => course.courseId === value);
      setCourseDetails(prevState => ({
        ...prevState,
        courseName: selectedCourse?.name || "",
      }));
    } else if (name === 'courseTee') {
      const selectedTee = TeeData.find(tee => tee._id === value);
      setCourseDetails(prevState => ({
        ...prevState,
        teeName: selectedTee?.name || "",
      }));
    }
  };

  const { request } = useRequest();
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [friendList, setFriendList] = useState([]);
  const [profile, setProfile] = useState({});
  const [courseListData, setCourseListData] = useState([])
  const [allGame, setAllGame] = useState([])
  const [addplayer, setAddPlayer] = useState()

  const navigate = useNavigate();

  const [clubDetailEditShow, clubDetailEditModal] = useState(false);
  const clubDetailEditModalClose = () => clubDetailEditModal(false);
  const clubDetailEditModalOpen = () => clubDetailEditModal(true);

  const [addPlayerShow, addPlayerModal] = useState(false);
  const addPlayerModalClose = () => addPlayerModal(false);
  const addPlayerModalOpen = (fetchFriendList) => addPlayerModal(true);
  const [searchDataQuery, setDataSearchQuery] = useState('');
  const [filteredFriendList, setFilteredFriendList] = useState([]);
  const { location } = useGeolocation();


  useEffect(() => {
    let latitude = null;
    let longitude = null; 
    
    const storedLocation = JSON.parse(localStorage.getItem('userLocation'));
    if (storedLocation) {
      // Reassign latitude and longitude if storedLocation exists
      latitude = storedLocation.latitude;
      longitude = storedLocation.longitude;
    } else {
      // Otherwise, assign from location (make sure location is defined)
      latitude = location.latitude; 
      longitude = location.longitude;
    }

    request({
      url: `/common/getNewcourse?search=${encodeURIComponent(searchQuery)}&latitude=${latitude}&longitude=${longitude}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        setCourseListData(data.data.docs);
      },
      onError: (error) => {
        console.log(error);
      },
    });
    fetchFriendList();
    setUserProfile();
    unfinishedGame();
  }, [searchQuery]);

  const fetchTeeData = (id) => {
    request({
      url: `/common/getCourseTeeData/${id}`,
      method: "GET",
      onSuccess: (data) => {
        // ShowToast(data.message, Severty.SUCCESS)
        setTeeData(data.data.data);
      },
      onError: (error) => {
        console.log(error);
        //ShowToast(error, Severty.ERROR);
      },

    })
  }

  const unfinishedGame = () => {
    request({
      url: `app/game/get-user-game`,
      method: "GET",
      onSuccess: (data) => {
        // ShowToast(data.message, Severty.SUCCESS)
        setAllGame(data.data.docs);
      },
      onError: (error) => {
        console.log(error);
        //ShowToast(error, Severty.ERROR);
      },
    })
  }

  const today = new Date();
  const formattedDate = `${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}${today.getFullYear()}`;

  const [playOnLeaderBoardShow, playOnLeaderBoardModal] = useState(false);
  const playOnLeaderBoardModalClose = () => playOnLeaderBoardModal(false);
  const playOnLeaderBoardModalOpen = () => playOnLeaderBoardModal(true);

  const [show, scoreCardStepModal] = useState(false);
  const scoreCardStepModalClose = () => scoreCardStepModal(false);
  const scoreCardStepModalOpen = () => {
    scoreCardStepModal(true)
    playOnLeaderBoardModal(false)
  };

  const setUserProfile = () => {
    request({
      url: "app/user/get-profile",
      method: "GET",
      onSuccess: (data) => {
        console.log("Profile data:", data.data.name);
        setProfile(data.data); // Set the user profile data in the state
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };
  const userId = profile?._id
  const fetchFriendList = () => {
    request({
      url: "/app/friend/user-friend-list",
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        setFriendList(data.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  // Function to handle search
  const handleDataSearch = (e) => {
    const query = e.target.value;
    setDataSearchQuery(query);

    // Filter the friendList based on the search query
    const filteredFriends = friendList.filter(friend =>
      friend.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredFriendList(filteredFriends);
  };

  const handleCheckboxChange = (friendId, friendName) => {
    // Check if the friend is already selected
    const isFriendSelected = selectedFriends.some(friend => friend.id === friendId);
    if (isFriendSelected) {
      // If the friend is already selected, remove them from the selectedFriends array
      const updatedSelectedFriends = selectedFriends.filter(friend => friend.id !== friendId);
      setSelectedFriends(updatedSelectedFriends);
    } else {
      // If the friend is not selected, add them to the selectedFriends array
      setSelectedFriends([...selectedFriends, { id: friendId, name: friendName }]);
    }
  };

  const addPlayerData = () => {
    console.log("selectedfriends", selectedFriends);
    setAddPlayer(selectedFriends);
    addPlayerModalClose();
  };

  // Function to handle removing a player
  const handleRemovePlayer = (friendToRemove) => {
    // Filter out the friend to be removed based on their ID
    const updatedSelectedFriends = selectedFriends.filter(friend => friend.id !== friendToRemove.id);
    setSelectedFriends(updatedSelectedFriends);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Gather the form data
    const formData = {
      date: event?.target?.date?.value,
      course: courseDetails.courseName, // Use courseName instead of course ID
      courseId: courseDetails.course, // Keep courseId if you need the ID
      courseTee: courseDetails.teeName, // Use teeName instead of courseTee ID
      courseTeeId: courseDetails.courseTee, // Keep courseTeeId if you need the ID
      holes: event?.target?.holes?.value
    };
    // Update the courseDetails state with the form data
    setCourseDetails(formData);
    clubDetailEditModalClose()
    // You can perform further actions here, such as making API calls or validation
  };

  const roundStart = (event) => {
    event.preventDefault();
    if (courseDetails && courseDetails.course && courseDetails.holes) {
      const playersIds = selectedFriends?.map(player => player?.id) || []; // Extracting user IDs from selectedFriends
      const startRoundData = {
        course: courseDetails.courseId,
        courseTee: courseDetails.courseTeeId ? courseDetails.courseTeeId : null,
        holes: courseDetails.holes,
        score: "Net",
        primaryStat: "",
        players: [...playersIds, userId],
        isFinished: true
      };
      request({
        url: "/app/game/create-game",
        method: "POST",
        data: startRoundData,
        onSuccess: (data) => {
          const gameId = data.data._id;
          navigate(`/score-keeping/${gameId}`, { state: { response: data.data } });
        },
        onError: (error) => {
          console.log(error);
        },
      });
    } else {
      console.log("please all detail fill")
      ShowToast('Please fill all course details.', Severty.ERROR);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  function navigateToScoreKeepingPage(gameId) {
    window.location.href = `/score-keeping/${gameId}`;
  }

  const gameDelete = (id) => {
    console.log("check the data value ")
    request({
      url: `/app/game/game-delete/${id}`,
      method: "Delete",
      onSuccess: (data) => {
        unfinishedGame()
        ShowToast('Game Deleted Successfully.', Severty.SUCCESS);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };


  return (
    <>
      <Main>
        <section className="home-wrap playPageOuter  px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
          <div className="row g-3 ">
            <div className="col-xl-8">
              <div className="customCard rounded-3 bg-white d-flex flex-column flex-nowrap h-100">
                <div className="customCardHeader pb-0">
                  <div className="title pb-3 border-bottom">Round Setup</div>
                </div>
                <div className="customCardBody flex-fill h-100 overflow-auto">
                  <div className="golfClubDetail pb-3 d-flex align-items-center justify-content-between">
                    <div className=" d-flex align-items-center gap-12">
                      <div className="icon">
                        <img src={carbonFilter} />
                      </div>
                      <div className="detail">
                        <div className="name">{courseDetails.course}</div>
                        <div className="points my-1">{courseDetails.courseTee}</div>
                        <div className="hole">Hole: <span className="text-dark">{courseDetails.holes}</span></div>
                        <div className="">{courseDetails.course}</div>
                        <div className="">{courseDetails.courseTee}</div>

                      </div>
                    </div>
                    <button type="button" onClick={clubDetailEditModalOpen} className="btn p-0 shadow-none border-0 shadow-none align-self-baseline">
                      <img src={editIcon} />
                    </button>
                  </div>
                  <div className="clubPlayerOuter mb-4" >
                    <div className="clubPlayer d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <div className="imgOuter rounded-circle overflow-hidden">
                          <img className="w-100 h-100" src={
                            profile?.profilePic
                              ? `https://b2btobacco.s3.amazonaws.com/${profile?.profilePic}`
                              : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                profile?.name
                              )}&size=256`
                          } />
                        </div>
                        <div className="detail">
                          <div className="name">{profile?.name}</div>
                          <div className="dec mt-1">{profile?.country}</div>
                        </div>
                      </div>
                      {/* <button type="button" className="btn btnClose bg-transparent p-0 shadow-none border-0" onClick={() => handleRemovePlayer(profile)}>
                <img className="" src={closeIconDark} />
            </button> */}
                    </div>
                  </div>
                  {selectedFriends?.map((friend, index) => (
                    <div className="clubPlayerOuter mb-4" key={index}>
                      <div className="clubPlayer d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <div className="imgOuter rounded-circle overflow-hidden">

                            <img className="w-100 h-100" src={
                              friend?.profilePic
                                ? `https://b2btobacco.s3.amazonaws.com/${friend?.profilePic}`
                                : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                  friend?.name
                                )}&size=256`
                            } />
                          </div>
                          <div className="detail">
                            <div className="name">{friend?.name}</div>
                            <div className="dec mt-1">{friend?.country}</div>
                          </div>
                        </div>
                        <button type="button" className="btn btnClose bg-transparent p-0 shadow-none border-0" onClick={() => handleRemovePlayer(friend)}>
                          <img className="" src={closeIconDark} />
                        </button>
                      </div>
                    </div>
                  ))}

                  <button type="button" onClick={roundStart} className="btn btnStyle1 btnTheme2 btnWidth shadow-none px-3 w-100">Start Round</button>
                  <div className="btnGroup d-flex flex-column flex-sm-row align-items-center gap-3 mt-4">
                    <button type="button" onClick={addPlayerModalOpen} className="btn btnStyle1 btnOutlineTheme1 btnWidth shadow-none px-3 w-100 d-flex align-items-center gap-1 justify-content-center"><img src={plusTheme1} /> Add Player</button>
                    <button type="button" className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-3 w-100">Play on Leaderboard</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 ">
              <div className="customCard rounded-3 bg-white d-flex flex-column flex-nowrap card-auto-h">
                <div className="customCardHeader p-3 pb-0">
                  <div className="d-flex gap-12 pb-3 border-bottom">
                    <div className="icon">
                      <img className="w-100" src={unfinishedRoundIcon} />
                    </div>
                    <div className="detail">
                      <div className="name">Unfinished Rounds</div>
                      <div className="dec mt-2">Do you want to resume a round that previously started?</div>
                    </div>
                  </div>
                </div>
                <div className="customCardBody p-3 flex-fill h-100 overflow-auto">
                  {allGame?.filter(game => !game.isFinished).map((game) => (
                    <div key={game?._id} className="eventcard border-bottom p-0 d-flex flex-column gap-2 position-relative" onClick={() => navigateToScoreKeepingPage(game?._id)} >
                      <div className="gameDetail d-flex align-items-center gap-2">
                        <div className="imgOuter">
                          <img className="w-100 h-100" src={
                            game?.gamePic
                              ? `https://b2btobacco.s3.amazonaws.com/${game?.gamePic}`
                              : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                game?.course?.name
                              )}&size=256`
                          } />
                        </div>
                        <div className="detail">
                          <div className="gameTitle">{game?.course?.name}</div>
                          <div className="gameScore d-flex align-items-center gap-1 mt-1">{game?.courseTeeData[0]?.name} {game?.courseTeeData[0]?.rating} / {game?.courseTeeData[0]?.slope}</div>
                          <div className="competitionSize text-muted">{game?.teamSize}</div>
                        </div>
                        <button type="button" className="btn btnClose border-0 shadow-none bg-transparent p-0 position-absolute top-0 end-0" onClick={(e) => {
                          e.stopPropagation();
                          gameDelete(game?._id);
                        }}>
                          <img className="" style={{ width: "14px" }} src={closeIconDark} alt="Close" />
                        </button>
                      </div>
                      <div className="leaderProfile">
                        <div className="row g-2">
                          <div className="col-sm-6 text-start">
                            <div className="value">{game?.totalScore}</div>
                            <div className="title">Round Score</div>
                          </div>
                          <div className="col-sm-6 text-sm-end">
                            <div className="value">{game?.handicapScore}</div>
                            <div className="title">Handicap</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

          </div>
        </section>
      </Main>


      {/* Club detail edit Modal  */}
      <Modal show={clubDetailEditShow} className="headerModals" onHide={clubDetailEditModalClose} centered>
        <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <Form className="row g-2" onSubmit={handleFormSubmit}>
            <Form.Label className="col-12 mb-0">Course Details</Form.Label>
            <Form.Group className="col-md-12">
              <Form.Control
                className="shadow-none"
                type="date"
                name="date"
                placeholder="MM/DD/YYYY"
                defaultValue={today.toISOString().split("T")[0]}
              />
            </Form.Group>
            <Form.Group className="col-sm-6">
              <Form.Control
                type="text"
                placeholder="Search for a course..."
                value={searchQuery}
                onChange={handleSearchChange}
              />

            </Form.Group>
            <Form.Group className="col-sm-6">
              <Form.Select
                as="select"
                name="course"
                value={courseDetails.course}
                onChange={(e) => {
                  fetchTeeData(e.target.value);
                  setCourseDetails({ ...courseDetails, course: e.target.value }); // Update the state

                  const selected = courseListData.filter(item => item.courseId == e.target.value)
                  console.log(selected, "selected");
                  handlechange(e)
                }}
              >
                <option value="">Select Course</option>
                {courseListData && courseListData?.map((course) => (
                  <option key={course?._id} value={course?.courseId}>
                    {course?.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-sm-6">
              <Form.Select
                as="select"
                name="courseTee"
                value={courseDetails.courseTee}
                onChange={handlechange}
              // onChange={({target})=>fetchTeeData(target.value)}
              >
                <option value="">Select Tees</option>
                {TeeData && TeeData?.map((tee) => (
                  <option key={tee.id} value={tee._id}>
                    {tee.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-sm-6">
              <Form.Select
                aria-label="Default select example"
                name="holes"
              >
                <option>Select Hole</option>
                <option value="9">Back 9</option>
                <option value="18">18 Holes</option>
                <option value="9">Front 9</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-12 mt-4 text-end">
              <button type="submit" onClick={clubDetailEditModalOpen} className="btn btnStyle1 btnTheme1 shadow-none px-5 w-100">Save</button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Add Player Modal  */}
      <Modal show={addPlayerShow} className="headerModals" onHide={addPlayerModalClose} centered>
        <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <Form className="row gap-12">
            <Form.Group className="col-12 position-relative">
              <Form.Label htmlFor="contactSearch" style={{ cursor: "pointer" }} className="position-absolute top-0 bottom-0 my-auto end-0 me-3 d-flex align-items-center justify-content-center">
                <img src={searchIcon} />
              </Form.Label>
              <Form.Control
                className="pe-5"
                type="search"
                id="contactSearch"
                placeholder="Search by username"
                aria-describedby="passwordHelpBlock"
                value={searchDataQuery}
                onChange={handleDataSearch}
              />
            </Form.Group>
            <Form.Group className="col-md-12 selectPlayerOuter">
              {filteredFriendList?.map((friend) => (
                <Form.Check
                  key={friend?._id}
                  className="selectPlayerCheck m-0 p-0 d-flex align-items-center justify-content-between flex-row-reverse"
                  name="group1"
                  type="checkbox"
                  id={`player${friend?._id}`} // Ensure the id is unique for each friend
                  onChange={() => handleCheckboxChange(friend?._id, friend?.name)}
                  checked={selectedFriends.some(selectedFriend => selectedFriend?.id === friend?._id)}
                  label={
                    <div className="playerPro d-flex align-items-center gap-2">
                      <div className="playerImg overflow-hidden rounded-circle">
                        <img
                          className="w-100 h-100"
                          src={
                            friend?.profilePic
                              ? `https://b2btobacco.s3.amazonaws.com/${friend?.profilePic}`
                              : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                friend?.name
                              )}&size=256`
                          }
                          alt="Friend Profile"
                        />
                      </div>
                      <div className="detail">
                        <div className="name">{friend.name}</div> {/* Use friend's name here */}
                        <div className="dec mt-1">{friend.location}</div> {/* Use friend's location here */}
                      </div>
                    </div>
                  }
                />
              ))}
            </Form.Group>
            <Form.Group className="col-12 mt-4 text-end">
              <button type="button" className="btn btnStyle1 btnTheme1 shadow-none px-5 w-100" onClick={addPlayerData}>Add Players</button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Play on leaderboard Modal  */}
      <Modal show={playOnLeaderBoardShow} className="headerModals" onHide={playOnLeaderBoardModalClose} centered>
        <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <div className="row gap-3">
            <div className="col-12 text-center mb-3">
              <img src={scoreCardModalImg} />
              <div className="mt-3 d-flex align-items-center justify-content-center gap-2 fs-6 fw-medium text-dark">
                Play on Leader board
                <button type="button" onClick={scoreCardStepModalOpen} className="p-0 border-0 p-0 bg-transparent"><img src={noteIcon} /></button>
              </div>
              <div className="dec mt-1 text-muted w-75 mx-auto fs-14 fw-medium">Complete live with a large group by creating or joining an existing group.</div>
            </div>
            <div className="col-12 d-flex flex-column gap-3">
              <button type="button" onClick={() => navigate("/leader-board")} className="btn textBtn screCardBtn border-0 text-start bg-transparent fw-medium text-dark d-flex align-items-center gap-12 position-relative">
                <div className="icon">
                  <img src={tabIcon1} />
                </div>
                <div className="">
                  <div className="title">Create</div>
                  <div className="dec text-muted fw-normal fs-14">New group or competition</div>
                </div>
              </button>
              <button type="button" onClick={() => navigate("/current-leaderboard")} className="btn textBtn screCardBtn border-0 text-start bg-transparent fw-medium text-dark d-flex align-items-center gap-12 position-relative">
                <div className="icon">
                  <img src={tabIcon1} />
                </div>
                <div className="">
                  <div className="title">Join</div>
                  <div className="dec text-muted fw-normal fs-14">Track score while playing</div>
                </div>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* How it work Modal  */}
      <Modal show={show} size="lg" className="headerModals" onHide={scoreCardStepModalClose} centered>
        <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <div className="row g-3 align-items-center">
            <div className="col-lg-4 col-md-6 text-center">
              <img src={scoreCardModalImg} />
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="title fs-6 fw-medium">The Best way to Compete!</div>
              <div className="dec fs-14 mt-1 text-muted">Live leader boards allow you to get real time updates of how your buddies are playing, even if hey are in a different foursome!</div>
              <ul className="p-0 m-0 list-unstyled d-flex flex-column gap-sm-4 gap-3 mt-sm-4 mt-3">
                <li className="">
                  <div className="d-flex align-items-center gap-1 fs-14 fw-medium"><img src={golfIcon} /> See Where You Stand:</div>
                  <div className="fs-12 text-muted fw-medium">Click on the Ranking icon to see the leader board table and how you rank.</div>
                </li>
                <li className="">
                  <div className="d-flex align-items-center gap-1 fs-14 fw-medium"><img src={golfIcon} />Track Foursome Score:</div>
                  <div className="fs-12 text-muted fw-medium">Track up to 4 scores in your phone to feed the leader board.</div>
                </li>
              </ul>
              <div className="fs-14 fw-medium text-dark mt-4">NOTE: Make sure to complete your upload at the end to post scores.</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>

  );
}
export default Play