import React, { useState, useEffect } from "react";
import Main from "../components/layout/Main";
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import useRequest from "../hooks/useRequest";
import backArrow from "../assets/images/backArrow.png";

function NotificationSetting() {
  const { request } = useRequest();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    request: "",
    is_message: "",
    friend_Activity: "",
    your_Activity: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Call getProfile function inside useEffect
    getProfile();
  }, []); // Empty dependency array means this effect runs only once after initial render

  const getProfile = () => {
    request({
      url: `app/user/get-profile`,
      method: "GET",
      onSuccess: (data) => {
        setProfileData(data.data);
        console.log("check post in post", data);
      },
      onError: (error) => {
        console.log(error);
      }
    });
  };

  const toggleSwitch = (field) => {
    // Create a copy of profileData
    const updatedProfileData = { ...profileData };
  
    // Update the field based on the toggle switch clicked
    updatedProfileData[field] = !updatedProfileData[field];
  
    // If the field is 'friend_Activity' and its value becomes true,
    // set 'request' to false
    if (field === 'friend_Activity' && updatedProfileData[field]) {
      updatedProfileData['request'] = false;
    }
    // Call updateProfile with the updated profile data
    updateProfile(updatedProfileData);
  };
  
  


  const updateProfile = (updatedProfileData) => {
    setLoading(true);
    request({
      url: "app/user/update-profile",
      method: "POST",
      data: updatedProfileData,
      onSuccess: (data) => {
        // Update the profileData state with the data received from the API response
        setProfileData(data.data);
        console.log("Profile updated successfully:", data);
        setLoading(false);
      },
      onError: (error) => {
        console.error("Error updating profile:", error);
        setLoading(false);
      },
    });
  };
  

  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-4lg-4 pb-3 container-fluid">
        <div className="row g-3">
          <div className="col-lg-12">
            <div className="pageHeadign d-flex align-items-center gap-3">
              <button onClick={() => navigate("/Home")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                <img className="h-100" src={backArrow} alt="Back" />
              </button>
              <div className="txt">Notification Preferences</div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="settingCard bg-white p-3 rounded-3">
              <div className="settingCardHeader pb-3">
                <div className="title">App Activity:</div>
                <div className="subTitle mt-1">Triggered alert from you and your friends.</div>
              </div>
              <div className="settingCardBody">
                <Form className="d-flex flex-column" style={{ gap: '12px' }}>
                  {profileData && (
                    <>
                      <Form.Check
                        className="switchCheck shadow-none p-0 d-flex align-items-center gap-2 justify-content-between"
                        type="switch"
                        id="InvitesRequests"
                        label="Invites and Requests"
                        checked={profileData.request}
                        onChange={() => toggleSwitch('request')}
                      />
                      <Form.Check
                        className="switchCheck shadow-none p-0 d-flex align-items-center gap-2 justify-content-between"
                        type="switch"
                        id="CommentsMessages"
                        label="Comments and Messages"
                        checked={profileData.is_message}
                        onChange={() => toggleSwitch('is_message')}
                      />
                      <Form.Check
                        className="switchCheck shadow-none p-0 d-flex align-items-center gap-2 justify-content-between"
                        type="switch"
                        id="FriendsActivity"
                        label="Friends Activity"
                        checked={profileData.friend_Activity}
                        onChange={() => toggleSwitch('friend_Activity')}
                      />
                      <Form.Check
                        className="switchCheck shadow-none p-0 d-flex align-items-center gap-2 justify-content-between"
                        type="switch"
                        id="YourActivity"
                        label="Your Activity"
                        checked={profileData.your_Activity}
                        onChange={() => toggleSwitch('your_Activity')}
                      />
                      {/* Add more Form.Check components here if needed */}
                    </>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>
  );
}

export default NotificationSetting;
