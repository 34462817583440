import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import backArrow from "../assets/images/backArrow.png";
import Table from 'react-bootstrap/Table';
import useRequest from "../hooks/useRequest";
import { useNavigate, useLocation, useParams } from "react-router-dom";

function AllScoreData() {
  const { request } = useRequest();
  const location = useLocation();
  const [gameData, setGameData] = useState({})
  const [scoreData, setScoreData] = useState([])
  const [scoreGame, setScoreGame] = useState()
  const { id } = useParams(); // Get the id parameter from the URL
  //   const scoreCardData = location?.state
  //   const id = location?.state?.response?.data[0]?.data[0]?.gameId
  console.log("gameId", id)

  useEffect(() => {
    if (id != null) {
      request({
        url: `app/game/game-detail/${id}`,
        method: 'GET',
        onSuccess: (data) => {
          console.log("check post in post", data);
          setGameData(data.data)
          getScoreGame(id)
          scoreDataDetail(data.data?.course, data.data?.courseTee?._id)
        },
        onError: (error) => {
          console.log(error);

        }
      })
    }
  }, [id])

  const getScoreGame = (id) => {
    request({
      url: `app/score/get-score-game/${id}`,
      method: 'GET',
      onSuccess: (data) => {
        console.log("check post in post", data);
        setScoreGame(data.data.data)

      },
      onError: (error) => {
        console.log(error);

      }
    })
  }
  const scoreDataDetail = (courseId, teeId) => {
    console.log("????????????????????????????????????????????????????????????????????")
    request({
      url: `common/scoreData/${courseId}/${teeId}`,
      method: "GET",
      onSuccess: (data) => {
        console.log("check game in data", data);
        setScoreData(data.data.data)
      },
      onError: (error) => {
        console.log(error);

      }
    })
  }
  function formatCreatedAt(createdAt) {
    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}/${month}/${year} - DURATION: ${hours}H ${minutes}M`;
  }

  console.log("scoreGame??????????????????????", scoreGame)
  const navigate = useNavigate();
  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="pagreHeader">
              <div className="pageHeadign d-flex align-items-center gap-3 mb-3">
                <button onClick={() => navigate("/career-scorecard")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                  <img className="h-100" src={backArrow} />
                </button>
                <div className="txt">Game Details</div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="gameDetailCard bg-white p-3 d-flex flex-column gap-3">
              <div className="cardOne d-sm-flex align-items-center justify-content-between">
                <div className="">
                  <div className="value">
                    {gameData.createdAt && formatCreatedAt(gameData.createdAt)}
                  </div>
                  <div className="title mt-1">{gameData?.courseData?.name}</div>
                </div>
                {/* <div className="totalScore d-flex align-items-center gap-12 mt-2 mt-sm-0">
                  <div className="scoreItem p-3 text-center">
                    <div className="value">49/43</div>
                    <div className="title mt-1">Gross/Net</div>
                  </div>
                  <div className="scoreItem p-3 text-center">
                    <div className="value">12.3/0</div>
                    <div className="title mt-1">Hdcp/Change</div>
                  </div>
                </div> */}
              </div>
              <div className="cardOne border-bottom pb-3">
                <div className="row g-3">
                  <div className="col-lg-3 col-sm-4">
                    <div className="title">{gameData?.courseTee?.name} 6216 yds</div>
                    <div className="value mt-1">Tee</div>
                  </div>
                  <div className="col-xxl-2 col-md-3 col-sm-4">
                    <div className="title">{gameData?.courseTee?.rating}/{gameData?.courseTee?.slope}</div>
                    <div className="value mt-1">Course Rating/Slope</div>
                  </div>
                  <div className="col-xxl-2 col-md-3 col-sm-4">
                    <div className="title">37</div>
                    <div className="value mt-1">Course Par</div>
                  </div>
                </div>
              </div>
              <div className="cardOne">
                <div className="row g-3">
                  <div className="col-lg-3 col-sm-4 col-6">
                    <div className="title">{gameData?.putts}</div>
                    <div className="value mt-1">Total putts</div>
                  </div>
                  {/* <div className="col-xl-1 col-sm-2 col-6">
                    <div className="title">23</div>
                    <div className="value mt-1">GIR</div>
                  </div>
                  <div className="col-xl-1 col-sm-2 col-6">
                    <div className="title">54</div>
                    <div className="value mt-1">FH</div>
                  </div>
                  <div className="col-xl-2 col-sm-3 col-6">
                    <div className="title">{gameData?.putts}</div>
                    <div className="value mt-1">Putts per Hole</div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="gameDetailCard bg-white d-flex flex-column gap-3">
              <div className="gameDetailTable">
                <Table responsive className="m-0">
                  <thead>

                    <tr className="headerFirst">
                      <th colSpan={2}>Hole</th>
                      {scoreData.map((list, index) => (
                        <th>{list.hole !== null ? list.hole : 0}</th>
                      ))}
                    </tr>
                    <tr className="headerSecond">
                      <th colSpan={2}>Par <br /> Handicap</th>
                      {scoreData.map((list, index) => (
                        <th>{list.par !== null ? list.par : 0}<br />{list.handicap !== null ? list.handicap : 0}</th>
                      ))}

                    </tr>
                  </thead>
                  <tbody>
                    {scoreGame?.map((playerData, index) => (
                      <tr key={index}>
                        <td colSpan={2}>
                          <div className="playerProfile">
                            <div className="profileImg">
                              <img
                                className="w-100 h-100"
                                src={
                                  playerData?.userData?.profilePic
                                    ? `https://b2btobacco.s3.amazonaws.com/${playerData?.userData?.profilePic}`
                                    : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                      playerData?.userData?.name
                                    )}&size=256`
                                } />
                            </div>
                            <div className="name">{playerData.userData.name}</div>
                            {/* <div className="dec">CH {playerData.handicap}</div> */}
                          </div>
                        </td>
                        {playerData?.data?.map((item, idx) => (
                          <td>
                            <div className="d-flex flex-column gap-2">

                              <div key={idx} className="scoreType">
                                {item?.scoreValue != null ? item?.scoreValue : '-'}
                              </div>
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>

          </div>
          {/* <div className="col-12">
            <div className="gameDetailCard bg-white d-flex flex-column gap-3">
              <div className="gameDetailTable">
                <Table responsive className="m-0">
                  <thead>
                    <tr className="headerFirst">
                      <th colSpan={2}>Hole</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>Total</th>
                    </tr>
                    <tr className="headerSecond">
                      <th colSpan={2}>Par <br/> Handicap</th>
                      <th>5<br/>11</th>
                      <th>4<br/>1</th>
                      <th>4<br/>7</th>
                      <th>4<br/>17</th>
                      <th>4<br/>3</th>
                      <th>4<br/>15</th>
                      <th>4<br/>5</th>
                      <th>37</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="playerProfile">
                          <div className="profileImg">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="name">D. Trinidad</div>
                          <div className="dec">CH 31</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">Gross</div>
                          <div className="scoreType">Net</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">8</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">7</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">7</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">6</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">4</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">8</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">8</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column h-100">
                          <div className="scoreType h-100">56</div>
                          <div className="scoreType h-100">40</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="playerProfile">
                          <div className="profileImg">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="name">D. Trinidad</div>
                          <div className="dec">CH 31</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">Gross</div>
                          <div className="scoreType">Net</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">8</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">7</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">7</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">6</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">4</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">8</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">8</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column h-100">
                          <div className="scoreType h-100">56</div>
                          <div className="scoreType h-100">40</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="playerProfile">
                          <div className="profileImg">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="name">D. Trinidad</div>
                          <div className="dec">CH 31</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">Gross</div>
                          <div className="scoreType">Net</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">8</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">7</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">7</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">6</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">4</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">8</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column gap-4">
                          <div className="scoreType">8</div>
                          <div className="scoreType">8</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column h-100">
                          <div className="scoreType h-100">56</div>
                          <div className="scoreType h-100">40</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </Main>

  );
}
export default AllScoreData;