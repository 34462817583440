import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import Modal from 'react-bootstrap/Modal';
import avtarImg from "../assets/images/avtarImg.png";
import backArrow from "../assets/images/backArrow.png";
import shareIcon from "../assets/images/shareIcon.png";
import Form from 'react-bootstrap/Form';
import gameImg from "../assets/images/gameImg.png";
import logoVerticaleTheme from "../assets/images/logoVerticaleTheme.png";
import plusTheme2Icon from "../assets/images/plusTheme2Icon.png";
import { useNavigate, useLocation } from "react-router-dom";
import useRequest from "../hooks/useRequest";
import searchIcon from "../assets/images/searchIcon.png";
function PlayersSetup() {
  const [addPlayerShow, addPlayerModal] = useState(false);
  const addPlayerModalClose = () => addPlayerModal(false);
  const addPlayerModalOpen = (fetchFriendList) => addPlayerModal(true);
  useEffect(() => {
    fetchLeaderDetail()
    fetchFriendList();
    setUserProfile();
  }, []);
  const { request } = useRequest();
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [friendList, setFriendList] = useState([]);
  const [profile, setProfile] = useState(null);
  const location = useLocation();
  const [detail, setDetail] = useState({})
  const gameId = location?.state?.response?._id
  const navigate = useNavigate();
  const setUserProfile = () => {
    request({
      url: "app/user/get-profile",
      method: "GET",
      onSuccess: (data) => {
        console.log("Profile data:", data.data.name);
        setProfile(data.data); // Set the user profile data in the state
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };
  const fetchFriendList = () => {
    request({
      url: "/app/friend/user-friend-list",
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        setFriendList(data.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  const handleCheckboxChange = (friendId) => {
    console.log("friendId", friendId)
    // Check if the friendId is already in the selectedFriends array
    if (selectedFriends.includes(friendId)) {
      // If it is, remove it from the selectedFriends array
      setSelectedFriends(selectedFriends.filter(id => id !== friendId));
    } else {
      // If it's not, add it to the selectedFriends array
      setSelectedFriends([...selectedFriends, friendId]);
    }
  };
  const addPlayerData = () => {
    // Make the API call only if at least one friend is selected
    if (selectedFriends.length > 0) {
      const requestData = {
        id: gameId,
        players: selectedFriends // Pass the array of selected friend IDs to the API
      };

      request({
        url: `/app/game/joinPlayer`,
        method: "PUT",
        data: requestData,
        onSuccess: (data) => {
          console.log("player joined successfully", data);
          navigate("/players-setup", { state: { response: data.data } })
          addPlayerModalClose();
          fetchLeaderDetail()
        },
        onError: (error) => {
          console.log("Error in joining player", error)
        }
      })
    } else {
      console.log("Please select at least one friend and provide the group ID.");
    }
  };
  const fetchLeaderDetail = () => {
    if (gameId) {
      request({
        url: `app/game/game-detail/${gameId}`,
        method: "GET",
        onSuccess: (data) => {
          console.log("Game Detail Data:", data.data);
          setDetail(data.data);
        },
        onError: (error) => {
          console.log("Error fetching game detail:", error);
        }
      });
    }
  };
  const handleRemovePlayer = async (leaderId, playerId) => {
    request({
      url: `app/leader/removePlayer/${leaderId}/${playerId}`,
      method: "GET",
      onSuccess: (data) => {
        console.log("Player removed successfully:", data);
        // Handle any necessary state updates or UI changes after player removal
      },
      onError: (error) => {
        console.error("Error removing player:", error);
        // Handle any error UI feedback or logging
      }
    });
  };
  const handleClick = () => {
    // Navigate to the desired route
    navigate(`/score-keeping/${gameId}`);
  };
  return (
    <Main>

      <section className="home-wrap vh100 px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3 h-100 flex-column flex-nowrap">
          <div className="col-lg-12">
            <div className="pageHeadign d-flex align-items-center gap-3">
              <button onClick={() => navigate("/event-created")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                <img className="h-100" src={backArrow} />
              </button>
              <div className="txt">Players Setup</div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-12">
              <div className="organizerProfile d-flex align-items-center justify-content-between">
                <div className="organizerDetail d-flex align-items-center gap-2">
                  <div className="imgOuter rounded-circle overflow-hidden">
                    <img className="w-100 h-100" src={avtarImg} />
                  </div>
                  <div className="detail">
                    <div className="name">{detail?.user?.name}</div>
                    <div className="dec mt-1">Organizer</div>
                  </div>
                </div>
              </div>
              <div className="leaderProfile">
                <div className="row g-2">
                  <div className="col-6 text-start">
                    <div className="value">{detail?.leaderName}</div>
                    <div className="title">Leaderboard Name</div>
                  </div>
                  <div className="col-6 text-end">
                    <div className="value">{detail?.type}</div>
                    <div className="title">Competition Type</div>
                  </div>
                </div>
              </div>
              <hr className="border-top border-secondary m-0" />
              <div className="gameDetail d-flex align-items-center gap-2">
                <div className="imgOuter">
                  <img className="w-100 h-100" src={gameImg} />
                </div>
                <div className="detail">
                  <div className="gameTitle">{detail?.courseData?.name}</div>
                  <div className="gameScore d-flex align-items-center gap-1 my-1">{detail?.courseTee?.name}</div>
                  <div className="competitionSize">{detail?.holes}holes</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 flex-fill h-100">
            <div className="sectionHeading fw-medium text-white fs-6 mb-1">Team Details</div>
            <div className="row g-3">
              {detail.gameDetailsId?.map((hello, index) => (
                <div className="col-lg-6 col-sm-12">
                  <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-3">
                    <div key={index} className="organizerProfile main-organizer">
                      <div className="organizerDetail d-flex align-items-center gap-2">
                        <div className="imgOuter rounded-circle overflow-hidden">
                          <img className="w-100 h-100" src={avtarImg} />
                        </div>
                        <div className="detail">
                          <div className="name">{hello?.user?.name || 'Unknown'}</div>
                          <div className="dec mt-1">22-26 / 0.0%</div>
                        </div>

                        <button
                          type="button"
                          className="btn btn-close shadow-none rounded-circle p-2 d-flex align-items-center justify-content-between or"
                          onClick={() => handleRemovePlayer(detail?._id, hello?.user?._id)}
                        >
                          <img src={shareIcon} />
                        </button>
                      </div>

                      <div className="leaderProfile">
                        <div className="row g-2">
                          <div className="col-6 text-start">
                            <div className="value">
                              <div className=" d-flex align-items-center gap-1">{detail?.courseTee?.name}</div>
                            </div>
                            <div className="title">Teebox</div>
                          </div>
                          <div className="col-6 text-end">
                            <div className="value">{hello?.handicapScore}</div>
                            <div className="title">Handicap</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-12">
                <button type="button" onClick={addPlayerModalOpen} className="btn btnStyle1 btnOutlineTheme2 rounded-3 shadow-none d-flex align-items-center gap-1 bg-white"><img src={plusTheme2Icon} />Add Player</button>
              </div>
            </div>
          </div>
          <div className="col-12 text-end">
            <button type="button" onClick={handleClick} className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-5">Next</button>
          </div>
        </div>
      </section>
      {/* Add Player Modal  */}
      <Modal show={addPlayerShow} className="headerModals" onHide={addPlayerModalClose} centered>
        <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <Form className="row gap-12">
            <Form.Group className="col-12 position-relative">
              <Form.Label htmlFor="contactSearch" style={{ cursor: "pointer" }} className="position-absolute top-0 bottom-0 my-auto end-0 me-3 d-flex align-items-center justify-content-center">
                <img src={searchIcon} />
              </Form.Label>
              <Form.Control className="pe-5"
                type="search"
                id="contactSearch"
                placeholder="Search by username, email or Handicap ID"
                aria-describedby="passwordHelpBlock"
              />
            </Form.Group>
            <Form.Group className="col-md-12 selectPlayerOuter">
              {friendList?.map((friend) => (
                <Form.Check
                  key={friend?._id}
                  className="selectPlayerCheck m-0 p-0 d-flex align-items-center justify-content-between flex-row-reverse"
                  name="group1"
                  type="checkbox"
                  id={`player${friend?._id}`} // Ensure the id is unique for each friend
                  onChange={() => handleCheckboxChange(friend?._id)} // Pass friend ID to handleCheckboxChange
                  checked={selectedFriends.includes(friend?._id)} // Check if the friend ID is in selectedFriends array
                  label={
                    <div className="playerPro d-flex align-items-center gap-2">
                      <div className="playerImg overflow-hidden rounded-circle">
                        <img
                          className="w-100 h-100"
                          src={
                            friend?.profilePic
                              ? `https://b2btobacco.s3.amazonaws.com/${friend?.profilePic}`
                              : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                friend?.name
                              )}&size=256`
                          }
                          alt="Friend Profile"
                        />
                      </div>
                      <div className="detail">
                        <div className="name">{friend.name}</div> {/* Use friend's name here */}
                        <div className="dec mt-1">{friend.location}</div> {/* Use friend's location here */}
                      </div>
                    </div>
                  }
                />
              ))}
            </Form.Group>
            <Form.Group className="col-12 mt-4 text-end">
              <button type="button" className="btn btnStyle1 btnTheme1 shadow-none px-5 w-100" onClick={addPlayerData}>Add Players</button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </Main>
  );
}



export default PlayersSetup;
