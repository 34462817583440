import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import ScoreCard from "../assets/images/score-card.svg";
import avtarImg from "../assets/images/avtarImg.png";
import trophyIcon from "../assets/images/trophyIcon.png";
import backArrow from "../assets/images/backArrow.png";
import qrSmallImg from "../assets/images/qrSmallImg.png";
import closeIconDark from "../assets/images/closeIconDark.png";
import tabIcon1 from "../assets/images/tabIcon1.png";
import noteIcon from "../assets/images/noteIcon.png";
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import logoVerticaleTheme from "../assets/images/logoVerticaleTheme.png";
import scoreCardModalImg from "../assets/images/scoreCardModalImg.png";
import golfIcon from "../assets/images/golfIcon.png";
import shareIcon from "../assets/images/shareIcon.png";
import golfIconDark from "../assets/images/golfIconDark.png";
import handicapRangeIcon from "../assets/images/handicapRangeIcon.png";
import { useNavigate, useLocation } from "react-router-dom";
import friendScrecardIcon from "../assets/images/friendScrecardIcon.png";
import trophyIcon2 from "../assets/images/trophyIcon2.png";
import chatIcon from "../assets/images/chatIcon.png";
import accountUser from "../assets/images/user.png";
import largeQrImg from "../assets/images/largeQrImg.png";
import useRequest from "../hooks/useRequest";

function FriendsRanking() {

  const navigate = useNavigate();
  const { request } = useRequest();
  const [show, scoreCardStepModal] = useState(false);
  const scoreCardStepModalClose = () => scoreCardStepModal(false);
  const scoreCardStepModalOpen = () => scoreCardStepModal(true);


  const [qrCodeShow, qrCodeModal] = useState(false);
  const qrCodeModalClose = () => qrCodeModal(false);
  const qrCodeModalOpen = () => qrCodeModal(true);
  const [handicap, sethandicap] = useState({})
  const [friendList, setFriendList] = useState([])
  const [avgScore, setAvgcap] = useState({})
  useEffect(() => {
    setHandicapProfile();
    setAvg();
    friendRanking()
  }, [])
  const setHandicapProfile = () => {
    request({
      url: "app/game/getHandicapScore",
      method: "GET",
      onSuccess: (data) => {
        sethandicap(data?.data); // Assuming the API response contains the user profile data
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };
  const setAvg = () => {
    request({
      url: "app/game/averageTotalScore",
      method: "GET",
      onSuccess: (data) => {
        setAvgcap(data.data); // Assuming the API response contains the user profile data
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };
  const friendRanking = () => {
    request({
      url: "app/friend/user-friend-list",
      method: "GET",
      onSuccess: (data) => {
        setFriendList(data?.data?.data)

      },
      onError: (error) => {
        console.log("Error in fetching friend List", error)
      }
    })
  };
  return (

    <>
      <Main>
        <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
          <div className="row g-3">
            <div className="col-lg-12">
              <div className="pagreHeader">
                <div className="pageHeadign d-flex align-items-center gap-3">
                  <button onClick={() => navigate("/career")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                    <img className="h-100" src={backArrow} />
                  </button>
                  <div className="txt">Friends Ranking</div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row g-3">
                <div className="col-xxl-8">
                  <div className="rankingType bg-white rounded-3 p-3">
                    <div className="rankingTypeOuter row g-3 row-cols-xxs-2 row-cols-3 row-cols-sm-4 row-cols-lg-6 row-cols-xxl-6 row-cols-xxxl-8 align-items-center w-100">
                      <div className="col rankingTypeItems text-center">
                        <div className="rank d-flex align-items-center justify-content-center rounded-circle text-white mx-auto fs-6 fw-medium">{handicap?.totalAverage?.toFixed(1) || '0.0'}</div>
                        <div className="title mt-1 text-uppercase fw-medium ">Avg Handicap <br /> <span className="text-muted fs-14 fw-normal"></span></div>
                      </div>
                      <div className="col rankingTypeItems text-center">
                        <div className="rank d-flex align-items-center justify-content-center rounded-circle text-white mx-auto fs-6 fw-medium">{avgScore?.totalAverage?.toString()?.substring(0, 5) || '0.0'}</div>
                        <div className="title mt-1 text-uppercase fw-medium ">Avg Score <br /> <span className="text-muted fs-14 fw-normal"></span></div>
                      </div>
                      <div className="col rankingTypeItems text-center">
                        <div className="rank d-flex align-items-center justify-content-center rounded-circle text-white mx-auto fs-6 fw-medium"> {avgScore?.averagePuttScore?.toString()?.substring(0, 5) || '0.0'}</div>
                        <div className="title mt-1 text-uppercase fw-medium ">Avg Putts <br /> <span className="text-muted fs-14 fw-normal"></span></div>
                      </div>
                      {/* <div className="col rankingTypeItems text-center">
                        <div className="rank d-flex align-items-center justify-content-center rounded-circle text-white mx-auto fs-6 fw-medium">28th</div>
                        <div className="title mt-1 text-uppercase fw-medium ">GIR% <br /> <span className="text-muted fs-14 fw-normal">17.7</span></div>
                      </div> */}
                    </div>
                  </div>
                  {friendList.map((list, index) => (
                    <div key={index} className="rankingOuter bg-white rounded-3 mt-3">
                      <div className="rankingItem p-3 d-flex align-items-center justify-content-between border-bottom">

                        <div key={index} className="d-flex align-items-center gap-2">
                          {/* <div className="rank rounded-1 d-flex align-items-center justify-content-center">
                            1<sup>st</sup>
                          </div> */}
                          <div className="playerPro d-flex align-items-center gap-2">
                            <div className="imgOuter rounded-circle overflow-hidden">
                              <img className="w-100 h-100" src={
                                list?.profilePic
                                  ? `https://b2btobacco.s3.amazonaws.com/${list?.profilePic}`
                                  : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                    list?.name
                                  )}&size=256`
                              } />
                            </div>
                            <div className="detail">
                              <div className="name">{list.name}</div>
                              {/* <div className="dec text-muted fs-14">Queen, NY</div> */}
                            </div>
                          </div>
                        </div>
                        {/* <div className="totalPoints bg-white rounded-circle text-theme2 d-flex align-items-center justify-content-center">
                          {(list?.scores?.handicapScore !== undefined) ? list?.scores?.handicapScore?.toFixed(1) : 0}
                        </div> */}

                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Main>
      <Modal show={qrCodeShow} className="headerModals" onHide={qrCodeModalClose} centered>
        <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3 text-center">
          <div className="fs-16 fw-medium mb-2">Your QR Code</div>
          <div className="qrouter mx-auto mb-4">
            <img className="w-100 h-100" src={largeQrImg} />
          </div>
          <div className="profile d-flex align-items-center gap-12 justify-content-center">
            <div className="imgOuter rounded-circle overflow-hidden">
              <img className="w-100 h-100" src={accountUser} />
            </div>
            <div className="detail text-start">
              <div className="name fs-6 fw-medium text-dark">Patricia Sanders</div>
              <div className="dec text-muted fs-14">India TG Golf Club</div>
              <div className="dec text-muted fs-12">Since 2023</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>

  );
}
export default FriendsRanking;