import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import ScoreCard from "../assets/images/score-card.svg";
import avtarImg from "../assets/images/avtarImg.png";
import trophyIcon from "../assets/images/trophyIcon.png";
import backArrow from "../assets/images/backArrow.png";
import closeIconDark from "../assets/images/closeIconDark.png";
import tabIcon1 from "../assets/images/tabIcon1.png";
import noteIcon from "../assets/images/noteIcon.png";
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import logoVerticaleTheme from "../assets/images/logoVerticaleTheme.png";
import scoreCardModalImg from "../assets/images/scoreCardModalImg.png";
import golfIcon from "../assets/images/golfIcon.png";
import { useNavigate, useLocation } from "react-router-dom";

function AddScore() {
  
  const navigate = useNavigate();

  const [show, scoreCardStepModal] = useState(false);
  const scoreCardStepModalClose = () => scoreCardStepModal(false);
  const scoreCardStepModalOpen = () => scoreCardStepModal(true);
  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="">
          <div className="col-lg-12">
            <div className="pagreHeader">
              <div className="pageHeadign d-flex align-items-center gap-3 mb-3">
                <button  onClick={()=>navigate("/score-keeping")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                  <img className="h-100" src={backArrow}/>        
                </button> 
                <div className="txt">Add Score</div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="tabsCard">
              <Form className="">
                  <div className="tabsCardInner rounded-3 p-3 bg-white">
                    <div className="row g-2">
                    <Form.Group className="col-xl-4 col-sm-6">
                      <Form.Control className="sahdow-none" type="date" placeholder="MM/DD/YYYY" />
                    </Form.Group>
                    <Form.Group className="col-xl-4 col-sm-6">
                      <Form.Control className="sahdow-none" type="text" placeholder="User Name" />
                    </Form.Group>
                    <Form.Group className="col-xl-4 col-sm-6">
                      <Form.Control className="sahdow-none" type="search" placeholder="Search course" />
                    </Form.Group>
                    <Form.Group className="col-xl-4 col-sm-6">
                      <Form.Select aria-label="Default select example">
                        <option>Select a Teebox</option>
                        <option value="1">Teebox1</option>
                        <option value="2">Teebox2</option>
                        <option value="3">Teebox3</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="col-xl-4 col-sm-6">
                      <Form.Select aria-label="Default select example">
                        <option>Select Round</option>
                        <option value="1">Round1</option>
                        <option value="2">Round2</option>
                        <option value="3">Round3</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="col-xl-4 col-sm-6">
                      <Form.Select aria-label="Default select example">
                        <option>Select Event</option>
                        <option value="1">Event1</option>
                        <option value="2">Event2</option>
                        <option value="3">Event3</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="col-12 mt-3">
                      <div className="tableOuter theme1">
                        <Table responsive bordered className="mb-0">
                          <thead>
                            <tr>
                              <th style={{minWidth:"200px", maxWidth:"200px"}}>HOLE</th>
                              <th>1</th>
                              <th>2</th>
                              <th>3</th>
                              <th>4</th>
                              <th>5</th>
                              <th>6</th>
                              <th>7</th>
                              <th>8</th>
                              <th>9</th>
                              <th>OUT</th>
                              <th>10</th>
                              <th>11</th>
                              <th>12</th>
                              <th>13</th>
                              <th>14</th>
                              <th>15</th>
                              <th>16</th>
                              <th>17</th>
                              <th>18</th>
                              <th>IN</th>
                              <th>OUT</th>
                              <th colSpan={2}></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>TEE</td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td rowSpan={2} colSpan={2}>
                                <div className="">Handicap: 25</div>
                                <div className="mt-2">Teebox Hdcp</div>
                              </td>
                            </tr>
                            <tr>
                              <td>PAR</td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                            </tr>
                            <tr>
                              <td>INDEX</td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td className="text-center" style={{verticalAlign:"middle"}}>HCP</td>
                              <td className="text-center" style={{verticalAlign:"middle"}}>NET</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-12 mt-3">
                      <div className="tableOuter">
                        <Table responsive bordered className="mb-0"> 
                          <thead>
                            <tr>
                              <th style={{minWidth:"200px", maxWidth:"200px"}}>SCORE</th>
                              <th className="text-white">1</th>
                              <th className="text-white">2</th>
                              <th className="text-white">3</th>
                              <th className="text-white">4</th>
                              <th className="text-white">5</th>
                              <th className="text-white">6</th>
                              <th className="text-white">7</th>
                              <th className="text-white">8</th>
                              <th className="text-white">9</th>
                              <th className="text-white">OUT</th>
                              <th className="text-white">10</th>
                              <th className="text-white">11</th>
                              <th className="text-white">12</th>
                              <th className="text-white">13</th>
                              <th className="text-white">14</th>
                              <th className="text-white">15</th>
                              <th className="text-white">16</th>
                              <th className="text-white">17</th>
                              <th className="text-white">18</th>
                              <th className="text-white">IN</th>
                              <th className="text-white">OUT</th>
                              <th colSpan={2}></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-muted">
                              Score for hdcp purposes
                              <br/>
                              (Adjusted Gross Score)
                              </td>
                              <td colSpan={21}>
                                {/* <Form.Control className="sahdow-none text-center" type="text" placeholder="" /> */}
                              </td>
                              <td colSpan={1}>
                                <div className="">Score</div>
                                <div className="mt-2">Value</div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-12 mt-3">
                      <div className="tableOuter">
                        <Table responsive bordered className="mb-0">
                          <thead>
                            <tr>
                              <th className="text-muted" style={{minWidth:"200px", maxWidth:"200px"}}>Optional data input</th>
                              <th className="text-white">1</th>
                              <th className="text-white">2</th>
                              <th className="text-white">3</th>
                              <th className="text-white">4</th>
                              <th className="text-white">5</th>
                              <th className="text-white">6</th>
                              <th className="text-white">7</th>
                              <th className="text-white">8</th>
                              <th className="text-white">9</th>
                              <th className="text-white">OUT</th>
                              <th className="text-white">10</th>
                              <th className="text-white">11</th>
                              <th className="text-white">12</th>
                              <th className="text-white">13</th>
                              <th className="text-white">14</th>
                              <th className="text-white">15</th>
                              <th className="text-white">16</th>
                              <th className="text-white">17</th>
                              <th className="text-white">18</th>
                              <th className="text-white">IN</th>
                              <th className="text-white">OUT</th>
                              <th colSpan={3}></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>PUTTS</td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td colSpan={3}>
                              </td>
                            </tr>
                            <tr>
                              <td>PENALTIES</td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td colSpan={3}>
                              </td>
                            </tr>
                            <tr>
                              <td>TEE ACCURACY</td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td>
                                <Form.Control className="sahdow-none text-center" type="text" placeholder="" />
                              </td>
                              <td className="text-center" colSpan={1} style={{verticalAlign:"middle"}}>FR</td>
                              <td className="text-center" colSpan={1} style={{verticalAlign:"middle"}}>PAR</td>
                              <td className="text-center" colSpan={1} style={{verticalAlign:"middle"}}>OVERALL</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-xxl-2 col-xl-3 col-md-4 col-sm-6">
                      <Form.Check
                      className="checkBox m-0 p-0 d-flex align-items-center gap-2"
                        type="checkbox"
                        id="practice"
                        label={
                          <>
                          <div className="text-dark fw-medium fs-6">Practice / Offseason Round</div>
                          </>
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-xxl-2 col-xl-3 col-md-4 col-sm-6">
                      <Form.Check
                      className="checkBox m-0 p-0 d-flex align-items-center gap-2"
                        type="checkbox"
                        id="tournament"
                        label={
                          <>
                          <div className="text-dark fw-medium fs-6">Tournament Score</div>
                          </>
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-xxl-2 col-xl-3 col-md-4 col-sm-6">
                      <Form.Check
                      className="checkBox m-0 p-0 d-flex align-items-center gap-2"
                        type="checkbox"
                        id="privateScore"
                        label={
                          <>
                          <div className="text-dark fw-medium fs-6">Private Score Upload</div>
                          </>
                        }
                      />
                    </Form.Group>

                  </div>
                </div>
                <div className="text-end mt-sm-4 mt-3 d-flex gap-3 justify-content-end">
                  <button type="button" className="btn btnStyle1 btnOutlineTheme1 btnWidth shadow-none px-5">Cancel</button>
                  <button type="button" className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-5">Submit</button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    {/* Score card Modal  */}
    <Modal show={show} size="lg" className="headerModals" onHide={scoreCardStepModalClose} centered>
      <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
        <Modal.Title>
          <img src={logoVerticaleTheme}/>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-sm-4 p-3">
        <div className="row g-3 align-items-center">
          <div className="col-lg-4 col-md-6 text-center">
            <img src={scoreCardModalImg}/>
          </div>
          <div className="col-lg-8 col-md-6">
            <div className="title fs-6 fw-medium">Scorecard Picture Service</div>
            <div className="dec fs-14 mt-1 text-muted">We will convert your physical scorecard digitally and upload it to your profile</div>
            <ul className="p-0 m-0 list-unstyled d-flex flex-column gap-sm-4 gap-3 mt-sm-4 mt-3">
              <li className="">
                <div className="d-flex align-items-center gap-1 fs-14 fw-medium"><img src={golfIcon}/> STEP 1:</div>
                <div className="fs-12 text-muted fw-medium">Take a picture of your scorecard once you finish your round?</div>
              </li>
              <li className="">
                <div className="d-flex align-items-center gap-1 fs-14 fw-medium"><img src={golfIcon}/> STEP 2:</div>
                <div className="fs-12 text-muted fw-medium">Upload the image of your scorecard to theGolf app.</div>
              </li>
              <li className="">
                <div className="d-flex align-items-center gap-1 fs-14 fw-medium"><img src={golfIcon}/> STEP 3:</div>
                <div className="fs-12 text-muted fw-medium">Our team will digitally convert your scorecard and connect it to your account</div>
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </Main>

  );
}
export default AddScore;