import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import ScoreCard from "../assets/images/score-card.svg";
import avtarImg from "../assets/images/avtarImg.png";
import trophyIcon from "../assets/images/trophyIcon.png";
import backArrow from "../assets/images/backArrow.png";
import closeIconDark from "../assets/images/closeIconDark.png";
import tabIcon1 from "../assets/images/tabIcon1.png";
import noteIcon from "../assets/images/noteIcon.png";
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import useRequest from "../../src/hooks/useRequest";
import { useNavigate, useLocation } from "react-router-dom";

function LeaderBoard() {
  const { request } = useRequest();
  const [courseListData, setCourseListData] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [TeeData, setTeeData] = useState([])
  const [leaderData, setLeaderData] = useState({
    leaderName: "",
    leaderPassword: "",
    course: null,
    courseTee: null,
    holes: "",
    score: "Net",
    primaryStat: "",
  })

  useEffect(() => {
    console.log("searchQuery", searchQuery)
    request({
      url: `/common/getNewcourse?search=${encodeURIComponent(searchQuery)}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        setCourseListData(data.data.docs);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, [searchQuery]);

  const fetchTeeData = (id) => {
    request({
      url: `/common/getCourseTeeData/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setTeeData(data.data.data);
      },
      onError: (error) => {
        console.log(error);
      },

    })
  }
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const addLeader = (event) => {
    console.log("leaderData", leaderData)
    event.preventDefault()
    leaderData['holes'] = selectedValue;
    request({
      url: "/app/game/create-game",
      method: "POST",
      data: leaderData,
      onSuccess: (data) => {
        navigate("/event-created", { state: { response: data.data } });
      },
      onError: (error) => {
        console.log(error);
      },
    })
  }

  const handlechange = (event) => {
    const { name, value } = event.target;
    setLeaderData({ ...leaderData, [name]: value })
  }

  const navigate = useNavigate();
  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3 h-100 flex-column flex-nowrap">
          <div className="col-lg-12">
            <div className="pagreHeader d-block p-sm-3 p-2 rounded-3 bg-white">
              <div className="title mb-1">Create a Live Leader Board</div>
              <div className="dec">Select the type of competition you wish to create.</div>
            </div>
          </div>
          <div className="col-12 flex-fill d-flex flex-column flex-nowrap h-100 autoHeight">
            <Tabs className="tabsOuter autoHeight mb-4 border-0 d-flex gap-12 flex-wrap flex-sm-nowrap"
              defaultActiveKey="individual"
              id="uncontrolled-tab-example"
            >
              <Tab className="m-0" eventKey="individual" title={
                <>
                  <div className="d-flex gap-2">
                    <div className="tabIcon">
                      <img className="w-100" src={tabIcon1} />
                    </div>
                    <div className="tabDetail">
                      <div className="title d-flex align-items-center gap-2">Individual</div>
                      <div className="subTitle text-start">Each player competes individually.</div>
                    </div>
                  </div>
                </>
              }>{ }
                <div className="tabsCard h-100">
                  <Form className="row g-2" onSubmit={addLeader}>
                    <div className="tabsCardInner h-100 flex-fill">
                      <div className="rounded-3 p-3 bg-white">
                        <div className="row g-2">
                          <Form.Label className="col-12 mb-0">Leader Board</Form.Label>
                          <Form.Group className="col-sm-6">
                            <Form.Control className="sahdow-none" type="text" name="leaderName" required="leaderName" placeholder="Name" onChange={handlechange} />
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Control className="sahdow-none" type="password" name="leaderPassword" required="leaderPassword" req placeholder="Enter password to share with participants" onChange={handlechange} />
                          </Form.Group>
                          <Form.Label className="col-12 mb-0 mt-3">Course Details</Form.Label>
                          <Form.Group className="col-sm-6">
                            <Form.Control
                              type="text"
                              placeholder="Search for a course..."
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />

                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Control
                              as="select"
                              name="course"
                              required="course"
                              value={leaderData.course}
                              onChange={(e) => {
                                fetchTeeData(e.target.value);
                                // setLeaderData({ ...leaderData, [target.name]: target.value })
                                handlechange(e)
                              }}
                            >
                              <option value="">Select Course</option>
                              {courseListData && courseListData?.map((course) => (
                                <option key={course?._id} value={course?.courseId}>
                                  {course?.name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Control
                              as="select"
                              name="courseTee"
                              value={leaderData.tee}
                              onChange={handlechange}
                            // onChange={({target})=>fetchTeeData(target.value)}
                            >
                              <option value="">Select Tees</option>
                              {TeeData && TeeData?.map((tee) => (
                                <option key={tee.id} value={tee._id}>
                                  {tee.name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Select
                              aria-label="Default select example"
                              name="holes"
                              onChange={(e) => {
                                setSelectedValue(e.target.value)
                              }}>
                              <option>Select Hole</option>
                              <option value="9">Back 9</option>
                              <option value="18">18 Holes</option>
                              <option value="9">Front 9</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Label className="col-12 mb-0 mt-3">Format</Form.Label>
                          <Form.Group className="col-sm-6">
                            <Form.Select aria-label="Default select example" >
                              <option>Select primary stat</option>
                              <option value="1">stat1</option>
                              <option value="2">stat2</option>
                              <option value="3">stat3</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Select aria-label="Default select example" name="score">
                              <option>Select Score</option>
                              <option value="Net">Net</option>
                              <option value="Gross">Gross</option>
                            </Form.Select>
                          </Form.Group>
                          {/* <Form.Group className="col-12 mt-3">
                            <Form.Label className="mb-0">Tour Events</Form.Label>
                            <div className="subLabel">Assign round to one of your tour events. Tour are setup in the “My Tour Section.”</div>
                          </Form.Group> */}
                          {/* <Form.Group className="col-sm-6">
                            <Form.Select aria-label="Default select example">
                              <option>Select an event</option>
                              <option value="1">Event1</option>
                              <option value="2">Event2</option>
                              <option value="3">Event3</option>
                            </Form.Select>
                          </Form.Group> */}
                          {/* <Form.Group className="col-12 mt-3">
                            <Form.Label className="mb-0">Handicapping</Form.Label>
                          </Form.Group> */}
                          {/* <Form.Group className="col-sm-6">
                            <Form.Select aria-label="Default select example">
                              <option>Select default handicap</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </Form.Select>
                          </Form.Group> */}
                          {/* <Form.Group className="col-sm-6">
                            <Form.Check
                              className="switchCheck leaderBoard form-control shadow-none mb-0 d-flex align-items-center justify-content-between gap-2 h-100 py-2" style={{ borderRadius: "6px" }}
                              type="switch"
                              id="PublicProfile"
                              label="Allow players to edit their handicaps."
                            />
                          </Form.Group> */}
                        </div>
                      </div>
                    </div>
                    <div className="text-end mt-3">
                      <button type="submit" className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-5">Next</button>
                    </div>
                  </Form>
                </div>
              </Tab>
              <Tab className="m-0" eventKey="teams" title={
                <>
                  <div className="d-flex gap-2">
                    <div className="tabIcon">
                      <img className="w-100" src={tabIcon1} />
                    </div>
                    <div className="tabDetail">
                      <div className="title d-flex align-items-center gap-2">Teams <img src={noteIcon} /></div>
                      <div className="subTitle text-start">Competition in teams of 2, 3 or 4 players.</div>
                    </div>
                  </div>
                </>
              }>
                <div className="tabsCard h-100">
                  <Form className="" onSubmit={addLeader}>
                    <div className="tabsCardInner h-100 flex-fill">
                      <div className="rounded-3 p-3 bg-white">
                        <div className="row g-2">
                          <Form.Label className="col-12 mb-0">Leader Board</Form.Label>
                          <Form.Group className="col-sm-6">
                            <Form.Control className="sahdow-none" type="text" placeholder="Name" />
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Control className="sahdow-none" type="password" placeholder="Enter password to share with participants" />
                          </Form.Group>
                          <Form.Label className="col-12 mb-0 mt-3">Course Details</Form.Label>
                          <Form.Group className="col-sm-6">
                            <Form.Control className="sahdow-none" type="search" placeholder="Search course by name" />
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Select aria-label="Default select example">
                              <option>Select a Teebox</option>
                              <option value="1">Teebox1</option>
                              <option value="2">Teebox2</option>
                              <option value="3">Teebox3</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Select aria-label="Default select example">
                              <option>Select Round</option>
                              <option value="1">Round1</option>
                              <option value="2">Round2</option>
                              <option value="3">Round3</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Label className="col-12 mb-0 mt-3">Format</Form.Label>
                          <Form.Group className="col-sm-6">
                            <Form.Select aria-label="Default select example">
                              <option>Select primary stat</option>
                              <option value="1">stat1</option>
                              <option value="2">stat2</option>
                              <option value="3">stat3</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Select aria-label="Default select example">
                              <option>Select Score</option>
                              <option value="1">101</option>
                              <option value="2">102</option>
                              <option value="3">103</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="col-sm-6  mt-3">
                            <Form.Label className="mb-0 d-flex align-items-center gap-1">Team Setup <img src={noteIcon} /></Form.Label>
                            <Form.Select aria-label="Default select example">
                              <option>Select team size</option>
                              <option value="1">Team1</option>
                              <option value="2">Team2</option>
                              <option value="3">Team3</option>
                            </Form.Select>
                            <div className="subLabel mt-1">Assign round to one of your tour events. Tour are setup in the “My Tour Section.”</div>
                          </Form.Group>
                          <Form.Group className="col-sm-6  mt-3">
                            <Form.Label className="mb-0 d-flex align-items-center gap-1">Competition <img src={noteIcon} /></Form.Label>
                            <Form.Select aria-label="Default select example">
                              <option>Select format</option>
                              <option value="1">format1</option>
                              <option value="2">format2</option>
                              <option value="3">format3</option>
                            </Form.Select>
                            <div className="subLabel mt-1">Assign round to one of your tour events. Tour are setup in the “My Tour Section.”</div>
                          </Form.Group>
                          <Form.Group className="col-12 mt-3">
                            <Form.Label className="mb-0">Handicapping</Form.Label>
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Check
                              className="switchCheck leaderBoard form-control shadow-none mb-0 d-flex align-items-center justify-content-between gap-2 h-100 py-2" style={{ borderRadius: "6px" }}
                              type="switch"
                              id="PublicProfile"
                              label="Allow players to edit their handicaps."
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="text-end mt-3">
                      <button type="submit" className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-5">Next</button>
                    </div>
                  </Form>
                </div>
              </Tab>
              <Tab className="m-0" eventKey="ryder" title={
                <>
                  <div className="d-flex gap-2">
                    <div className="tabIcon">
                      <img className="w-100" src={tabIcon1} />
                    </div>
                    <div className="tabDetail">
                      <div className="title d-flex align-items-center gap-2">Ryder <img src={noteIcon} />  </div>
                      <div className="subTitle text-start">Two large teams compete against each other.</div>
                    </div>
                  </div>
                </>
              }>
                <div className="tabsCard h-100">
                  <Form className="">
                    <div className="tabsCardInner h-100 flex-fill">
                      <div className="rounded-3 p-3 bg-white">
                        <div className="row g-2">
                          <Form.Label className="col-12 mb-0">Ryder Cup</Form.Label>
                          <Form.Group className="col-sm-6">
                            <Form.Control className="sahdow-none" type="text" placeholder="Name" />
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Control className="sahdow-none" type="password" placeholder="Enter password to share with participants" />
                          </Form.Group>
                          <Form.Label className="col-12 mb-0 mt-3">Team Names <small className="text-muted">(Optional)</small></Form.Label>
                          <Form.Group className="col-sm-6">
                            <div className="teamNameColorInput blue position-relative">
                              <Form.Control className="sahdow-none pe-5" type="text" placeholder="Enter name or keep default “Blue”" />
                            </div>
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <div className="teamNameColorInput red position-relative">
                              <Form.Control className="sahdow-none pe-5" type="text" placeholder="Enter name or keep default “Red”" />
                            </div>
                          </Form.Group>
                          <Form.Label className="col-12 mb-0 mt-3">Format</Form.Label>
                          <Form.Group className="col-sm-6">
                            <Form.Select aria-label="Default select example">
                              <option>Select Select Score</option>
                              <option value="1">Score1</option>
                              <option value="2">Score2</option>
                              <option value="3">Score3</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Select aria-label="Default select example">
                              <option>Select strokes format</option>
                              <option value="1">format1</option>
                              <option value="2">format2</option>
                              <option value="3">format3</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="col-12 mt-3">
                            <Form.Label className="mb-0">Handicapping</Form.Label>
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Select aria-label="Default select example">
                              <option>Select default handicap</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="col-sm-6">
                            <Form.Check
                              className="switchCheck leaderBoard form-control shadow-none mb-0 d-flex align-items-center justify-content-between gap-2 h-100 py-2" style={{ borderRadius: "6px" }}
                              type="switch"
                              id="PublicProfile"
                              label="Allow players to edit their handicaps."
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="text-end mt-3">
                      <button type="button" onClick={() => navigate("/event-ryder")} className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-5">Next</button>
                    </div>
                  </Form>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </Main>
  );
}



export default LeaderBoard;
