import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import backArrow from "../assets/images/backArrow.png";
import Table from 'react-bootstrap/Table';
import useRequest from "../hooks/useRequest";
import { useNavigate, useLocation, useParams } from "react-router-dom";

function GameDetails() {
  const { request } = useRequest();
  const location = useLocation();
  const [gameData, setGameData] = useState({})
  const [scoreData, setScoreData] = useState([])
  const [scoreGame, setScoreGame] = useState()

  const { id } = useParams();

  useEffect(() => {
    if (id != null) {
      request({
        url: `app/game/game-detail/${id}`,
        method: 'GET',
        onSuccess: (data) => {
          console.log("check post in post", data);
          setGameData(data.data)
          getScoreGame(id)
          scoreDataDetail(data.data?.course, data.data?.courseTee?._id)
        },
        onError: (error) => {
          console.log(error);

        }
      })
    }
  }, [id])

  const getScoreGame = (id) => {
    request({
      url: `app/score/get-score-game/${id}`,
      method: 'GET',
      onSuccess: (data) => {
        console.log("check post in post", data);
        setScoreGame(data.data.data)

      },
      onError: (error) => {
        console.log(error);

      }
    })
  }
  const scoreDataDetail = (courseId, teeId) => {
    request({
      url: `common/scoreData/${courseId}/${teeId}`,
      method: "GET",
      onSuccess: (data) => {
        console.log("check game in data", data);
        setScoreData(data.data.data)
      },
      onError: (error) => {
        console.log(error);

      }
    })
  }
  function formatCreatedAt(createdAt) {
    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${month}/${day}/${year} - DURATION: ${hours}H ${minutes}M`;
  }

  const navigate = useNavigate();

  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="pagreHeader">
              <div className="pageHeadign d-flex align-items-center gap-3 mb-3">
                <button onClick={() => navigate(`/score-keeping/${id}`)} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                  <img className="h-100" src={backArrow} />
                </button>
                <div className="txt">Game Details</div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="gameDetailCard bg-white p-3 d-flex flex-column gap-3">
              <div className="cardOne d-sm-flex align-items-center justify-content-between">
                <div className="">
                  <div className="value">
                    {gameData.createdAt && formatCreatedAt(gameData.createdAt)}
                  </div>
                  <div className="title mt-1">{gameData?.courseData?.name}</div>
                </div>
              </div>
              <div className="cardOne border-bottom pb-3">
                <div className="row g-3">
                  <div className="col-lg-3 col-sm-4">
                    <div className="title">{gameData?.courseTee?.name}</div>
                    <div className="value mt-1">Tee</div>
                  </div>
                  <div className="col-xxl-2 col-md-3 col-sm-4">
                    <div className="title">{gameData?.courseTee?.rating}/{gameData?.courseTee?.slope}</div>
                    <div className="value mt-1">Course Rating/Slope</div>
                  </div>
                  <div className="col-xxl-2 col-md-3 col-sm-4">
                  </div>
                </div>
              </div>
              <div className="cardOne">
                <div className="row g-3">
                  <div className="col-lg-3 col-sm-4 col-6">
                    <div className="title">{gameData?.putts}</div>
                    <div className="value mt-1">Total putts</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="gameDetailCard bg-white d-flex flex-column gap-3">
              <div className="gameDetailTable">
                <Table responsive className="m-0">
                  <thead>

                    <tr className="headerFirst">
                      <th colSpan={2}>Hole</th>
                      {scoreData.map((list, index) => (
                        <th>{list.hole !== null ? list.hole : 0}</th>
                      ))}
                    </tr>
                    <tr className="headerSecond">
                      <th colSpan={2}>Par <br /> Handicap</th>
                      {scoreData.map((list, index) => (
                        <th>{list.par !== null ? list.par : 0}<br />{list.handicap !== null ? list.handicap : 0}</th>
                      ))}

                    </tr>
                  </thead>
                  <tbody>
                    {scoreGame?.map((playerData, index) => (
                      <tr key={index}>
                        <td colSpan={2}>
                          <div className="playerProfile">
                            <div className="profileImg">
                              <img
                                className="w-100 h-100"
                                src={
                                  playerData?.userData?.profilePic
                                    ? `https://b2btobacco.s3.amazonaws.com/${playerData?.userData?.profilePic}`
                                    : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                      playerData?.userData?.name
                                    )}&size=256`
                                } />
                            </div>
                            <div className="name">{playerData.userData.name}</div>
                            {/* <div className="dec">CH {playerData.handicap}</div> */}
                          </div>
                        </td>
                        {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21]?.map((item1, idx) => {
                           
                           const item = scoreGame  ? playerData?.data.find(score =>score.scoreRound ==item1)  :undefined
                           console.log(playerData,'lllll',playerData?.data)
                          return(
                          <td>
                            <div className="d-flex flex-column gap-2">

                              <div key={idx} className="scoreType">
                                {item?.scoreValue != null ? item?.scoreValue : '-'}
                              </div>
                            </div>
                          </td>
                        )})
                        }
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>

          </div>
        </div>
      </section>
    </Main>

  );
}
export default GameDetails;