import React, { useState, useEffect, useContext } from 'react';
import { Menu, Button,Spin } from "antd";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate, useLocation } from "react-router-dom";
import PlusIc from "../../assets/images/plus.svg";
import Icon1 from "../../assets/images/ic-1.svg";
import Icon2 from "../../assets/images/ic-2.svg";
import Icon3 from "../../assets/images/ic-3.svg";
import Icon4 from "../../assets/images/ic-4.svg";
import Icon5 from "../../assets/images/ic-5.svg";
import Icon6 from "../../assets/images/ic-6.svg";
import Icon7 from "../../assets/images/freinds-icon.svg";
import Logout from "../../assets/images/log-outIcon.png";
import Dashboard from "../../assets/images/dashboard.svg";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import logoVerticaleTheme from "../../assets/images/logoVerticaleTheme.png";
import fileUploadIcon from "../../assets/images/fileUploadIcon.png";
import logo from "../../assets/images/main-logo.svg";
import { Collapse } from "antd";
import useRequest from "../../hooks/useRequest";
import { AuthContext } from '../../context/AuthContext';
function Sidenav({ color }) {
  const { request } = useRequest();
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    course: "",
    holes: "",
    media: "",
    // Add other fields as needed
  });
  const [courseList, setCourseList] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    error: null
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null
          });
        },
        (error) => {
          setLocation({
            latitude: null,
            longitude: null,
            error: error.message
          });
        }
      );
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        error: 'Geolocation is not supported by this browser.'
      });
    }
  }, []);

  useEffect(() => {
    request({
      url: `/common/getNewcourse?search=${encodeURIComponent(searchQuery)}&latitude=${location.latitude}&longitude=${location.longitude}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        // ShowToast(data.message, Severty.SUCCESS)
        setCourseList(data.data.docs);
      },
      onError: (error) => {
        console.log(error);
        //ShowToast(error, Severty.ERROR);
      },
    });
  }, [searchQuery]);

  const handleLogout = () => {
    setShowLogoutConfirmation(true);
  };

  const confirmLogout = () => {
    setShowLogoutConfirmation(false);
    logout();
  };

  const cancelLogout = () => {
    setShowLogoutConfirmation(false);
  };

  const [newPostShow, newPostModal] = useState(false);
  const newPostModalClose = () => newPostModal(false);
  const newPostModalOpen = () => newPostModal(true);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault()
    formData['holes'] = selectedValue;
    console.log('formdata', formData);

    request({
      url: "/app/post/add-post",
      method: "POST",
      data: formData,
      onSuccess: (data) => {
        newPostModalClose();
        window.location.reload();
        //setCourse(data.data.data);
      },
      onError: (error) => {
        console.log(error);
        // ShowToast(error, Severty.ERROR);
      },
    });
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlechange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  const fileUploads = (event) => {
    setLoading(true)
    console.log("chec k event value ", event)
    console.log("eventttttttttttttttttttttttttttttttttttt", event.target.files[0]
    )
    const file = event.target.files[0];

    const formdata = new FormData()
    formdata.append('image', file)


    console.log('called', formdata);
    request({
      url: "/common/image-upload",
      method: "POST",
      data: formdata,
      onSuccess: (data) => {
        console.log("check data ", data);
        setFormData({ ...formData, media: data.data.upload })
        setLoading(false)
      },
      onError: (error) => {
        console.log(error);
        setLoading(false)
      },
    });
  }
  return (
    <>
      <div className="p-3 p-lg-0">
        <div className="sidenavLogo d-block pb-3 mb-3 border-bottom d-lg-none">
          <a className="d-block" href="/">
            <img className="w-100" src={logo} alt="" />
          </a>
        </div>
        <div className="new-post">
          <Button className="btn btn_primary" onClick={newPostModalOpen}>
            <img src={PlusIc} />
            New Post
          </Button>
        </div>
        <Menu
          theme="light"
          mode="inline"
          className="navBarMenu d-flex flex-column gap-4"
        >
          <Accordion defaultActiveKey="">
            <Accordion.Item eventKey="0" className="border-0">
              <Accordion.Header className="" onClick={() => navigate("/home")}>
                <div className="menu_inner">
                  <img src={Icon1} />
                  <span className="menuCont">Home</span>
                </div>
              </Accordion.Header>
              <Accordion.Body className="p-2 ps-4">
                <Menu
                  theme="light"
                  mode="inline"
                  className="accordionMenu border-start d-flex flex-column gap-2"
                >
                  <Menu.Item
                    key="11"
                    className="navBarItem bg-white"
                    onClick={() => navigate("/post")}
                  >
                    <div className="menu_inner">
                      <span className="menuCont">Posts</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    key="1.2"
                    className="navBarItem bg-white"
                  // onClick={() => navigate("/leader-board")}
                  >
                    <div className="menu_inner">
                      <span className="menuCont">Leader board/Games</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    key="1.3"
                    className="navBarItem bg-white"
                    onClick={() => navigate("/post-score")}
                  >
                    <div className="menu_inner">
                      <span className="menuCont">Post Score</span>
                    </div>
                  </Menu.Item>
                </Menu>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Menu.Item
            key="2"
            className="navBarItem bg-white"
            onClick={() => navigate("/career")}
          >
            <div className="menu_inner">
              <img src={Icon2} />
              <span className="menuCont">Career</span>
            </div>
          </Menu.Item>
          <Menu.Item
            key="3"
            className="navBarItem bg-white"
            onClick={() => navigate("/play")}
          >
            <div className="menu_inner">
              <img src={Icon3} />
              <span className="menuCont">Play</span>
            </div>
          </Menu.Item>
          <Menu.Item
            key="4"
            className="navBarItem bg-white"
            onClick={() => navigate("/courses")}
          >
            <div className="menu_inner">
              <img src={Icon4} />
              <span className="menuCont">Courses</span>
            </div>
          </Menu.Item>
          <Menu.Item
            key="5"
            className="navBarItem bg-white"
            onClick={() => navigate("/chat")}
          >
            <div className="menu_inner">
              <img src={Icon5} />
              <span className="menuCont">Messages</span>
            </div>
          </Menu.Item>
          <Menu.Item
            key="6"
            className="navBarItem bg-white"
            onClick={() => navigate("/handicap")}
          >
            <div className="menu_inner">
              <img src={Icon6} />
              <span className="menuCont">Handicap</span>
            </div>
          </Menu.Item>
          <Menu.Item
            key="7"
            className="navBarItem bg-white"
            onClick={() => navigate("/friends")}
          >
            <div className="menu_inner">
              <img src={Icon7} />
              <span className="menuCont">Friends</span>
            </div>
          </Menu.Item>
          <Menu.Item key="8" className="navBarItem bg-white" onClick={handleLogout}>
            <div className="menu_inner">
              <img src={Logout} />
              <span className="menuCont">Logout</span>
            </div>
          </Menu.Item>
        </Menu>
        <Modal show={showLogoutConfirmation} onHide={cancelLogout}>
          <Modal.Header closeButton>
            <Modal.Title>Logout Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to logout?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelLogout}>
              Cancel
            </Button>
            <Button variant="primary" onClick={confirmLogout}>
              Logout
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Modal
        show={newPostShow}
        size="lg"
        className="headerModals"
        onHide={newPostModalClose}
        centered
      >
        <Modal.Header
          className="p-4 position-relative justify-content-center"
          closeButton
        >
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Form className="row g-2" onSubmit={handleSubmit}>
            <Form.Label className="col-12 mb-0">New Post</Form.Label>
            <div className="col-12 mb-0">
              <div className="row">
                <div className="col-12 col-md-6">
                  <Form.Group
                    controlId="formFile"
                    className="uploadFileOuter w-100 h-100"
                  >
                    <Form.Label className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <div className="uploadFile text-center profile-upload">
      <Spin spinning={loading} size="large">
        <div className="imgOuter">
          {formData?.media && formData.media.endsWith('.mp4') ? (
            <video src={`https://b2btobacco.s3.amazonaws.com/${formData.media}`} autoPlay="true" type="video/mp4" />
          ) : (
            <img src={formData?.media ? `https://b2btobacco.s3.amazonaws.com/${formData?.media}` : fileUploadIcon} />
          )}
        </div>
        <div className="txt mt-3">Upload Img or video</div>
      </Spin>
    </div>
                    </Form.Label>
                    <Form.Control className="d-none" type="file" name="media" onChange={fileUploads} />
                  </Form.Group>
                </div>
                <div className="col-12 mt-md-0 mt-3 col-md-6">
                  <div className="row gap-12">
                    <Form.Group className="col-12">
                      <Form.Control
                        className="sahdow-none"
                        type="text"
                        name="title"
                        onReset={Form}
                        required="title"
                        placeholder="Title"
                        onChange={handlechange}
                      />
                    </Form.Group>
                    <Form.Group className="col-12 position-relative">
                      <Form.Control
                        as="textarea"
                        className="sahdow-none"
                        type="text"
                        onReset={Form}
                        name="description"
                        placeholder="Description"
                        onChange={handlechange}
                        rows={3}
                      />
                    </Form.Group>
                    <Form.Group controlId="formCourse">
                      <Form.Label>Search and Select Course</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search for a course..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <Form.Select
                        as="select"
                        name="course"
                        // required="course"
                        onReset={Form}
                        value={formData.course}
                        onChange={handlechange}
                        style={{ marginTop: '10px' }}
                      >
                        <option value="">Select Course</option>
                        {courseList.map((course) => (
                          <option key={course.id} value={course.id}>
                            {course.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>



                    <Form.Group className="col-12">
                      <Form.Select
                        aria-label="Default select example"
                        name="holes"
                        onChange={(e) => {
                          setSelectedValue(e.target.value)

                        }}

                      >
                        <option>Select Hole</option>
                        <option value="9">Hole 9</option>
                        <option value="18">Hole 18</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="col-12">
                      <Form.Check
                        className="switchCheck shadow-none p-0 d-flex gap-2 justify-content-between"
                        type="switch"
                        id="TheGrintTour"
                        label={
                          <>
                            <div className="title fw-medium text-dark fs-6">
                              TheGrint Tour
                            </div>
                            <div className="subTitle">
                              Messages send by us through your journey
                            </div>
                          </>
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-12 mt-4 text-end">
                      <button
                        type="submit"
                        className="btn btnStyle1 btnTheme1 shadow-none px-5 text-center w-100"
                      >
                        Post
                      </button>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Sidenav;
