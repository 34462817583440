import React from "react";
import Main from "../components/layout/Main";
import { Input } from "antd";
import backArrow from "../assets/images/backArrow.png";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

function Terms() {
  const navigate = useNavigate();

  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3">
          <div className="col-lg-12">
            <div className="pagreHeader">
              <div className="pageHeadign d-flex align-items-center gap-3 mb-3">
                <button
                  onClick={() => navigate("/sign-up")}
                  className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex"
                >
                  <img className="h-100" src={backArrow} alt="Back Arrow" />
                </button>
                <div className="txt">Title: "Fairway Etiquette: Navigating the Greens of Golf Therapy with Golfapathy Inc."

                  Welcome to Golfapathy Inc., where we believe in providing a unique therapy experience on the virtual greens. Before you tee off on this therapeutic journey, let's explore the terms and conditions that govern your swing through our online platform.

                  1. The Golfapathy Experience

                  As you step onto our digital fairways, you enter into an agreement with Golfapathy Inc. This agreement encompasses your access and use of our online therapy platform. We're headquartered at 215 Fairway Lane, Apt 1, Golfsville, Massachusetts, United States. The platform may be accessed via our website (golfapathy.org) and related apps.

                  2. Your Golf Therapists and Swing Services

                  Our therapists are the skilled caddies on this journey. They're independent providers, not our employees, agents, or representatives. The platform's role is to enable their swing services, and they are responsible for the performance of your therapeutic experience. If your swing with a therapist doesn't suit your needs, fear not! You have the liberty to switch to another therapist available through the platform.

                  We ensure that every therapist providing services on our platform is an accredited, trained, or experienced licensed psychologist (PhD/Master’s) or holds a recognized professional certification. Qualifications matter, and our therapists are equipped with relevant academic degrees and certifications.

                  3. In Case of Hazards: Emergency Situations

                  While we aim for a flexible environment, emergencies can happen. If a therapist leaves Golfapathy after your connection or cancels appointments, we'll notify you. However, if you're in a mental hazard zone – thinking about harm, emergencies, or medical issues – our platform isn't the fairway for you. Call your local emergency services immediately.

                  4. Privacy: A Hole-in-One

                  Your privacy is paramount. We take extensive measures to protect any information you provide through the platform. Our Privacy Policy, part of this agreement, outlines our security practices. By using the platform, you agree to the terms of the Privacy Policy.

                  5. Third-Party Sand Traps

                  The platform may contain links or content from third parties. We don't craft or control third-party content, and we're not liable for any damage caused by it.

                  6. Swing Warranty and Liability Limits

                  We're here to enhance your mental swing, but we can't guarantee perfection. The platform comes "as is" without warranties. We're not liable for indirect, incidental, or consequential damages.

                  7. Your Golf Subscription

                  Our subscriptions offer various plans for international expats seeking short or long-term mental wellness. We reserve the right to adjust services and prices. You can cancel your subscription at any time.

                  8. Swing Responsibly: Your Account and Conduct

                  Your account is your golfing identity. Keep it secure. Don't let anyone else swing your club. Use the platform responsibly; don't disrupt the virtual greens. If you receive a mental file, check for "virus thoughts" before using it.

                  9. Weathering the Storm: Modifications and Interruptions

                  We might tweak the platform, suspend it briefly, or introduce disruptions. We're not liable for losses during such events. Virtual platforms, like real greens, aren't 100% predictable.

                  10. Sanctions: Golfapathy Regulations

                  Using Golfapathy products comes with responsibilities. Abide by export and re-export control laws. Don't breach any laws related to product use.

                  11. Notices on the Green

                  We'll communicate changes through emails or postings. Check your inbox frequently.

                  Conclusion: Swinging towards Mental Wellness

                  This agreement is your caddy on the mental wellness course. We interpret it under Massachusetts laws. It's the whole agreement between you and us. Keep an eye out for updates, and remember, every swing towards better mental health is a hole-in-one.

                  GOLFAPATHY INC. Team.</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>
  );
}

export default Terms;
