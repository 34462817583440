import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import ScoreCard from "../assets/images/score-card.svg";
import avtarImg from "../assets/images/avtarImg.png";
import trophyIcon from "../assets/images/trophyIcon.png";
import backArrow from "../assets/images/backArrow.png";
import closeIconDark from "../assets/images/closeIconDark.png";
import tabIcon1 from "../assets/images/tabIcon1.png";
import noteIcon from "../assets/images/noteIcon.png";
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';  
import logoVerticaleTheme from "../assets/images/logoVerticaleTheme.png";
import scoreCardModalImg from "../assets/images/scoreCardModalImg.png";
import golfIcon from "../assets/images/golfIcon.png";
import shareIcon from "../assets/images/shareIcon.png";
import gameImg from "../assets/images/gameImg.png";
import { useNavigate, useLocation } from "react-router-dom";

function CurrentLeaderboard() {
  
  const navigate = useNavigate();
  
  const [show, scoreCardStepModal] = useState(false);
  const scoreCardStepModalClose = () => scoreCardStepModal(false);
  const scoreCardStepModalOpen = () => scoreCardStepModal(true);
  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3">
          <div className="col-lg-12">
            <div className="pagreHeader">
              <div className="pageHeadign d-flex align-items-center gap-3 mb-3">
                <button onClick={()=>navigate("/play")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                  <img className="h-100" src={backArrow}/>        
                </button> 
                <div className="txt">Leaderboard</div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row g-3">
              <div className="col-xxl-4 col-md-6">
                <a role="button" onClick={scoreCardStepModalOpen} className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-3">
                  <div className="organizerProfile">
                    <div className="organizerDetail d-flex align-items-center gap-2">
                      <div className="imgOuter rounded-circle overflow-hidden">
                        <img className="w-100 h-100" src={avtarImg}/>
                      </div>
                      <div className="detail w-100">
                        <div className="name d-flex align-items-center justify-content-between">The Belfry Cup <div className="date">12/29/23</div></div>
                        <div className="dec mt-1">The Belfry Golf Club l Brabazon</div>
                      </div>
                    </div>
                  </div>
                  <div className="leaderProfile">
                    <div className="row g-2">
                      <div className="col-6 text-start">
                        <div className="value">Individual Competition</div>
                        <div className="title">Type</div>
                      </div>
                      <div className="col-6 text-end">
                        <div className="value">12</div>
                        <div className="title">Joined</div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xxl-4 col-md-6">
                <a role="button" onClick={scoreCardStepModalOpen} className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-3">
                  <div className="organizerProfile">
                    <div className="organizerDetail d-flex align-items-center gap-2">
                      <div className="imgOuter rounded-circle overflow-hidden">
                        <img className="w-100 h-100" src={avtarImg}/>
                      </div>
                      <div className="detail w-100">
                        <div className="name d-flex align-items-center justify-content-between">The Belfry Cup <div className="date">12/29/23</div></div>
                        <div className="dec mt-1">The Belfry Golf Club l Brabazon</div>
                      </div>
                    </div>
                  </div>
                  <div className="leaderProfile">
                    <div className="row g-2">
                      <div className="col-6 text-start">
                        <div className="value">Individual Competition</div>
                        <div className="title">Type</div>
                      </div>
                      <div className="col-6 text-end">
                        <div className="value">12</div>
                        <div className="title">Joined</div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xxl-4 col-md-6">
                <a role="button" onClick={scoreCardStepModalOpen} className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-3">
                  <div className="organizerProfile">
                    <div className="organizerDetail d-flex align-items-center gap-2">
                      <div className="imgOuter rounded-circle overflow-hidden">
                        <img className="w-100 h-100" src={avtarImg}/>
                      </div>
                      <div className="detail w-100">
                        <div className="name d-flex align-items-center justify-content-between">The Belfry Cup <div className="date">12/29/23</div></div>
                        <div className="dec mt-1">The Belfry Golf Club l Brabazon</div>
                      </div>
                    </div>
                  </div>
                  <div className="leaderProfile">
                    <div className="row g-2">
                      <div className="col-6 text-start">
                        <div className="value">Individual Competition</div>
                        <div className="title">Type</div>
                      </div>
                      <div className="col-6 text-end">
                        <div className="value">12</div>
                        <div className="title">Joined</div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xxl-4 col-md-6">
                <a role="button" onClick={scoreCardStepModalOpen} className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-3">
                  <div className="organizerProfile">
                    <div className="organizerDetail d-flex align-items-center gap-2">
                      <div className="imgOuter rounded-circle overflow-hidden">
                        <img className="w-100 h-100" src={avtarImg}/>
                      </div>
                      <div className="detail w-100">
                        <div className="name d-flex align-items-center justify-content-between">The Belfry Cup <div className="date">12/29/23</div></div>
                        <div className="dec mt-1">The Belfry Golf Club l Brabazon</div>
                      </div>
                    </div>
                  </div>
                  <div className="leaderProfile">
                    <div className="row g-2">
                      <div className="col-6 text-start">
                        <div className="value">Individual Competition</div>
                        <div className="title">Type</div>
                      </div>
                      <div className="col-6 text-end">
                        <div className="value">12</div>
                        <div className="title">Joined</div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xxl-4 col-md-6">
                <a role="button" onClick={scoreCardStepModalOpen} className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-3">
                  <div className="organizerProfile">
                    <div className="organizerDetail d-flex align-items-center gap-2">
                      <div className="imgOuter rounded-circle overflow-hidden">
                        <img className="w-100 h-100" src={avtarImg}/>
                      </div>
                      <div className="detail w-100">
                        <div className="name d-flex align-items-center justify-content-between">The Belfry Cup <div className="date">12/29/23</div></div>
                        <div className="dec mt-1">The Belfry Golf Club l Brabazon</div>
                      </div>
                    </div>
                  </div>
                  <div className="leaderProfile">
                    <div className="row g-2">
                      <div className="col-6 text-start">
                        <div className="value">Individual Competition</div>
                        <div className="title">Type</div>
                      </div>
                      <div className="col-6 text-end">
                        <div className="value">12</div>
                        <div className="title">Joined</div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xxl-4 col-md-6">
                <a role="button" onClick={scoreCardStepModalOpen} className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-3">
                  <div className="organizerProfile">
                    <div className="organizerDetail d-flex align-items-center gap-2">
                      <div className="imgOuter rounded-circle overflow-hidden">
                        <img className="w-100 h-100" src={avtarImg}/>
                      </div>
                      <div className="detail w-100">
                        <div className="name d-flex align-items-center justify-content-between">The Belfry Cup <div className="date">12/29/23</div></div>
                        <div className="dec mt-1">The Belfry Golf Club l Brabazon</div>
                      </div>
                    </div>
                  </div>
                  <div className="leaderProfile">
                    <div className="row g-2">
                      <div className="col-6 text-start">
                        <div className="value">Individual Competition</div>
                        <div className="title">Type</div>
                      </div>
                      <div className="col-6 text-end">
                        <div className="value">12</div>
                        <div className="title">Joined</div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    {/* Score card Modal  */}
    <Modal show={show} size="lg" className="headerModals" onHide={scoreCardStepModalClose} centered>
      <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
        <Modal.Title>
          <img src={logoVerticaleTheme}/>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-sm-4 p-3">
        <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-12 p-0">
          <div className="organizerProfile d-flex align-items-center justify-content-between">
            <div className="organizerDetail d-flex align-items-center gap-2">
              <div className="imgOuter rounded-circle overflow-hidden">
                <img className="w-100 h-100" src={avtarImg}/>
              </div>
              <div className="detail">
                <div className="name">John Doe</div>
                <div className="dec mt-1">Organizer</div>
              </div>
            </div>
            <div className="leaderProfile text-end">
              <div className="value">0</div>
              <div className="title">Golfers Joined</div>
            </div>
          </div>
          <div className="leaderProfile">
            <div className="row g-2">
              <div className="col-sm-6 text-start">
                <div className="value">Dummy Name</div>
                <div className="title">Leaderboard Name</div>
              </div>
              <div className="col-sm-6 text-sm-end">
                <div className="value">Individual Competition</div>
                <div className="title">Competition Type</div>
              </div>
            </div>
          </div>
          <hr className="border-top border-secondary m-0"/>
          <div className="gameDetail d-flex align-items-center gap-2">
            <div className="imgOuter">
              <img className="w-100 h-100" src={gameImg}/>
            </div>
            <div className="detail">
              <div className="gameTitle">Makena Golf & Beach Club l Makena Maka</div>
              <div className="gameScore d-flex align-items-center gap-1 mt-1">Blue 34.80 / 122</div>
              <div className="competitionSize">08/23/23</div>
            </div>
          </div>
          <div className="leaderProfile">
            <div className="row g-2">
              <div className="col-sm-6 text-start">
                <div className="value">42</div>
                <div className="title">Round Score</div>
              </div>
              <div className="col-sm-6 text-sm-end">
                <div className="value">5.5</div>
                <div className="title">Handicap</div>
              </div>
            </div>
          </div>
          <Form className="">
            <div className="row g-2">
              <Form.Group className="col-sm-12">
                <Form.Control className="sahdow-none" type="text" placeholder="Enter leader board password" />
              </Form.Group>
            </div>
            <div className="btnGroup d-flex flex-column flex-sm-row align-items-center gap-3 mt-4">
              <button type="button" className="btn btnStyle1 btnOutlineTheme1 btnWidth shadow-none px-3 w-100 d-flex align-items-center gap-1 justify-content-center">Observe Only</button>
              <button type="button" className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-3 w-100">Join</button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
    </Main>

  );
}
export default CurrentLeaderboard;