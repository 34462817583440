import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select, Tabs, Spin } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import Heart from "../assets/images/heart.png";
import ScoreCard from "../assets/images/score-card.svg";
import avtarImg from "../assets/images/avtarImg.png";
import useRequest from "../hooks/useRequest";
import { useNavigate } from "react-router-dom";
import trophyIcon from "../assets/images/trophyIcon.png";
import warningImage from "../assets/images/warningImgae.jpg"
import { Link } from "react-router-dom";
const { TabPane } = Tabs;
function Posts() {
  const [liked, setLiked] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1"); // State to manage active tab
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const [posts, setPosts] = useState([])
  const { request } = useRequest();
  const [loading, setLoading] = useState(true)
  const [selecttab, setselectedTab] = useState("1");
  const [handicap, sethandicap] = useState({})
  const [avgScore, setAvgcap] = useState({})
  const [setLike, setselectedLike] = useState()
  const [page, setPage] = useState(1);
  const [comment, setComment] = useState()
  const [addScore, setScore] = useState([])
  const [profile, setProfile] = useState({})


  const fetchPost = () => {
    console.log("hello its for just check")
    let type = selecttab === "1" ? "Friend" : selecttab === "2" ? "Public" : "User";
    request({
      url: `/app/post/get-post/${type}?page=${page}&limit=20`,
      method: "GET",
      onSuccess: (data) => {
        console.log("check post in post", data);
        setPosts(data.data.data)
        setLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

      }
    })
  }
  useEffect(() => {
    addDataPost()
    setUserProfile();
    setHandicapProfile();
    setAvg();
    fetchPost()
  }, [selecttab, page])

  const addDataPost = (id) => {
    request({
      url: `app/game/gameBestScore`,
      method: "GET",

      onSuccess: (data) => {
        setScore(data.data.data)
        console.log("check post in post", data);
        // setPosts(data.data.data)
      },
      onError: (error) => {
        console.log(error);

      }
    })
  }

  const setHandicapProfile = () => {
    request({
      url: "app/game/getHandicapScore",
      method: "GET",
      onSuccess: (data) => {
        console.log("Handica[]:", data.data.name);
        sethandicap(data?.data); // Assuming the API response contains the user profile data
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };
  const setAvg = () => {
    request({
      url: "app/game/averageTotalScore",
      method: "GET",
      onSuccess: (data) => {
        console.log("Handica[]:", data.data.totalAverage);
        setAvgcap(data.data); // Assuming the API response contains the user profile data
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };
  const setUserProfile = () => {
    request({
      url: "app/user/get-profile",
      method: "GET",
      onSuccess: (data) => {
        console.log("Profile data:", data.data.name);
        setProfile(data.data); // Assuming the API response contains the user profile data
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };

  const addPost = (id) => {
    request({
      url: `app/post/post-Comment/${id}`,
      method: "PUT",
      data: `${comment}`,
      onSuccess: (data) => {
        console.log("check post in post", data);
        // setPosts(data.data.data)
      },
      onError: (error) => {
        console.log(error);

      }
    })
  }

  const handleLikeClick = async (postId) => {
    try {
      request({
        url: `/app/post/like-post/${postId}`,
        method: "GET",
        onSuccess: (data) => {
          setPosts(prevPosts =>
            prevPosts.map(post =>
              post._id === postId ? { ...post, liked: true } : post
            )
          );
          fetchPost()
        },
        onError: (error) => {
          console.log(error);
        }
      })
      // Assuming the like API call is successful, update the state to reflect the like
    } catch (error) {
      console.error("Error liking post:", error);
    }
  };
  const handleTabs = (key) => {
    console.log('key', key);
    setselectedTab(key)
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(event.target, 67)
    setComment(event.target.value)
  }
  function formatDate(dateString) {
    if (!dateString) return ""; // handle cases where dateString is undefined or null

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${month}-${day}-${year}`;
  }
  const handleNextPage = () => {
    setPage(page + 1); // Increment page number
  };
  const handlePrevPage = () => {
    setPage(page - 1); // Increment page number
  };


  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 container-fluid">
        <div className="row">
          <div className="col-lg-8">

            <section className=" p-0 pt-2 container-fluid">

              <Tabs className="tabin" onChange={handleTabs}>
                {/* Define your tabs here */}
                <TabPane tab="FRIENDS POST" key="1" className="frndtab">
                  {/* Content for Tab 1 */}
                  {/* Check if there are posts available */}
                  {loading ? (
                    <div style={{ textAlign: 'center' }}>
                      <Spin size="large" tip="Loading..." style={{ color: '#04070c' }} />
                    </div>
                  ) : (
                    posts.length === 0 ? (
                      // If there are no posts available, render this content
                      <div className="no-posts-available">
                        <div className="image-container">
                          <img src={warningImage} alt="No posts image" />
                        </div>
                      </div>
                    ) : (
                      // If there are posts available, render each post
                      posts.map((post, index) => (
                        <div className="single-post" key={index}>
                          <div className="post-header">
                            <div className="post-left">
                              <div className="post-user">
                                <div className="post-img">
                                  <img className="w-100 h-100" src={
                                    post?.user[0].profilePic
                                      ? `https://b2btobacco.s3.amazonaws.com/${post?.user[0].profilePic}`
                                      : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                        post?.user[0].name
                                      )}&size=256`
                                  } alt={post?.user[0].name} />
                                </div>
                                <div className="post-user-cont">
                                  <h4>{post?.user[0]?.name}</h4>
                                  {/* <p>{post.description}</p> */}
                                </div>
                              </div>
                            </div>
                            <div className="post-right">
                              <div className="post_date">
                                <p>{formatDate(post?.created_at)}</p>
                              </div>
                            </div>
                          </div>
                          {post?.media && (
                            <div className="post-main-img">
                              {post.media.endsWith('.mp4') ? (
                                <video controls>
                                  <source src={`https://b2btobacco.s3.amazonaws.com/${post.media}`} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <img
                                  src={`https://b2btobacco.s3.amazonaws.com/${post.media}`}
                                  alt="Post"
                                />
                              )}
                            </div>
                          )}
                          <div className="post-mian-cont">
                            <div className="post-tit">
                              <h3>{post?.course}</h3>
                              <p>{post?.description}</p>
                            </div>
                            <div className="single-post-dtl">
                              {post.score && (
                                <div className="score-post">
                                  <div className="post-info-ic">
                                    {post.score !== null ? post.score : 0}
                                  </div>
                                  <span>Score</span>
                                </div>
                              )}
                              {post.putts !== 0 && post.putts && (
                                <div className="score-post">
                                  <div className="post-info-ic">
                                    {post.putts}
                                  </div>
                                  <span>Putts</span>
                                </div>
                              )}
                              {post.penalties !== 0 && post.penalties && (
                                <div className="score-post">
                                  <div className="post-info-ic">
                                    {post.penalties}
                                  </div>
                                  <span>Penalties</span>
                                </div>
                              )}
                            </div>
                            <div className="post-option">
                              <ul>
                                <li>
                                  {/* Use the Link component to navigate to the comment page */}
                                  <Link to={`/comment/${post._id}`} className="btm-like">
                                    <span className="post-icon">
                                      <img src={Comment} alt="Comment Icon" />
                                    </span>
                                    <span>Comment</span>
                                  </Link>
                                </li>
                                <li onClick={() => handleLikeClick(post._id)}>
                                  <a className="btm-like">
                                    <span className="post-icon">
                                      {post.isLike ? (
                                        <img id="heart01" src={Heart} style={{ display: 'block' }} />
                                      ) : (
                                        <img id="heart02" src={Like} style={{ display: 'block' }} />
                                      )}
                                    </span>
                                    <span> Like</span>
                                  </a>
                                </li>
                                <li>
                                  <a className="btm-like" onClick={() => {
                                    if (post?.gameId[0]?._id) {
                                      navigate(`/allScoreData/${post?.gameId[0]?._id}`);
                                    } else {
                                      // Handle the case when the score card is not available
                                      alert("Score card not available");
                                    }
                                  }}>
                                    <span className="post-icon">
                                      <img src={ScoreCard} />
                                    </span>
                                    <span>Scorecard</span>
                                  </a>
                                </li>
                              </ul>
                              {/* <div className="msg-wrap">
                            <div className="msg-field">
                              <Input
                                type="text"
                                name="comment"
                                placeholder="Add a comment..."
                                onChange={handleChange}
                              />
                            </div>
                            <div className="msg-btn">
                              <Button
                                type="submit"
                                className="btn btn_primary btn-inn" onClick={() => addPost(post._id)}>Post</Button>
                            </div>
                          </div> */}
                            </div>
                            {/* Additional post details and options */}
                          </div>

                        </div>

                      ))
                    )
                  )}
                </TabPane>
                <TabPane tab="PUBLIC POST" key="2" className="frndtab">
                  {/* Content for Tab 2 */}
                  {posts.length === 0 ? (
                    // If there are no posts available, render this content
                    <div className="no-posts-available">
                      {/* <h3>No posts available</h3> */}
                      <div className="image-container">
                        {/* Add your image tag here */}
                        <img src={warningImage} alt="No posts image" />
                      </div>
                    </div>
                  ) : (
                    // If there are posts available, render each post
                    posts.map((post, index) => (
                      <div className="single-post" key={index}>
                        <div className="post-header">
                          <div className="post-left">
                            <div className="post-user">
                              <div className="post-img">
                                <img className="w-100 h-100" src={
                                  post?.user[0].profilePic
                                    ? `https://b2btobacco.s3.amazonaws.com/${post?.user[0].profilePic}`
                                    : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                      post?.user[0].name
                                    )}&size=256`
                                } alt={post?.user[0].name} />
                              </div>
                              <div className="post-user-cont">
                                <h4>{post?.user[0]?.name}</h4>
                                {/* <p>{post.description}</p> */}
                              </div>
                            </div>
                          </div>
                          <div className="post-right">
                            <div className="post_date">
                              <p>{formatDate(post?.created_at)}</p>
                            </div>
                          </div>
                        </div>
                        {post?.media && (
                          <div className="post-main-img">
                            {post.media.endsWith('.mp4') ? (
                              <video controls>
                                <source src={`https://b2btobacco.s3.amazonaws.com/${post.media}`} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <img
                                src={`https://b2btobacco.s3.amazonaws.com/${post.media}`}
                                alt="Post"
                              />
                            )}
                          </div>
                        )}
                        <div className="post-mian-cont">
                          <div className="post-tit">
                            <h3>{post?.course}</h3>
                            <p>{post?.description}</p>
                          </div>
                          <div className="single-post-dtl">
                            {post.score && (
                              <div className="score-post">
                                <div className="post-info-ic">
                                  {post.score !== null ? post.score : 0}
                                </div>
                                <span>Score</span>
                              </div>
                            )}
                            {post.putts !== 0 && post.putts && (
                              <div className="score-post">
                                <div className="post-info-ic">
                                  {post.putts}
                                </div>
                                <span>Putts</span>
                              </div>
                            )}
                            {post.penalties !== 0 && post.penalties && (
                              <div className="score-post">
                                <div className="post-info-ic">
                                  {post.penalties}
                                </div>
                                <span>Penalties</span>
                              </div>
                            )}
                          </div>
                          <div className="post-option">
                            <ul>
                              <li>
                                {/* Use the Link component to navigate to the comment page */}
                                <Link to={`/comment/${post._id}`} className="btm-like">
                                  <span className="post-icon">
                                    <img src={Comment} alt="Comment Icon" />
                                  </span>
                                  <span>Comment</span>
                                </Link>
                              </li>
                              <li onClick={() => handleLikeClick(post._id)}>
                                <a className="btm-like">
                                  <span className="post-icon">
                                    {post.isLike ? (
                                      <img id="heart01" src={Heart} style={{ display: 'block' }} />
                                    ) : (
                                      <img id="heart02" src={Like} style={{ display: 'block' }} />
                                    )}
                                  </span>
                                  <span> Like</span>
                                </a>
                              </li>
                              <li>
                                <a className="btm-like" onClick={() => {
                                  if (post?.gameId[0]?._id) {
                                    navigate(`/allScoreData/${post?.gameId[0]?._id}`);
                                  } else {
                                    // Handle the case when the score card is not available
                                    alert("Score card not available");
                                  }
                                }}>
                                  <span className="post-icon">
                                    <img src={ScoreCard} />
                                  </span>
                                  <span>Scorecard</span>
                                </a>
                              </li>
                            </ul>
                            {/* <div className="msg-wrap">
                            <div className="msg-field">
                              <Input
                                type="text"
                                name="comment"
                                placeholder="Add a comment..."
                                onChange={handleChange}
                              />
                            </div>
                            <div className="msg-btn">
                              <Button
                                type="submit"
                                className="btn btn_primary btn-inn" onClick={() => addPost(post._id)}>Post</Button>
                            </div>
                          </div> */}
                          </div>
                          {/* Additional post details and options */}
                        </div>

                      </div>

                    ))
                  )}
                </TabPane>
                <TabPane tab="SELF POST" key="3" className="frndtab">
                  {/* Content for Tab 3 */}
                  {posts.length === 0 ? (
                    // If there are no posts available, render this content
                    <div className="no-posts-available">
                      <div className="image-container">
                        <img src={warningImage} alt="No posts image" />
                      </div>
                    </div>
                  ) : (
                    // If there are posts available, render each post
                    posts.map((post, index) => (
                      <div className="single-post" key={index}>
                        <div className="post-header">
                          <div className="post-left">
                            <div className="post-user">
                              <div className="post-img">
                                <img
                                  className="w-100 h-100"
                                  src={
                                    post?.user[0].profilePic
                                      ? `https://b2btobacco.s3.amazonaws.com/${post?.user[0].profilePic}`
                                      : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                        post?.user[0].name
                                      )}&size=256`
                                  }
                                  alt={post?.user[0].name}
                                />
                              </div>
                              <div className="post-user-cont">
                                <h4>{post?.user[0]?.name}</h4>
                              </div>
                            </div>
                          </div>
                          <div className="post-right">
                            <div className="post_date">
                              <p>{formatDate(post?.created_at)}</p>
                            </div>
                          </div>
                        </div>
                        {post?.media && (
                          <div className="post-main-img">
                            {post.media.endsWith('.mp4') ? (
                              <video controls>
                                <source src={`https://b2btobacco.s3.amazonaws.com/${post.media}`} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <img src={`https://b2btobacco.s3.amazonaws.com/${post.media}`} alt="Post" />
                            )}
                          </div>
                        )}
                        <div className="post-mian-cont">
                          <div className="post-tit">
                            <h3>{post?.course}</h3>
                            <p>{post?.description}</p>
                          </div>
                          <div className="single-post-dtl">
                            {post.score && (
                              <div className="score-post">
                                <div className="post-info-ic">
                                  {post.score !== null ? post.score : 0}
                                </div>
                                <span>Score</span>
                              </div>
                            )}
                            {post.putts !== 0 && post.putts && (
                              <div className="score-post">
                                <div className="post-info-ic">
                                  {post.putts}
                                </div>
                                <span>Putts</span>
                              </div>
                            )}
                            {post.penalties !== 0 && post.penalties && (
                              <div className="score-post">
                                <div className="post-info-ic">
                                  {post.penalties}
                                </div>
                                <span>Penalties</span>
                              </div>
                            )}
                          </div>
                          <div className="post-option">
                            <ul>
                              <li>
                                {/* Use the Link component to navigate to the comment page */}
                                <Link to={`/comment/${post._id}`} className="btm-like">
                                  <span className="post-icon">
                                    <img src={Comment} alt="Comment Icon" />
                                  </span>
                                  <span>Comment</span>
                                </Link>
                              </li>
                              <li onClick={() => handleLikeClick(post._id)}>
                                <a className="btm-like">
                                  <span className="post-icon">
                                    {post.isLike ? (
                                      <img id="heart01" src={Heart} style={{ display: 'block' }} />
                                    ) : (
                                      <img id="heart02" src={Like} style={{ display: 'block' }} />
                                    )}
                                  </span>
                                  <span> Like</span>
                                </a>
                              </li>
                              <li>
                                <a className="btm-like" onClick={() => {
                                  if (post?.gameId[0]?._id) {
                                    navigate(`/allScoreData/${post?.gameId[0]?._id}`);
                                  } else {
                                    // Handle the case when the score card is not available
                                    alert("Score card not available");
                                  }
                                }}>
                                  <span className="post-icon">
                                    <img src={ScoreCard} />
                                  </span>
                                  <span>Scorecard</span>
                                </a>
                              </li>
                            </ul>
                            {/* <div className="msg-wrap">
                            <div className="msg-field">
                              <Input
                                type="text"
                                name="comment"
                                placeholder="Add a comment..."
                                onChange={handleChange}
                              />
                            </div>
                            <div className="msg-btn">
                              <Button
                                type="submit"
                                className="btn btn_primary btn-inn" onClick={() => addPost(post._id)}>Post</Button>
                            </div>
                          </div> */}
                          </div>
                          {/* Additional post details and options */}
                        </div>
                      </div>
                    ))
                  )}
                </TabPane>
                {/* Add more TabPane components for additional tabs */}
              </Tabs>
              <div className="pagination-buttons">
                <Button onClick={handlePrevPage}>Prev</Button>
                <Button onClick={handleNextPage}>Next</Button>
              </div>

              {/* Your existing content goes here */}
            </section>
          </div>
          <div className="col-lg-4">
            <div className="sticky-post bg-white rounded-3">
              <div className="stickyPostHeader d-flex align-items-center justify-content-between w-100">
                <div className="stickyPostHeaderProfile d-flex align-items-center gap-2">
                  <div className="stickyPostHeaderProfileImg rounded-circle overflow-hidden">
                    <img
                      className="w-100 h-100"
                      src={
                        profile?.profilePic
                          ? `https://b2btobacco.s3.amazonaws.com/${profile?.profilePic}`
                          : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                            profile?.name
                          )}&size=256`
                      } />
                  </div>
                  <div className="stickyPostHeaderProfileDetail">
                    <div className="name">{profile.name}</div>
                    <div className="dec">{profile.email}</div>
                  </div>
                </div>
                {/* <div className="rank d-flex align-items-center justify-content-center rounded-circle">1<sup>st</sup></div> */}
              </div>
              <div className="stickyPostGamesScoring row row-cols-xxs-2 row-cols-xs-3 row-cols-4 row-cols-sm-6 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4 row-cols-xxxl-5 align-items-center g-3 pb-3 border-bottom w-100 justify-content-start">
                <div className="col scoringItems text-center">
                  <div className="scor d-flex align-items-center justify-content-center rounded-circle text-white mx-auto">
                    {handicap?.totalAverage?.toString()?.substring(0, 5) || '0.0'}
                  </div>
                  <div className="title mt-1 ">Handicap</div>
                </div>
                <div className="col scoringItems text-center">
                  <div className="scor d-flex align-items-center justify-content-center rounded-circle text-white mx-auto">{avgScore?.totalAverage?.toString()?.substring(0, 5) || '0.0'}</div>
                  <div className="title mt-1 ">Avg Score</div>
                </div>
                <div className="col scoringItems text-center">
                  <div className="scor d-flex align-items-center justify-content-center rounded-circle text-white mx-auto">
                    {(avgScore?.averagePuttScore || 0).toFixed(2)}

                  </div>
                  <div className="title mt-1 ">Avg Putts</div>
                </div>
                {/* <div className="col scoringItems text-center">
                  <div className="scor d-flex align-items-center justify-content-center rounded-circle text-white mx-auto">86</div>
                  <div className="title mt-1 text-uppercase">GIR%</div>
                </div> */}
              </div>
              <div className="stickyPostTrophy d-flex flex-column gap-3 w-100">
                <div className="stickyPostTrophyHeader d-flex align-items-center justify-content-between w-100">
                  <div className="title">Trophy</div>
                  {/* <a href="javascript:;" className="view-all-btn textBtn textBtnPrimary p-0 text-decoration-underline">View All</a> */}
                </div>
                <div className="stickyPostTrophyOuter row g-2 row-cols-xxs-2 row-cols-xs-3 row-cols-4 row-cols-sm-6 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4 row-cols-xxxl-5 align-items-center">
                  <div className="col">
                    <div className="stickyPostTrophyItem text-center">
                      <div className="imgOuter mx-auto position-relative">
                        <img className="w-100" src={trophyIcon} />
                        <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">{addScore[1] ? addScore[1] : 0}</div>
                      </div>
                      <div className="title">Best Score</div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="stickyPostTrophyItem text-center">
                      <div className="imgOuter mx-auto position-relative">
                        <img className="w-100" src={trophyIcon} />
                        <div className="scor text-white position-absolute start-0 end-0 mx-auto z-1">{addScore[0] ? addScore[0] : 0}</div>
                      </div>
                      <div className="title">Best Putts</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>
  );
}
export default Posts;
