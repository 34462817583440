import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import avtarImg from "../assets/images/avtarImg.png";
import backArrow from "../assets/images/backArrow.png";
import searchIcon from "../assets/images/searchIcon.png";
import msgSend from "../assets/images/msgSend.png";
import { useNavigate, useLocation } from "react-router-dom";
import Form from 'react-bootstrap/Form';

function Chat() {
  
  const navigate = useNavigate();
  
  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3">
          <div className="col-lg-12">
            <div className="pagreHeader">
              <div className="pageHeadign d-flex align-items-center gap-3">
                <button onClick={()=>navigate("/career-friends-ranking")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                  <img className="h-100" src={backArrow}/>        
                </button> 
                <div className="txt">Chat</div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="chatMainOuter position-relative">
              <div className="row h-100">
                <div className="col-xxl-4 col-md-5 h-100">
                  <div className="contactListOuter bg-white rounded-3 overflow-hidden h-100 d-flex flex-column flex-nowrap">
                    <div className="contactListHeader p-3">
                      <Form className="">
                        <Form.Group className="position-relative">
                          <Form.Label htmlFor="contactSearch" style={{cursor:"pointer"}} className="position-absolute top-0 bottom-0 my-auto start-0 ms-3 d-flex align-items-center justify-content-center">
                            <img src={searchIcon}/>
                          </Form.Label>
                          <Form.Control className="ps-5"
                            type="search"
                            id="contactSearch"
                            aria-describedby="passwordHelpBlock"
                          />
                        </Form.Group>
                      </Form>
                    </div>
                    <div className="contactList flex-fill h-100 overflow-hidden">
                      <div className="h-100 overflow-auto">
                        <div className="contactItem unseen p-3 border-bottom d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="contactDetail w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="name">Bradley Lawlor</div>
                              <div className="lastSeen ">09:23 AM</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-4 mt-1">
                              <div className="lastMsg">Maecenas dignissim justo eget. Maecenas dignissim justo eget</div>
                              <div className="unSeenMsg">3</div>
                            </div>
                          </div>
                        </div>
                        <div className="contactItem p-3 border-bottom d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="contactDetail w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="name">Bradley Lawlor</div>
                              <div className="lastSeen ">09:23 AM</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-4 mt-1">
                              <div className="lastMsg">Maecenas dignissim justo eget. Maecenas dignissim justo eget</div>
                              <div className="unSeenMsg">3</div>
                            </div>
                          </div>
                        </div>
                        <div className="contactItem active p-3 border-bottom d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="contactDetail w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="name">Bradley Lawlor</div>
                              <div className="lastSeen ">09:23 AM</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-4 mt-1">
                              <div className="lastMsg">Maecenas dignissim justo eget. Maecenas dignissim justo eget</div>
                              <div className="unSeenMsg">3</div>
                            </div>
                          </div>
                        </div>
                        <div className="contactItem unseen p-3 border-bottom d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="contactDetail w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="name">Bradley Lawlor</div>
                              <div className="lastSeen ">09:23 AM</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-4 mt-1">
                              <div className="lastMsg">Maecenas dignissim justo eget. Maecenas dignissim justo eget</div>
                              <div className="unSeenMsg">3</div>
                            </div>
                          </div>
                        </div>
                        <div className="contactItem p-3 border-bottom d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="contactDetail w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="name">Bradley Lawlor</div>
                              <div className="lastSeen ">09:23 AM</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-4 mt-1">
                              <div className="lastMsg">Maecenas dignissim justo eget. Maecenas dignissim justo eget</div>
                              <div className="unSeenMsg">3</div>
                            </div>
                          </div>
                        </div>
                        <div className="contactItem p-3 border-bottom d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="contactDetail w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="name">Bradley Lawlor</div>
                              <div className="lastSeen ">09:23 AM</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-4 mt-1">
                              <div className="lastMsg">Maecenas dignissim justo eget. Maecenas dignissim justo eget</div>
                              <div className="unSeenMsg">3</div>
                            </div>
                          </div>
                        </div>
                        <div className="contactItem p-3 border-bottom d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="contactDetail w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="name">Bradley Lawlor</div>
                              <div className="lastSeen ">09:23 AM</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-4 mt-1">
                              <div className="lastMsg">Maecenas dignissim justo eget. Maecenas dignissim justo eget</div>
                              <div className="unSeenMsg">3</div>
                            </div>
                          </div>
                        </div>
                        <div className="contactItem p-3 border-bottom d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="contactDetail w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="name">Bradley Lawlor</div>
                              <div className="lastSeen ">09:23 AM</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-4 mt-1">
                              <div className="lastMsg">Maecenas dignissim justo eget. Maecenas dignissim justo eget</div>
                              <div className="unSeenMsg">3</div>
                            </div>
                          </div>
                        </div>
                        <div className="contactItem p-3 border-bottom d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="contactDetail w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="name">Bradley Lawlor</div>
                              <div className="lastSeen ">09:23 AM</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-4 mt-1">
                              <div className="lastMsg">Maecenas dignissim justo eget. Maecenas dignissim justo eget</div>
                              <div className="unSeenMsg">3</div>
                            </div>
                          </div>
                        </div>
                        <div className="contactItem p-3 border-bottom d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="contactDetail w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="name">Bradley Lawlor</div>
                              <div className="lastSeen ">09:23 AM</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-4 mt-1">
                              <div className="lastMsg">Maecenas dignissim justo eget. Maecenas dignissim justo eget</div>
                              <div className="unSeenMsg">3</div>
                            </div>
                          </div>
                        </div>
                        <div className="contactItem p-3 border-bottom d-flex align-items-center gap-2">
                          <div className="imgOuter">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="contactDetail w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="name">Bradley Lawlor</div>
                              <div className="lastSeen ">09:23 AM</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between gap-4 mt-1">
                              <div className="lastMsg">Maecenas dignissim justo eget. Maecenas dignissim justo eget</div>
                              <div className="unSeenMsg">3</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-8 col-md-7 h-100 chatOuter active">
                  <div className="chatInner bg-white rounded-3 overflow-hidden h-100 d-flex flex-column flex-nowrap">
                    <div className="chatHeader p-3 d-flex align-items-center gap-3">
                      <button type="button" className="btn backBtn border-0 shadow-none p-0 h-auto d-md-none">
                        <img className="" style={{filter:"brightness(0)"}} src={backArrow}/>
                      </button>
                      <div className="d-flex align-items-center gap-2">
                        <div className="imgOuter rounded-circle overflow-hidden">
                          <img className="w-100 h-100" src={avtarImg}/>
                        </div>
                        <div className="">
                          <div className="name fs-16 fw-medium">Bradley Lawlor</div>
                          <div className="lastSeen fs-12 fw-medium text-muted d-none ">09:23 AM</div>
                          <div className="online">Online</div>
                        </div>
                      </div>
                    </div>
                    <div className="chatBody flex-fill h-100 overflow-hidden">
                      <div className="h-100 overflow-auto p-3 d-flex flex-column-reverse gap-3">
                        <div className="msgMain recive d-flex gap-2 w-100">
                          <div className="msgSenderImg">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="msgOuter w-100  d-flex flex-column gap-2">
                            <div className="msg">
                              <div className="txt">Hello john, how are you?</div>
                              <div class="msgTime float-end ms-2 mt-1">09:23 AM</div>
                            </div>
                            <div className="msg">
                              <div className="txt">Hello john, how are you? Hello john, how are you?</div>
                              <div class="msgTime float-end ms-2 mt-1">09:23 AM</div>
                            </div>
                            <div className="msg">
                              <div className="txt">Hello john, how are you? Hello john, how are you? Hello john, how are you?</div>
                              <div class="msgTime float-end ms-2 mt-1">09:23 AM</div>
                            </div>
                          </div>
                        </div>
                        <div className="msgMain send d-flex gap-2 w-100">
                          <div className="msgOuter w-100 d-flex flex-column gap-2">
                            <div className="msg">
                              <div className="txt">Hello john, how are you?</div>
                              <div class="msgTime float-end ms-2 mt-1">09:23 AM</div>
                            </div>
                          </div>
                        </div>
                        <div className="msgMain recive d-flex gap-2 w-100">
                          <div className="msgSenderImg">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="msgOuter w-100 d-flex flex-column gap-2">
                            <div className="msg">
                              <div className="txt">Hello john, how are you?</div>
                              <div class="msgTime float-end ms-2 mt-1">09:23 AM</div>
                            </div>
                          </div>
                        </div>
                        <div className="msgDate">15-11-2023</div>
                        <div className="msgMain recive d-flex gap-2 w-100">
                          <div className="msgSenderImg">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="msgOuter w-100  d-flex flex-column gap-2">
                            <div className="msg">
                              <div className="txt">Hello john, how are you?</div>
                              <div class="msgTime float-end ms-2 mt-1">09:23 AM</div>
                            </div>
                            <div className="msg">
                              <div className="txt">Hello john, how are you? Hello john, how are you?</div>
                              <div class="msgTime float-end ms-2 mt-1">09:23 AM</div>
                            </div>
                            <div className="msg">
                              <div className="txt">Hello john, how are you? Hello john, how are you? Hello john, how are you?</div>
                              <div class="msgTime float-end ms-2 mt-1">09:23 AM</div>
                            </div>
                          </div>
                        </div>
                        <div className="msgMain send d-flex gap-2 w-100">
                          <div className="msgOuter w-100 d-flex flex-column gap-2">
                            <div className="msg">
                              <div className="txt">Hello john, how are you?</div>
                              <div class="msgTime float-end ms-2 mt-1">09:23 AM</div>
                            </div>
                          </div>
                        </div>
                        <div className="msgMain recive d-flex gap-2 w-100">
                          <div className="msgSenderImg">
                            <img className="w-100 h-100" src={avtarImg}/>
                          </div>
                          <div className="msgOuter w-100 d-flex flex-column gap-2">
                            <div className="msg">
                              <div className="txt">Hello john, how are you?</div>
                              <div class="msgTime float-end ms-2 mt-1">09:23 AM</div>
                            </div>
                          </div>
                        </div>
                        <div className="msgDate">14-11-2023</div>
                      </div>
                    </div>
                    <div className="chatFooter p-3">
                      <Form className="">
                        <Form.Group className="position-relative">
                          <Form.Control className="pe-5 rounded-pill"
                            type="text"
                            id="contactSearch"
                            aria-describedby="passwordHelpBlock"
                            placeholder="Type..."
                          />
                          <button type="button" className="btn msgSendBtn btnTheme2 p-2 position-absolute top-0 bottom-0 mt-2 mb-2 end-0 me-2 d-flex align-items-center justify-content-center">
                            <img src={msgSend}/>
                          </button>
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>

  );
}
export default Chat;