// export const BASE_URL = "http://153.92.4.13:8563/api";
//  export const BASE_URL = 'https://q8snxvdwgj.us-west-2.awsapprunner.com/api'
 
 //export const BASE_URL = 'http://localhost:8563/api'
//  export const BASE_URL = "http://64.23.184.14:8563/api";
 
//  export const BASE_URL = "http://localhost:5000/api";

export const BASE_URL = "https://www.acecam.tech:5000/api"

// export const BASE_URL = "/:5000api"