import axios from "axios";
import { useContext } from "react";
import { BASE_URL } from "../constants/api_urls";
import { AuthContext } from "../context/AuthContext";
import useBackgroundSync from "./useBackgroundSync";

const client = axios.create({
  baseURL: BASE_URL,
});

const useRequest = () => {
  const { logout } = useContext(AuthContext);
  const { addToSyncQueue } = useBackgroundSync();

  const request = async ({
    url,
    method: tmethod,
    data,
    onSuccess,
    onError,
    header,
    onErrorSubmit,
  }) => {
    const method = tmethod.trim().toUpperCase();
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";

    const headers = {
      ...header,
      Authorization: `Bearer ${token}`,
    };

    const requestData = {
      url,
      method,
      headers,
      data,
    };

    if (!navigator.onLine) {
      // If offline, add to sync queue
      await addToSyncQueue(requestData);
      if (onSuccess) {
        onSuccess({ status: true, message: "Request queued for background sync" });
      }
      return;
    }

    try {
      const response = await client(requestData);

      if (onSuccess) {
        onSuccess(response.data);
      }
      if (onError) {
        // This condition seems unnecessary, you might want to remove it
      } else {
        onErrorSubmit(response.data);
      }
      return response.data;
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        logout();
      }
      if (err.response && err.response.status === 403) {
        logout();
      }
      if (err.response && err.response.data.message === "jwt expired") {
        logout();
      }
      if (onError) {
        onError(err);
      }
    }
  };

  return { request };
};

export default useRequest;








// import axios from "axios";
// import React, { useState, useContext, useEffect } from "react";
// import { BASE_URL } from "../constants/api_urls";
// import { AuthContext } from "../context/AuthContext";
// const client = axios.create({
//   baseURL: BASE_URL,
// });

// const useRequest = () => {
//   const { logout } = useContext(AuthContext);
//   const request = async ({
//     url,
//     method: tmethod,
//     data,
//     onSuccess,
//     onError,
//     header,
//     onErrorSubmit,
//   }) => {
//     const method = tmethod.trim().toUpperCase();
//     let token = localStorage.getItem("token")
//       ? localStorage.getItem("token")
//       : "";

//     const headers = {
//       ...header,
//       Authorization: `Bearer ${token}`,
//     };

//     try {
//       const response = await client({
//         url,
//         method,
//         data,
//         headers: { ...headers },
//       });

//       if (onSuccess) {
//         // console.log(response.data, "response")
//         onSuccess(response.data);
//       }
//       if (onError) {
//         // console.log(onError, "onError Jay")
//       } else {
//         onErrorSubmit(response.data);
//       }
//       return response.data;
//     } catch (err) {
//       console.log(err);
//       if (err.response.status === 401) {
//         logout();
//       }
//       if (err.response.status === 403) {
//         logout();
//       }
//       if (err.response.data.message === "jwt expired") {
//         logout();
//       }
//       if (onError) {
//         onError(err);
//       }
//       // throw err;
//     }
//   };

//   return { request };
// };

// export default useRequest;
