import React, { useState, useEffect } from "react";
import Main from "../components/layout/Main";
import backArrow from "../assets/images/backArrow.png";
import gameImg from "../assets/images/gameImg.png";
import { useNavigate } from "react-router-dom";
import useRequest from "../../src/hooks/useRequest";

function Scorecard() {
  const { request } = useRequest();
  const navigate = useNavigate();

  const [gameList, setGameList] = useState([]);

  useEffect(() => {
    request({
      url: "/app/game/get-user-game",
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        setGameList(data.data.docs);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, []);
console.log("gameList",gameList)
  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3">
          <div className="col-lg-12">
            <div className="pagreHeader">
              <div className="pageHeadign d-flex align-items-center gap-3 mb-3">
                <button
                  onClick={() => navigate("/career")}
                  className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex"
                >
                  <img className="h-100" src={backArrow} alt="Back" />
                </button>
                <div className="txt">Scorecard</div>
              </div>
            </div>
          </div>
          <div className="col-12">
  {gameList.length > 0 ? (
    <div className="row g-3">
      {gameList.map((game, index) => (
        <div key={index} className="col-sm-6">
          <button
            onClick={() => navigate(`/allScoreData/${game?._id}`)}
            className="eventcard h-100 rounded-3 p-3 bg-white d-flex flex-column gap-12 w-100"
          >
            <div className="gameDetail d-flex align-items-center gap-2">
              <div className="imgOuter">
                <img
                  className="w-100 h-100"
                  src={gameImg}
                  alt="Game"
                />
              </div>
              <div className="detail">
                <div className="gameTitle">
                  At {game?.course?.name}
                </div>
                <div className="gameScore d-flex align-items-center gap-1 my-1">
                  {game.courseTeeData[0]?.name}{" "}
                  {game.courseTeeData[0]?.rating} /{" "}
                  {game.courseTeeData[0]?.slope}
                </div>
                <div className="competitionSize">
                  {game.holes} Hole
                </div>
              </div>
            </div>
            <div className="leaderProfile">
              <div className="row g-2">
                <div className="col-sm-6 text-start">
                  <div className="value">{game?.scoreRound}</div>
                  <div className="title">Round Score</div>
                </div>
                <div className="col-sm-6 text-sm-end">
                  <div className="value">{game?.handicapScore}</div>
                  <div className="title">Handicap</div>
                </div>
              </div>
            </div>
          </button>
        </div>
      ))}
    </div>
  ) : (
    <div className="text-center" style={{fontWeight: 'bold', color: 'white'}}>No Data found</div>

  )}
</div>

        </div>
      </section>
    </Main>
  );
}

export default Scorecard;
