import React, { useState, useContext, useEffect, useRef } from "react";
import $ from "jquery"
import OwlCarousel from "react-owl-carousel3";

import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select, Slider, Typography,Spin } from "antd";
import avtarImg from "../assets/images/avtarImg.png";
import fileUploadIcon from "../../src/assets/images/fileUploadIcon.png";
import backArrow from "../assets/images/backArrow.png";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import logoVerticaleTheme from "../../src/assets/images/logoVerticaleTheme.png";
import leftBtnArrow from "../assets/images/leftBtnArrow.png";
import rightBtnArrow from "../assets/images/rightBtnArrow.png";
import plusIconTheme2 from "../assets/images/plusIconTheme2.png";
import clockIconFill from "../assets/images/clockIconFill.png";
import gpsWhiteIcon from "../assets/images/gpsWhiteIcon.png";
import addPlayerIcon from "../assets/images/addPlayerIcon.png";
import settingIcon from "../assets/images/settingIcon.png";
import exitIcon from "../assets/images/exitIcon.png";
import useRequest from "../../src/hooks/useRequest"
import searchIcon from "../assets/images/searchIcon.png";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { ShowToast,Severty } from "../helper/toast";
const { Paragraph } = Typography;
function ScoreKeeping() {
  const location = useLocation();
  // const gameIdFromLocation = location?.state?.response?._id
  // const [gameId, setGameId] = useState(gameIdFromLocation);

  const { gameId } = useParams();
  let id = gameId

  console.log("gameId in scoreKeeping page", gameId)
  const [currentHole, setCurrentHole] = useState(1);
  const [friendList, setFriendList] = useState([])
  const { request } = useRequest();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newPostShow, newPostModal] = useState(false);
  const [show, scoreCardStepModal] = useState(false);
  const [Sdetail, setSdetail] = useState({})
  const scoreCardStepModalClose = () => scoreCardStepModal(false);
  const scoreCardStepModalOpen = () => scoreCardStepModal(true);
  const [scoreCard, setScoreCard] = useState()
  const [profile, setProfile] = useState({})
  const [gdetail, setGameDetail] = useState({})
  const [scoreRound, setScoreRound] = useState({})
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [courseList, setCourseList] = useState([])
  const [addPlayerShow, addPlayerModal] = useState(false);
  const addPlayerModalClose = () => addPlayerModal(false);
  const addPlayerModalOpen = () => addPlayerModal(true);
  const closeAddScoreModal = () => { setAddScoreShow(false); };
  const newPostModalClose = () => newPostModal(false);
  const newPostModalOpen = () => newPostModal(true);

  const [clubDetailEditShow, clubDetailEditModal] = useState(false);
  const clubDetailEditModalClose = () => clubDetailEditModal(false);
  const clubDetailEditModalOpen = () => clubDetailEditModal(true);
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [addScore, setAddScore] = useState([])
  const [holeMax,setholeMax] = useState('')
  const [playerList, serPlayerList] = useState([])
  const [isGameFinishedPopupOpen, setGameFinishedPopupOpen] = useState(false);
  const [addScoreShow, setAddScoreShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const openGameFinishedPopup = () => {
    setGameFinishedPopupOpen(true);
  };

  const closeGameFinishedPopup = () => {
    setGameFinishedPopupOpen(false);
  };
  const [scoreData, setScoreData] = useState({
    userId: null,
    gameId: gameId,
    scoreValue: "",
    putts: "",
    penalties: "",
    teeAccuracy: "",
    scoreRound: Sdetail?.hole,
    par: Sdetail?.par,
    totalDuration: "",
    putDistance: "",
    greenSideBunker: 0,
    fairwayBunker: 0,
    outOfBounds: 0,
    dropShot: 0,
    penaltyArea: 0,
    isFinished:false
  })

  // Function to handle changes in putts distance
  const handlePuttsDistanceChange = (event) => {
    const newDistance = parseInt(event.target.value); // Convert value to integer
    setScoreData({ ...scoreData, putDistance: newDistance }); // Update putDistance state
  };
  const handleScoreIncrement = (field) => {
    // Check if the field is greenSideBunker or fairwayBunker and if it's less than 2
    if ((field === 'greenSideBunker' || field === 'fairwayBunker' || field === 'dropShot' || field === 'penaltyArea') && scoreData[field] < 2) {
      setScoreData((prevScoreData) => ({
        ...prevScoreData,
        [field]: prevScoreData[field] + 1,
      }));
    }
  };

  // const gameDetail = () => {
  //   request({
  //     url: `app/game/game-detail/${id}`,
  //     method: "GET",
  //     onSuccess: (data) => {
  //       setGameDetail(data.data)
  //       scoreDetail(data.data)

  //     },
  //     onError: (error) => {
  //       console.log(error);
  //     },
  //   })
  // }
  useEffect(() => {
    request({
      url: `app/game/game-detail/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setGameDetail(data.data)
        setholeMax(data.data.holes)
        scoreDetail(data.data)

      },
      onError: (error) => {
        console.log(error);
      },
    })

  }, [])

  const holes_max = holeMax
  console.log("holesMx////////////////////////////",holes_max)

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    course: "",
    holes: "",
    media: "",
    // Add other fields as needed
  });

  // Function to open the modal
  const openAddScoreModal = (userId) => {
    console.log("userId???????????????????????????????", userId)
    setAddScoreShow(true);
    setScoreData(prevScoreData => ({ ...prevScoreData, userId: userId }));
    //  setScoreData({userId})
  };
  const handlechange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value })
    setScoreData({ ...scoreData, [name]: value })
  }

  useEffect(() => {
    request({
      url: "app/friend/user-friend-list",
      method: "GET",
      onSuccess: (data) => {
        setFriendList(data?.data?.data)

      },
      onError: (error) => {
        console.log("Error in fetching friend List", error)
      }
    })
    setUserProfile()
    scoreInAddScore(gameId, currentHole)
    // setScoreByHole(currentHole);

  }, [location, gameId, currentHole])

  useEffect(() => {
    scoreDetail()
  }, [currentHole]);

  useEffect(() => {
    request({
      url: `/common/getNewcourse?search=${encodeURIComponent(searchQuery)}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        // ShowToast(data.message, Severty.SUCCESS)
        setCourseList(data.data.docs);
      },
      onError: (error) => {
        console.log(error);
        //ShowToast(error, Severty.ERROR);
      },
    });
  }, [searchQuery]);

  const setUserProfile = () => {
    request({
      url: "app/user/get-profile",
      method: "GET",
      onSuccess: (data) => {
        setProfile(data.data); // Assuming the API response contains the user profile data
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault()
    formData['holes'] = selectedValue;
    console.log('formdata', formData);

    request({
      url: "/app/post/add-post",
      method: "POST",
      data: formData,
      onSuccess: (data) => {
        ShowToast("Post Added Successfully", Severty.SUCCESS)
        newPostModalClose();
        setFormData("")

        //setCourse(data.data.data);
      },
      onError: (error) => {
        console.log(error);
        // ShowToast(error, Severty.ERROR);
      },
    });
  }

  const handleSearchChange = (event) => {
    console.log("find isssue of searhc ", event)
    setSearchQuery(event.target.value);
  };
  // let courseId = gdetail?.course
  // let teeId = gdetail?.courseTee?._id


  const scoreDetail = async (data) => {
    const courseTee = data ? data?.courseTee?._id : gdetail?.courseTee?._id
    const course = data ? data?.course : gdetail?.course
    request({
      url: `/common/scoreData/${course}/${courseTee}/${currentHole}`,
      method: 'GET',
      onSuccess: (data) => {
        setSdetail(data.data)
        setScoreData(prev => ({ ...prev, scoreRound: data.data.hole, par: data.data.par }))
      },
      onError: (error) => {
        console.log(error);
      },
    })
  }

// Update minutes and hours when seconds or minutes change


  const fileUploads = (event) => {
    setLoading(true)
    const file = event.target.files[0];
    const formdata = new FormData()
    formdata.append('image', file)
    request({
      url: "/common/image-upload",
      method: "POST",
      data: formdata,
      onSuccess: (data) => {
        console.log("check data ", data.data.upload);
        setFormData({ ...formData, media: data.data.upload })
        setLoading(false)
      },
      onError: (error) => {
        console.log(error);
        setLoading(false)
      },
    });
  }

  const scoreCardData = async () => {
    request({
      url: `/app/score/get-score-game/${gameId}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        navigate(`/gameDetail/${id}`, { state: { response: data.data } })
        setScoreCard(data.data)
      },
      onError: (error) => {
        console.log(error);
        // ShowToast(error, Severty.ERROR);
      },
    });
  }

  const handleAddScore = () => {
    const isLastHole = currentHole == holes_max ? true: false;
  const updatedScoreData = { ...scoreData, isFinished: isLastHole };
    request({
      url: "/app/score/add-score",
      method: "POST",
      data: updatedScoreData,
      onSuccess: (data) => {
        console.log("data",data)
        scoreInAddScore(gameId, currentHole)
        gameDetail(id)
        closeAddScoreModal();
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const gameDetail = () => {
    request({
      url: `app/game/game-detail/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setGameDetail(data.data)
        scoreDetail(data.data)
        if (data.data.isFinished) {
          openGameFinishedPopup();
        }

      },
      onError: (error) => {
        console.log(error);
      },
    })
  }

  const [rows, setRows] = useState(1);
  // Function to handle clicking on the left arrow
  const handleLeftArrowClick = () => {
    if (currentHole > 1) {
      setCurrentHole(currentHole - 1);
      // scoreDetail();
      // setScoreData({scoreRound: currentHole})
      // Decrease hole number by 1 if it's greater than 1
    }
  };


  // Function to handle clicking on the right arrow
  const handleRightArrowClick = () => {
    console.log("currentHole", currentHole)
    const updatedHole = currentHole + 1;
    // You can add a condition here to check if the currentHole is within a certain limit
    // setCurrentHole(updatedHole); // Increase hole number by 1
    // scoreDetail();
    // setScoreData({scoreRound: currentHole})
    if (updatedHole <= holes_max) {
      setCurrentHole(updatedHole); // Increase hole number by 1
      // scoreDetail();
      // setScoreData({scoreRound: updatedHole})
    } else {
      // Do nothing or handle when the maximum number of holes is reached
      console.log("Reached the maximum number of holes");
    }

  };

  const handleHoleSelection = (holeNumber) => {
    setCurrentHole(holeNumber);
    // setScoreData({scoreRound: currentHole})

  };

  const setScoreByHole = (holeNumber) => {
    request({
      url: `app/score/get-score/${holeNumber}/${gameId}`,
      method: "GET",
      onSuccess: (data) => {
        setScoreData()
      },
      onError: (error) => {
        console.log(error);
      },
    })
  }

  const scoreInAddScore = (gameId, currentHole) => {
    request({
      url: `app/score/score-detail/${currentHole}/${gameId}`,
      method: "GET",
      onSuccess: (data) => {
        setAddScore(data.data.data)
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  const handleCheckboxChange = (friendId) => {
    console.log("friendId", friendId)
    // Check if the friendId is already in the selectedFriends array
    if (selectedFriends.includes(friendId)) {
      // If it is, remove it from the selectedFriends array
      setSelectedFriends(selectedFriends.filter(id => id !== friendId));
    } else {
      // If it's not, add it to the selectedFriends array
      setSelectedFriends([...selectedFriends, friendId]);
    }
  };

  const addPlayerData = () => {
    // Make the API call only if at least one friend is selected
    if (selectedFriends.length > 0) {
      const requestData = {
        id: profile?._id,
        players: selectedFriends ? selectedFriends : [] // Pass the array of selected friend IDs to the API
      };
      console.log("requestedData", requestData)
      request({
        url: `app/game/joinPlayer`,
        method: "PUT",
        data: requestData,
        onSuccess: (data) => {
          console.log("player joined successfully", data);
          addPlayerModalClose()

        },
        onError: (error) => {
          console.log("Error in joining player", error)
        }
      })
    } else {
      console.log("Please select at least one friend and provide the group ID.");
    }
  };

  console.log(currentHole, 'current hole state ==========>>>>>>>>')
  const itemsToShow = Math.min(2, Math.floor((5 - 1) / 4));
  return (
    <>
      <Main>
        <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="pagreHeader">
                <div className="pageHeadign d-flex align-items-center gap-3 mb-3">
                  <button onClick={handleOpenModal} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                    <img className="h-100" src={backArrow} />
                  </button>
                  <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to quit?</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseModal}>
                        No
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          navigate("/play");
                          handleCloseModal();
                        }}
                      >
                        Yes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <div className="txt">Score Keeping</div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row g-3">
                <div className="col-xl-8">
                  <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-12">
                    <div className="cardHead pb-3 border-bottom">
                      <div className="fs-6 fw-medium text-dark">Players</div>
                    </div>

                    {/* <div className="playerItem d-flex align-items-sm-center justify-content-between">

                      <div className="d-flex align-items-center gap-2">
                        <div className="imgOuter rounded-circle overflow-hidden">
                          <img className="w-100 h-100" src={
                            profile?.profilePic
                              ? `https://b2btobacco.s3.amazonaws.com/${profile?.profilePic}`
                              : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                profile?.name
                              )}&size=256`
                          } alt={profile.name} />
                        </div>
                        <div className="detail">
                          <div className="name fs-16 fw-medium text-dark">{profile?.name}</div>
                          <div className="dec mt-1 text-muted fs-14 mt-1">{profile?.handicapScore}(E)</div>
                        </div>
                      </div>

                      <Button className="btn btnOutlineTheme2 gap-1"  onClick={() => openAddScoreModal(profile?._id)}>
                        <img src={plusIconTheme2} />  {scoreValue !== null && scoreValue !== undefined ? (
                          <span>{scoreValue}</span>
                        ) : (
                          <span>Add Score</span>
                        )}
                      </Button>
                    </div> */}
                    {gdetail?.gameDetailsId?.map((list, index) => (
                      <div className="playerItem d-flex align-items-sm-center justify-content-between">

                        <div className="d-flex align-items-center gap-2">
                          <div className="imgOuter rounded-circle overflow-hidden">
                            <img className="w-100 h-100" src={
                              list?.user?.profilePic
                                ? `https://b2btobacco.s3.amazonaws.com/${list?.user?.profilePic}`
                                : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                  list?.user?.name
                                )}&size=256`
                            } alt={list.name} />
                          </div>
                          <div className="detail">
                            <div className="name fs-16 fw-medium text-dark">{list?.user?.name}</div>
                            <div className="dec mt-1 text-muted fs-14 mt-1">{list?.totalScore}(E)</div>
                          </div>
                        </div>

                        <Button disabled={addScore.length > index && addScore[index]?.scoreValue !== null && addScore[index]?.scoreValue !== undefined} className="btn btnOutlineTheme2 gap-1" onClick={() => openAddScoreModal(list?.user?._id)}>
                          <img src={plusIconTheme2} />{' '}
                          {addScore.length > index && addScore[index]?.scoreValue !== null && addScore[index]?.scoreValue !== undefined ? (
                            <span>{addScore[index]?.scoreValue}</span>
                          ) : (
                            <span>Add Score</span>
                          )}
                        </Button>
                      </div>
                    ))}

                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-12">
                    <div className="cardHead pb-3 border-bottom">
                      <div className="holeHeading d-flex align-items-center justify-content-center gap-1" >
                        <button type="button" className="btn p-0 bg-transparent border-0" onClick={() => handleLeftArrowClick()}><img className="h-100" src={leftBtnArrow} /></button>
                        <div className="text fs-6 fw-medium text-dark">Hole {currentHole} </div>
                        <button type="button" className="btn p-0 bg-transparent border-0" onClick={() => handleRightArrowClick()}><img className="h-100" src={rightBtnArrow} /></button>
                      </div>
                      <div className="text-muted fs-14 text-center">Par {Sdetail?.par} Yards {Sdetail?.value} l {Sdetail?.handicap}</div>
                    </div>
                    <div className="cardBody pt-1 d-flex flex-column gap-12">
                      <Timer />
                      {/* <button type="button" className="btn btnStyle1 btnTheme2 shadow-none text-white"><img className="h-100" src={gpsWhiteIcon} /> GPS On</button> */}
                      <div className="btnGroup mt-1 d-flex flex-column gap-2">
                        {/* <button type="button" onClick={addPlayerModalOpen} className="btn btnStyle1 px-0 py-2 border-0 shadow-none bg-transparent d-flex align-items-center gap-2 w-100"><img className="h-100" src={addPlayerIcon} /> Add Players</button> */}
                        {/* <button type="button" onClick={clubDetailEditModalOpen} className="btn btnStyle1 px-0 py-2 border-0 shadow-none bg-transparent d-flex align-items-center gap-2 w-100"><img className="h-100" src={settingIcon} /> Round Settings</button> */}
                        <button onClick={handleOpenModal} className="btn btnStyle1 px-0 py-2 border-0 shadow-none bg-transparent d-flex align-items-center gap-2 w-100"><img className="h-100" src={exitIcon} /> Round Pause & Exit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="col-12 mt-3"> {/* Add margin top to create space */}
              <Button className="btn btnOutlineTheme2 me-2" onClick={() => scoreCardData()}>
                Scoreboard
              </Button>
              <Button className="btn btnOutlineTheme2" onClick={newPostModalOpen}>
                Add Post
              </Button>
            </div>
          </div>
        </section>

      </Main>


      {/* Add Player Modal  */}
      <Modal show={addPlayerShow} className="headerModals" onHide={addPlayerModalClose} centered>
        <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <Form className="row gap-12">
            <Form.Group className="col-12 position-relative">
              <Form.Label htmlFor="contactSearch" style={{ cursor: "pointer" }} className="position-absolute top-0 bottom-0 my-auto end-0 me-3 d-flex align-items-center justify-content-center">
                <img src={searchIcon} />
              </Form.Label>
              <Form.Control className="pe-5"
                type="search"
                id="contactSearch"
                placeholder="Search by username, email or Handicap ID"
                aria-describedby="passwordHelpBlock"
              />
            </Form.Group>
            <Form.Group className="col-md-12 selectPlayerOuter">
              {friendList.map((list) => (
                <Form.Check
                  key={list.id} // Add a unique key prop for each Form.Check component
                  className="selectPlayerCheck m-0 p-0 d-flex align-items-center justify-content-between flex-row-reverse"
                  name="group1"
                  type="checkbox"
                  id={`player_${list.id}`} // Use a unique ID for each Form.Check component
                  onChange={() => handleCheckboxChange(list?._id)} // Pass friend ID to handleCheckboxChange
                  checked={selectedFriends.includes(list?._id)} // Check if the friend ID is in selectedFriends array
                  label={
                    <div className="playerPro d-flex align-items-center gap-2">
                      <div className="playerImg overflow-hidden rounded-circle">
                        <img className="w-100 h-100" src={
                          list?.profilePic
                            ? `https://b2btobacco.s3.amazonaws.com/${list?.profilePic}`
                            : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                              list?.name
                            )}&size=256`
                        } alt="Avatar" />
                      </div>
                      <div className="detail">
                        <div className="name">{list.name}</div>
                        <div className="dec mt-1">{list && list.city}, {list && list.state}</div>
                      </div>
                    </div>
                  }
                />
              ))}
            </Form.Group>

            <Form.Group className="col-12 mt-4 text-end">
              <button type="button" className="btn btnStyle1 btnTheme1 shadow-none px-5 w-100" onClick={addPlayerData}>Add Players</button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={newPostShow}
        size="lg"
        className="headerModals"
        onHide={newPostModalClose}
        centered
      >
        <Modal.Header
          className="p-4 position-relative justify-content-center"
          closeButton
        >
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Form className="row g-2" onSubmit={handleSubmit}>
            <Form.Label className="col-12 mb-0">New Post</Form.Label>
            <div className="col-12 mb-0">
              <div className="row">
                <div className="col-12 col-md-6">
                  <Form.Group
                    controlId="formFile"
                    className="uploadFileOuter w-100 h-100"
                  >
                    <Form.Label className="w-100 h-100 d-flex align-items-center justify-content-center">
                      <div className="uploadFile text-center profile-upload">
                      <Spin spinning={loading} size="large">
                        <div className="imgOuter">
                          {formData?.media && formData.media.endsWith('.mp4') ? (
                            <video src={`https://b2btobacco.s3.amazonaws.com/${formData.media}`} autoPlay="true" type="video/mp4" />
                          ) : (
                            <img src={formData?.media ? `https://b2btobacco.s3.amazonaws.com/${formData?.media}` : fileUploadIcon} />
                          )}
                        </div>
                        <div className="txt mt-3">Upload Img or video</div>
                        </Spin>
                      </div>
                    </Form.Label>
                    <Form.Control className="d-none" type="file" name="media" onChange={fileUploads} />
                  </Form.Group>
                </div>
                <div className="col-12 mt-md-0 mt-3 col-md-6">
                  <div className="row gap-12">
                    <Form.Group className="col-12">
                      <Form.Control
                        className="sahdow-none"
                        type="text"
                        name="title"
                        onReset={Form}
                        required="title"
                        placeholder="Title"
                        onChange={handlechange}
                      />
                    </Form.Group>
                    <Form.Group className="col-12 position-relative">
                      <Form.Control
                        as="textarea"
                        className="sahdow-none"
                        type="text"
                        onReset={Form}
                        name="description"
                        placeholder="Description"
                        onChange={handlechange}
                        rows={3}
                      />
                    </Form.Group>
                    <Form.Group controlId="formCourse">
                      <Form.Label>Search and Select Course</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search for a course..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <Form.Control
                        as="select"
                        name="course"
                        required="course"
                        onReset={Form}
                        value={formData.course}
                        onChange={handlechange}
                        style={{ marginTop: '10px' }}
                      >
                        <option value="">Select Course</option>
                        {courseList.map((course) => (
                          <option key={course.id} value={course.id}>
                            {course.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>



                    <Form.Group className="col-12">
                      <Form.Select
                        aria-label="Default select example"
                        name="holes"
                        onChange={(e) => {
                          setSelectedValue(e.target.value)

                        }}

                      >
                        <option>Select Hole</option>
                        <option value="9">Hole 9</option>
                        <option value="18">Hole 18</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="col-12">
                      <Form.Check
                        className="switchCheck shadow-none p-0 d-flex gap-2 justify-content-between"
                        type="switch"
                        id="TheGrintTour"
                        label={
                          <>
                            <div className="title fw-medium text-dark fs-6">
                              TheGrint Tour
                            </div>
                            <div className="subTitle">
                              Messages send by us through your journey
                            </div>
                          </>
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-12 mt-4 text-end">
                      <button
                        type="submit"
                        className="btn btnStyle1 btnTheme1 shadow-none px-5 text-center w-100"
                      >
                        Post
                      </button>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Club detail edit Modal  */}
      <Modal show={clubDetailEditShow} className="headerModals" onHide={clubDetailEditModalClose} centered>
        <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
          <Modal.Title>
            <img src={logoVerticaleTheme} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-sm-4 p-3">
          <Form className="row g-2">
            <Form.Label className="col-12 mb-0">Course Details</Form.Label>
            <Form.Group className="col-md-12 mt-1">
              <Form.Control className="sahdow-none" type="search" placeholder="Search" />
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Control className="sahdow-none" value={new Date().toISOString().split('T')[0]} type="date" placeholder="DD/MM/YYYY" />
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Select aria-label="Default select example">
                <option>Select a Teebox</option>
                <option value="1">Teebox 1</option>
                <option value="2">Teebox 2</option>
                <option value="3">Teebox 3</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Select aria-label="Default select example">
                <option>Select Round</option>
                <option value="1">Round 1</option>
                <option value="2">Round 2</option>
                <option value="3">Round 3</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-12 mt-4 text-end">
              <button type="button" className="btn btnStyle1 btnTheme1 shadow-none px-5 w-100">Save</button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={addScoreShow} onHide={closeAddScoreModal} className="headerModals" centered>
        <Modal.Body>
          <div className="headding-modal">
            <h6>Score Tracker</h6>
          </div>
          <div className="modal-card-01">
            <div className="score-headding">
              <h6>Score</h6>
            </div>

            <OwlCarousel onDrag={(event) => {
              const activeItem = event.item.index + 1;
              console.log("Active Item:", event);
            }}
              onDragged={(event) => {
                const activeItem = event.item.index + 1;
                console.log("Active Item fine:", activeItem, event.item.index);
                setScoreData(prev => ({ ...prev, scoreValue: activeItem }))
                $('.owl-carousel').trigger('stop.owl.autoplay');
              }}
              className='owl-theme' center={true} items={5} loop={false} margin={5} nav={false} dots={false}
              startPosition={scoreData.scoreValue - itemsToShow}
            >
              {
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map(item =>
                  <div class='item'
                  // onClick={() => {
                  //   setScoreData(prev => ({ ...prev, scoreValue: item }))
                  // }}
                  >
                    <div className="score-type">
                      <h4>{item}</h4>
                    </div>
                  </div>
                )
              }

            </OwlCarousel>
          </div>
          <div className="modal-card-01">
            <div className="score-headding">
              <h6>Putts</h6>
            </div>
            <div className="putts-type">
              <ul>
                {[0, 1, 2, 3, 4, 5].map(value => (
                  <li key={value} className={scoreData.putts === value ? 'active' : ''} onClick={() => setScoreData(prev => ({ ...prev, putts: value }))}>
                    {value}
                  </li>
                ))}
              </ul>
            </div>

          </div>
          <div className="modal-card-01">
            <div className="score-headding d-flex justify-content-between">
              <h6>1st Putts Distance</h6>
              <h6>{scoreData.putDistance} FT</h6> {/* Display current putDistance value */}
            </div>
            <div className="putts-type-range">
              <input
                type="range"
                className="form-range"
                id="customRange1"
                value={scoreData.putDistance} // Bind value of input to putDistance state
                onChange={handlePuttsDistanceChange} // Call handler function on change
              />
            </div>
          </div>
          <div class="single-post-dtl mt-5">
            {/* Greenside Bunker */}
            <div className="score-post" onClick={() => handleScoreIncrement('greenSideBunker')}>
              <div className="post-info-ic">{scoreData.greenSideBunker}</div>
              <span>Greenside Bunker</span>
            </div>

            {/* Fairway Bunker */}
            <div className="score-post" onClick={() => handleScoreIncrement('fairwayBunker')}>
              <div className="post-info-ic">{scoreData.fairwayBunker}</div>
              <span>Fairway Bunker</span>
            </div>

            {/* Out of Bounds */}
            <div className="score-post" onClick={() => handleScoreIncrement('outOfBounds')}>
              <div className="post-info-ic">{scoreData.outOfBounds}</div>
              <span>Out of Bounds</span>
            </div>

            {/* Drop Shot */}
            <div className="score-post" onClick={() => handleScoreIncrement('dropShot')}>
              <div className="post-info-ic">{scoreData.dropShot}</div>
              <span>Drop shot</span>
            </div>

            {/* Penalty Area */}
            <div className="score-post" onClick={() => handleScoreIncrement('penaltyArea')}>
              <div className="post-info-ic">{scoreData.penaltyArea}</div>
              <span>Penalty Area</span>
            </div>
          </div>

          <div className="btnGroup d-flex flex-column flex-sm-row align-items-center gap-3 mt-4">
            <Button type="button" onClick={closeAddScoreModal} className="btn btnStyle1 btnOutlineTheme1 btnWidth shadow-none px-3 w-100 d-flex align-items-center gap-1 justify-content-center">Cancel</Button>
            <Button type="button" onClick={handleAddScore} className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-3 w-100">Add</Button>
          </div>
        </Modal.Body>
      </Modal>

        {/* Game finished popup */}
        <Modal show={isGameFinishedPopupOpen} onHide={closeGameFinishedPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Game Finished</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The game is finished!</p>
          {/* Add any additional content here */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeGameFinishedPopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  );
}



const Timer = () => {

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Calculate minutes and hours based on seconds
  useEffect(() => {
    if (seconds === 60) {
      setSeconds(0);
      setMinutes(prevMinutes => prevMinutes + 1);
    }
    if (minutes === 60) {
      setMinutes(0);
      setHours(prevHours => prevHours + 1);
    }
  }, [seconds, minutes]); 
  return (
    <div className="timing d-flex align-items-center gap-12 p-sm-3 p-2 mb-1">
      <img src={clockIconFill} />
      <div className="fs-6 fw-medium text-dark d-flex d-xl-block d-xxl-flex align-items-center gap-12">
        <div className="hole"><span>Hole:  <span className="fw-bold">{`${hours}:${minutes}:${seconds}`}</span></span></div>
        <div className="round d-flex align-items-center gap-12"><span>Round: <span className="fw-bold">{`${hours}:${minutes}:${seconds}`}</span></span></div>
      </div>
    </div>)
}
export default ScoreKeeping;