import { useEffect } from 'react';
import { openDB } from 'idb';

const BACKGROUND_SYNC_QUEUE = 'backgroundSyncQueue';

const dbPromise = openDB('backgroundSync', 1, {
  upgrade(db) {
    db.createObjectStore(BACKGROUND_SYNC_QUEUE, { keyPath: 'id', autoIncrement: true });
  },
});

const useBackgroundSync = () => {
  useEffect(() => {
    if ('serviceWorker' in navigator && 'SyncManager' in window) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.sync.register('backgroundSync');
      });
    }
  }, []);

  const addToSyncQueue = async (request) => {
    if ('serviceWorker' in navigator && 'SyncManager' in window) {
      const db = await dbPromise;
      await db.add(BACKGROUND_SYNC_QUEUE, request);
      await navigator.serviceWorker.ready;
      await navigator.serviceWorker.sync.register('backgroundSync');
    } else {
      // Fallback for browsers that don't support background sync
      try {
        const response = await fetch(request.url, {
          method: request.method,
          headers: request.headers,
          body: JSON.stringify(request.data),
        });
        return response.json();
      } catch (error) {
        console.error('Error performing request:', error);
      }
    }
  };

  return { addToSyncQueue };
};

export default useBackgroundSync;