import React, { useState, useEffect } from "react";
import Main from "../components/layout/Main";
import { Input, Card,Spin } from "antd";
import useRequest from "../../src/hooks/useRequest";
import logoVerticaleTheme from "../assets/images/logoVerticaleTheme.png";
import backArrow from "../assets/images/backArrow.png";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Modal } from "antd";
const { Search } = Input;
function Friends() {
  const [loading, setLoading] = useState(true)
  const { request } = useRequest();
  const [isModalVisible,setIsModalVisible] = useState(false)
  const [friendList, setFriendList] = useState([])
  const [hoveredFriend, setHoveredFriend] = useState(null);

  const showModal = () =>{
    setIsModalVisible(true)
  }

  const handleCancel = () =>{
    setIsModalVisible(false)
  }

  const handleMouseEnter = () =>{
    console.log("<><><><<><><><><><><>is modal Open ")
    showModal();
  }

  const handleMouseLeave = () => {
    console.log("<><><><<><><><><><><>is modal Closed ");
    handleCancel();
  };

  const handleFriendHover = (friend) => {
  
    setHoveredFriend(friend);
    handleMouseEnter()
  };

  //function to handle search 
  const handleSearch = (value) => {
    request({
      url: `/app/friend/user-list?search=${value}`,
      method: "GET",
      onSuccess: (data) => {
        setFriendList(data?.data?.docs)
        setLoading(false);
      },
      onError: (error) => {
        console.log("Error in fetching friend List", error)
        setLoading(false);
      }
    })
  }
  const [addFriend, setAddFriend] = useState({
    recevedUserId: null
  })
  const [acceptRequest, setAccept] = useState({
    recevedUserID: null,
    accept: true
  })
  const addFriendData = (id) => {
    request({
      url: "app/friend/request-friend",
      method: "POST",
      data: { recevedUserId: id },
      onSuccess: (data) => {
        console.log("friend added successfully:", data);
        handleSearch("");
      },
      onError: (error) => {
        console.error("Error updating profile:", error);
      },
    })
  }
  const cancelFriendData = (id) => {
    request({
      url: "app/friend/cancel-request-friend",
      method: "POST",
      data: { recevedUserId: id },
      onSuccess: (data) => {
        console.log("friend cancel request successfully:", data);
        handleSearch("");
      },
      onError: (error) => {
        console.error("Error updating profile:", error);
      },
    })
  }

  const acceptFriendData = (id) => {
    request({
      url: "/app/friend/accept-deny-request-friend",
      method: "POST",
      data: { recevedUserID: id, accept: true },
      onSuccess: (data) => {
        console.log("accept request successfully:", data);
        handleSearch();
      },
      onError: (error) => {
        console.error("Error updating profile:", error);
      },
    })
  }
  const handleAddFriend = async (friend) => {
    console.log("Sender User ID:", friend?.senderUserId);
    if (friend?.senderUserId === "Add Friend") {
      // setAddFriend({ recevedUserId: friend?._id })
      await addFriendData(friend?._id);
    }
    else if (friend?.senderUserId === "Request Sent") {
      // setAddFriend({ recevedUserId: friend?._id })
      await cancelFriendData(friend?._id)
    }
    else if (friend?.senderUserId === "Accept") {
      setAccept({ recevedUserID: friend?._id, accept: true })
      await acceptFriendData(friend?._id)
    }
    else {
      console.log("Cannot add friend. Invalid senderUserId:", friend?.senderUserId);
    }
  }
  useEffect(() => {
    handleSearch('')
  }, [])
  const navigate = useNavigate();
  return (
    <Main>
      { }
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3">
          <div className="col-lg-12">
            <div className="pagreHeader">
              <div className="pageHeadign d-flex align-items-center gap-3 mb-3">
                <button onClick={() => navigate(`/Home`)}className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                  <img className="h-100" src={backArrow} alt="Back Arrow" />
                </button>
                <div className="txt">Add Friends</div>
              </div>
              <div className="right-search">
                <Search
                  placeholder="input search text"
                  onSearch={handleSearch}
                  className="mb-3 input-search"
                />
                {/* <Button className="qr-btn"><QrcodeOutlined /></Button> */}
              </div>
            </div>
          </div>
          <div>
  {loading ? (
    <div style={{ textAlign: 'center' }}>
      <Spin size="large" tip="Loading..." style={{ color: '#04070c' }} />
    </div>
  ) : friendList.length === 0 ? (
    <div className="nodata-f">No data found</div>
  ) : (
    friendList.filter((friend) => friend.is_active).map((friend) => (
      <Card key={friend.id} className="mb-3">
        <div className="profileDetail d-flex align-items-center justify-content-between gap-2 w-100">
          <div className="d-flex align-items-center gap-2">
            <div
              className="frnd-img rounded-circle overflow-hidden"
              onMouseEnter={() => handleFriendHover(friend)}
            >
              <img
                className="w-100 h-100"
                src={
                  friend?.profilePic
                    ? `https://b2btobacco.s3.amazonaws.com/${friend?.profilePic}`
                    : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                        friend?.name
                      )}&size=256`
                }
                alt="Friend Profile"
              />
            </div>

            <div className="profileDetail">
              <div className="frnd-name">{friend.name}</div>
              <div className="dec ">{friend?.created_At} </div>
            </div>
          </div>
          <button
            type="button"
            onClick={() => handleAddFriend(friend)}
            className="btn px-3 add-btn_primary"
          >
            {friend?.senderUserId === "Request Sent" ? "Cancel" : friend?.senderUserId}
          </button>
        </div>
      </Card>
    ))
  )}
</div>

        </div>
        {/* Add your content and functionality here */}
        {/* For example, input fields, buttons, modal, etc. */}
      </section>
      {/* Modal */}
      <Modal 
  visible={isModalVisible}
  onCancel={handleCancel}
  footer={null}
  className="headerModals privacymodal"
>
  {hoveredFriend && (
    <div>
      <div className="p-sm-4 p-3 modal-header">
        <div className="modal-title h4">
          <h2 className="form-headdin">Friend Details</h2>
        </div>
      </div>
      <div className="p-sm-4 p-3 modal-body">
        <div className="user-details">
          <span>Name :</span>
          <h6>{hoveredFriend.name}</h6>
        </div>
        <div className="user-details">
          <span>Email :</span>
          <h6>{hoveredFriend.email}</h6>
        </div>
        <div className="user-details">
          <span>Phone Number:</span>
          <h6>{hoveredFriend.mobileNumber}</h6>
        </div>
      </div>
    </div>
  )}
</Modal>

    </Main>
  );
}

export default Friends;
