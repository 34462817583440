import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import ScoreCard from "../assets/images/score-card.svg";
import avtarImg from "../assets/images/avtarImg.png";
import trophyIcon from "../assets/images/trophyIcon.png";
import backArrow from "../assets/images/backArrow.png";
import Form from 'react-bootstrap/Form';
import shareIcon from "../assets/images/shareIcon.png";
import gameImg from "../assets/images/gameImg.png";
import golfIcon from "../assets/images/golfIcon.png";
import { useNavigate, useLocation } from "react-router-dom";
function EventRyderFormatSetup() {
 
  const navigate = useNavigate();
  return (
    <Main>
      <section className="home-wrap vh100 px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3 h-100 flex-column flex-nowrap">
          <div className="col-lg-12">
            <div className="pageHeadign d-flex align-items-center gap-3">
              <button onClick={()=>navigate("/leader-board")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                <img className="h-100" src={backArrow}/>        
              </button> 
              <div className="txt">Event Ryder Format Setup</div>
            </div>
          </div>
          <Form className="col-12 h-100 flex-fill d-flex flex-column flex-nowrap">
            <div className="h-100 flex-fill">
              <div className="row g-3">
                <div className="col-12">
                  <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-12">
                    <div className="row g-2">
                    <Form.Label className="col-12 mb-0 lh-sm">Ryder Format Setup</Form.Label>
                    <Form.Group className="col-12">
                      <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-sm-4 gap-3">
                        <div className="teamColorOuter blue d-flex align-items-center gap-2">
                          <div className="teamColor rounded-1"></div>
                          <div className="detail">
                            <div className="title">Team Blue</div>
                            <div className="value">Jonny</div>
                          </div>
                        </div>
                        <div className="teamColorOuter red d-flex align-items-center gap-2">
                          <div className="teamColor rounded-1"></div>
                          <div className="detail">
                            <div className="title">Team red</div>
                            <div className="value">Jonny</div>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-sm-6">
                      <Form.Select aria-label="Default select example">
                        <option>Select Session</option>
                        <option value="1">Session 1</option>
                        <option value="2">Session 2</option>
                        <option value="3">Session 3</option>
                      </Form.Select>
                    </Form.Group>
                    <div className="col-12">
                      <div className="row g-2">
                        <Form.Group className="col-sm-6">
                          <Form.Control className="sahdow-none" type="date" placeholder="MM/DD/YYYY" />
                        </Form.Group>
                        <Form.Group className="col-sm-6">
                          <Form.Control className="sahdow-none" type="date" placeholder="MM/DD/YYYY" />
                        </Form.Group>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="sessionCard h-100 rounded-3 bg-white d-flex flex-column gap-12">
                    <div className="headign">Session 1</div>
                    <Form.Group className="">
                      <Form.Select aria-label="Default select example">
                        <option>Select Title</option>
                        <option value="1">Makena Golf & Beach Club l Makena Maka</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="">
                      <Form.Select aria-label="Default select example">
                        <option>Select Color</option>
                        <option value="1">Blue, 4004 yrds</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="">
                      <Form.Select aria-label="Default select example">
                        <option>Select Round</option>
                        <option value="1">Round: 18 Hole</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="">
                      <Form.Select aria-label="Default select example">
                        <option>Select match</option>
                        <option value="1">Four-Ball Match (2-man teams)</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="sessionCard h-100 rounded-3 bg-white d-flex flex-column gap-12">
                    <div className="headign">Session 1</div>
                    <Form.Group className="">
                      <Form.Select aria-label="Default select example">
                        <option>Select Title</option>
                        <option value="1">Makena Golf & Beach Club l Makena Maka</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="">
                      <Form.Select aria-label="Default select example">
                        <option>Select Color</option>
                        <option value="1">Blue, 4004 yrds</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="">
                      <Form.Select aria-label="Default select example">
                        <option>Select Round</option>
                        <option value="1">Round: 18 Hole</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="">
                      <Form.Select aria-label="Default select example">
                        <option>Select match</option>
                        <option value="1">Four-Ball Match (2-man teams)</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="sessionCard h-100 rounded-3 bg-white d-flex flex-column gap-12">
                    <div className="headign">Session 1</div>
                    <Form.Group className="">
                      <Form.Select aria-label="Default select example">
                        <option>Select Title</option>
                        <option value="1">Makena Golf & Beach Club l Makena Maka</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="">
                      <Form.Select aria-label="Default select example">
                        <option>Select Color</option>
                        <option value="1">Blue, 4004 yrds</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="">
                      <Form.Select aria-label="Default select example">
                        <option>Select Round</option>
                        <option value="1">Round: 18 Hole</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="">
                      <Form.Select aria-label="Default select example">
                        <option>Select match</option>
                        <option value="1">Four-Ball Match (2-man teams)</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end mt-3">
              <button type="button" onClick={()=>navigate("/ryder-event")} className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-5">Create</button>
            </div>
          </Form>
        </div>
      </section>
    </Main>
  );
}



export default EventRyderFormatSetup;
