import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import graphImg from "../assets/images/graphImg.png";
import golfIconDark from "../assets/images/golfIconDark.png";
import handicapRangeIcon from "../assets/images/handicapRangeIcon.png";
import { useNavigate, useLocation } from "react-router-dom";
import useRequest from "../../src/hooks/useRequest";

function Handicap() {
  const { request } = useRequest();
  const navigate = useNavigate();
  const [average, setAverage] = useState()
  const [handicap, setHandicap] = useState()
  const [show, scoreCardStepModal] = useState(false);
  const scoreCardStepModalClose = () => scoreCardStepModal(false);
  const scoreCardStepModalOpen = () => scoreCardStepModal(true);
  useEffect(() => {
    request({
      url: `app/game/get_lowest_highest_Score`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        // ShowToast(data.message, Severty.SUCCESS)
        setAverage(data.data);
      },
      onError: (error) => {
        console.log(error);
        //ShowToast(error, Severty.ERROR);
      },
    });
    fetchHandicapData()
  }, []);
  const fetchHandicapData = () => {
    request({
      url: `/app/game/getHandicapScore`,
      method: "GET",
      onSuccess: (data) => {
        // ShowToast(data.message, Severty.SUCCESS)
        setHandicap(data.data);
      },
      onError: (error) => {
        console.log(error);
        //ShowToast(error, Severty.ERROR);
      },

    })
  }

  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3">
          <div className="col-12">
            <div className="row g-3">
              <div className="col-xl-8">
                <div className="handicapeCard p-3 rounded-3 bg-white h-100 d-flex flex-column flex-nowrap">
                  <div className="handicapeCardHeader">
                    <div className="title mb-1">Score Differentials</div>
                    <div className="graphIndicater d-flex flex-column flex-sm-row align-items-sm-center gap-sm-4 gap-2">
                      <div className="graphIndicaterItem handicap d-flex gap-1">Count Towards Handicap</div>
                      <div className="graphIndicaterItem d-flex gap-1">Not for in Hdcp yet</div>
                    </div>
                  </div>
                  <div className="handicapeCardBody flex-fill h-100">
                    <div className="graphOuter">
                      <img className="w-100" src={graphImg} />
                    </div>
                    {/* <div className="handicapDetail">
                      <div className="title mb-2">Handicap details</div>
                      <div className="handicapeContent mb-0 pt-1">
                        <div className="row g-2">
                          <div className="col-xxl-5 col-sm-6">
                            <div className="txt date">Jan 07, 2022</div>
                            <div className="txt dec text-muted mt-1">Trump National Doral l Doral l Red Tiger</div>
                          </div>
                          <div className="col-xxl-5 col-sm-6">
                            <div className="txt">Score: 85</div>
                            <div className="txt mt-1">Handicap Diff: 12.8</div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="handicapeCard p-3 rounded-3 bg-white h-100 d-flex flex-column flex-nowrap">
                  <div className="handicapeCardBody flex-fill h-100">
                    <div className="handicapDetail pt-0">
                      <div className="title lh-sm">Handicap details</div>
                      {/* <a href="" className="fs-14 text-decoration-underline">View Handicap Card</a> */}
                      <div className="handicapeContent mb-0">
                        <div className="attestedScores rounded-1 text-center">
                          <div className="value text-white">{(handicap?.totalAverage ?? 0).toFixed(2)}</div>
                          <div className="title text-white">Attested Scores</div>
                        </div>
                        <div className="row otherDetail gap-12">
                          <div className="col-12">
                            <div className="value text-blue">{(average?.lowest8Average ?? 0).toFixed(2)}</div>
                            <div className="title">Low Handicap</div>
                          </div>
                          <div className="col-12">
                            <div className="value text-blue">{(average?.highest8Average ?? 0).toFixed(2)}</div>
                            <div className="title">Differential Avg.</div>
                          </div>
                          <div className="col-12">
                            <div className="value text-blue">{(average?.finalHandicapScore ?? 0).toFixed(2)}</div>
                            <div className="title">Anti Handicap</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="handicapRange p-3 bg-white rounded-3">
              <div className="handicapRangeBody d-flex flex-column gap-12">
                <div className="d-flex align-items-center gap-2 pb-3 border-bottom mb-1">
                  <div className="imgOuter">
                    <img src={handicapRangeIcon} alt="Handicap Range Icon" />
                  </div>
                  <div className="">
                    <div className="fs-6 fw-medium text-dark">How to Read The Data</div>
                    <div className="dec text-muted fs-14">
                      {/* Below we explain how to use this graph. To Manage your handicap, Visit{" "}
                      <a href="" className="text-decoration-underline">Mt Handicap</a> */}
                    </div>
                  </div>
                </div>
                <div className="handicapRangeItem">
                  <div className="d-flex align-items-center gap-2 fs-6 fw-medium text-dark lh-sm">
                    <img src={golfIconDark} alt="Golf Icon" />
                    Handicap
                  </div>
                  <div className="dec fs-14 text-muted mt-1 lh-sm">
                    Handicap refers to a golfer's playing ability. It is measured by the number of strokes above or below par that a player is expected to score on a course.
                  </div>
                </div>
                <div className="handicapRangeItem">
                  <div className="d-flex align-items-center gap-2 fs-6 fw-medium text-dark lh-sm">
                    <img src={golfIconDark} alt="Golf Icon" />
                    Best Handicap
                  </div>
                  <div className="dec fs-14 text-muted mt-1 lh-sm">
                    Best Handicap represents the lowest handicap score achieved by a player over a certain period, indicating their best playing ability.
                  </div>
                </div>
                <div className="handicapRangeItem">
                  <div className="d-flex align-items-center gap-2 fs-6 fw-medium text-dark lh-sm">
                    <img src={golfIconDark} alt="Golf Icon" />
                    Score Value Avg
                  </div>
                  <div className="dec fs-14 text-muted mt-1 lh-sm">
                    Score Value Avg represents the average score value obtained by a player across multiple games, providing an indication of their typical performance.
                  </div>
                </div>
                <div className="handicapRangeItem">
                  <div className="d-flex align-items-center gap-2 fs-6 fw-medium text-dark lh-sm">
                    <img src={golfIconDark} alt="Golf Icon" />
                    Anti Handicap
                  </div>
                  <div className="dec fs-14 text-muted mt-1 lh-sm">
                    Anti Handicap is a theoretical concept representing the opposite of a player's handicap. It is used for comparison purposes and indicates areas where improvement is needed.
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      {/* Score card Modal  */}
      {/* <Modal show={show} size="lg" className="headerModals" onHide={scoreCardStepModalClose} centered>
      <Modal.Header className="p-sm-4 p-3 position-relative justify-content-center" closeButton>
        <Modal.Title>
          <img src={logoVerticaleTheme}/>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-sm-4 p-3">
        <div className="row g-3 align-items-center">
          <div className="col-lg-4 col-md-6 text-center">
            <img src={scoreCardModalImg}/>
          </div>
          <div className="col-lg-8 col-md-6">
            <div className="title fs-6 fw-medium">Scorecard Picture Service</div>
            <div className="dec fs-14 mt-1 text-muted">We will convert your physical scorecard digitally and upload it to your profile</div>
            <ul className="p-0 m-0 list-unstyled d-flex flex-column gap-sm-4 gap-3 mt-sm-4 mt-3">
              <li className="">
                <div className="d-flex align-items-center gap-1 fs-14 fw-medium"><img src={golfIcon}/> STEP 1:</div>
                <div className="fs-12 text-muted fw-medium">Take a picture of your scorecard once you finish your round?</div>
              </li>
              <li className="">
                <div className="d-flex align-items-center gap-1 fs-14 fw-medium"><img src={golfIcon}/> STEP 2:</div>
                <div className="fs-12 text-muted fw-medium">Upload the image of your scorecard to theGolf app.</div>
              </li>
              <li className="">
                <div className="d-flex align-items-center gap-1 fs-14 fw-medium"><img src={golfIcon}/> STEP 3:</div>
                <div className="fs-12 text-muted fw-medium">Our team will digitally convert your scorecard and connect it to your account</div>
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal> */}
    </Main>

  );
}
export default Handicap;