// useGeolocation.js
import { useState, useEffect } from 'react';

const useGeolocation = () => {
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    error: null
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check for existing location in localStorage on mount
    const storedLocation = JSON.parse(localStorage.getItem('userLocation'));
    if (storedLocation) {
      setLocation(storedLocation);
    }
    
    // Geolocation API
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(success, errorHandler);
    } else {
      setError("Geolocation is not supported by your browser.");
    }

    // Optional: Start tracking location changes
    const watchId = navigator.geolocation.watchPosition(success, errorHandler);

    // Cleanup function to stop watching
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  const success = (position) => {
    const { latitude, longitude } = position.coords;
    const newLocation = { latitude, longitude };
    setLocation(newLocation);
    localStorage.setItem('userLocation', JSON.stringify(newLocation));
    setError(null); // Clear any previous error
  };

  const errorHandler = (err) => {
    switch (err.code) {
      case err.PERMISSION_DENIED:
        setError("User denied the request for Geolocation.");
        break;
      case err.POSITION_UNAVAILABLE:
        setError("Location information is unavailable.");
        break;
      case err.TIMEOUT:
        setError("The request to get user location timed out.");
        break;
      case err.UNKNOWN_ERROR:
        setError("An unknown error occurred.");
        break;
      default:
        setError("An unexpected error occurred.");
    }
  };

  return { location, error };
};

export default useGeolocation;
