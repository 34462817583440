import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { Button, Col, Input, Row, Select } from "antd";
import Search from "antd/lib/transfer/search";
import PostUser from "../assets/images/post-user.png";
import Post1 from "../assets/images/post-1.png";
import Comment from "../assets/images/comment.svg";
import Like from "../assets/images/like.svg";
import ScoreCard from "../assets/images/score-card.svg";
import avtarImg from "../assets/images/avtarImg.png";
import Modal from 'react-bootstrap/Modal';
import trophyIcon from "../assets/images/trophyIcon.png";
import backArrow from "../assets/images/backArrow.png";
import Form from 'react-bootstrap/Form';
import shareIcon from "../assets/images/shareIcon.png";
import gameImg from "../assets/images/gameImg.png";
import golfIcon from "../assets/images/golfIcon.png";
import useRequest from "../hooks/useRequest";
import { useNavigate, useLocation } from "react-router-dom";
import LeaderBoard from "./LeaderBoard";
function EventCreated() {
  const [profile, setProfile] = useState({})
  const { request } = useRequest();
  const location = useLocation();
  const setleaderData = location?.state?.response
  console.log("setLeaderData", setleaderData)
  const [detail, setDetail] = useState({})
  let Id = setleaderData?._id

  useEffect(() => {
    const fetchLeaderDetail = () => {
      if (Id) {
        request({
          url: `app/game/game-detail/${Id}`,
          method: "GET",
          onSuccess: (data) => {
            // console.log("Game Detail Data:", data.data);
            setDetail(data.data);
          },
          onError: (error) => {
            console.log("Error fetching game detail:", error);
          }
        });
      }
    };

    fetchLeaderDetail();
  }, []);
  useEffect(() => {
    setUserProfile();
  }, [])
  const setUserProfile = () => {
    request({
      url: "app/user/get-profile",
      method: "GET",
      onSuccess: (data) => {
        console.log("Profile data:", data.data.name);
        setProfile(data.data); // Assuming the API response contains the user profile data
      },
      onError: (error) => {
        console.log("Error fetching profile:", error);
      }
    });
  };
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false); // State variable for modal visibility

  // Function to handle modal visibility
  const handleModal = () => {
    console.log(isModalVisible, ">?>?>?>?>?>??????????????????????????????????????????/")
    setIsModalVisible(!isModalVisible); // Toggle modal visibility
  };
  return (
    <Main>
      <section className="home-wrap vh100 px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3 h-100 flex-column flex-nowrap">
          <div className="col-lg-12">
            <div className="pageHeadign d-flex align-items-center gap-3">
              <button onClick={() => navigate("/leader-board")} className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex">
                <img className="h-100" src={backArrow} />
              </button>
              <div className="txt">Event Created</div>
            </div>
          </div>
          <div className="col-12">
            <div className="row g-3">
              <div className="col-md-6">
                <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-12">
                  <div className="organizerProfile d-flex align-items-center justify-content-between">
                    <div className="organizerDetail d-flex align-items-center gap-2">
                      <div className="imgOuter rounded-circle overflow-hidden">
                        <img className="w-100 h-100" src={avtarImg} />
                      </div>
                      <div className="detail">
                        <div className="name">{profile.name}</div>
                        <div className="dec mt-1">{profile.email}</div>
                      </div>
                    </div>
                    <button type="button" className="btn btnShare btnTheme2 shadow-none rounded-circle p-2 d-flex align-items-center justify-content-between">
                      <img src={shareIcon} />
                    </button>
                  </div>
                  <div className="leaderProfile">
                    <div className="row g-2">
                      <div className="col-sm-6 text-start">
                        <div className="value">{setleaderData?.leaderName}</div>
                        <div className="title">Leaderboard Name</div>
                      </div>
                      <div className="col-sm-6 text-sm-end">
                        <div className="value">Individual Competition</div>
                        <div className="title">Competition Type</div>
                      </div>
                      <div className="col-sm-6 text-start">
                        <div className="value">0</div>
                        <div className="title">Golfers Joined</div>
                      </div>
                      <div className="col-sm-6 text-sm-end">
                        <div className="value">{setleaderData?.leaderPassword}</div>
                        <div className="title">Password</div>
                      </div>
                    </div>
                  </div>
                  <hr className="border-top border-secondary m-0" />
                  <div className="gameDetail d-flex align-items-center gap-2">
                    <div className="imgOuter">
                      <img className="w-100 h-100" src={gameImg} />
                    </div>
                    <div className="detail">
                      <div className="gameTitle">{detail?.courseData?.name}</div>
                      <div className="gameScore d-flex align-items-center gap-1 my-1">{detail?.courseTee?.name}</div>
                      <div className="competitionSize">{detail?.holes}holes</div>
                    </div>
                  </div>
                  <div className="btnGroup d-flex flex-column flex-sm-row align-items-center gap-3">
                    <button type="button" onClick={handleModal} className="btn btnStyle1 btnOutlineTheme1 btnWidth shadow-none px-3 w-100">Observe Only</button>
                    <button type="button" onClick={() => navigate("/players-setup", { state: { response: detail } })} className="btn btnStyle1 btnTheme1 btnWidth shadow-none px-3 w-100">Create</button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="eventcard h-100 rounded-3 bg-white d-flex flex-column gap-3">
                  <div className="cardHeadign">How to join Leaderboard?</div>
                  <hr className="border-top border-secondary m-0" />
                  <div className="howToDetail">
                    <div className="title d-flex align-items-center">
                      <img style={{ width: "24px", height: "24px" }} src={golfIcon} />
                      Share the Leader board link:
                    </div>
                    <div className="dec mt-1">Give all participants the Leader board's name and password or share with them the Leader board link.</div>
                  </div>
                  <div className="howToDetail">
                    <div className="title d-flex align-items-center">
                      <img style={{ width: "24px", height: "24px" }} src={golfIcon} />
                      Add Score:
                    </div>
                    <div className="dec mt-1">Participants who join can add their some from their phone while they play.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        
      </section>
      <Modal
        className="event-modal"
        title="Basic Modal"
        show={isModalVisible}
        onHide={handleModal}
        footer={[
          <Button key="cancel" onClick={handleModal}>
            Cancel
          </Button>,
        ]}
      >
        <div className="modal-header-o">
            <h4>Observe Only</h4>
        </div>
        <div className="userCard event-modal">
        <div className="gameDetail d-flex align-items-center gap-2">
                    <div className="imgOuter">
                      <img className="w-100 h-100" src={gameImg} />
                    </div>
                    <div className="detail userDetail">
                       <div className="label">LeaderName</div>
                      <div className="gameTitle"> {detail?.leaderName}</div> 
                      <div className="competitionSize">{detail?.holes}holes</div>
                    </div>
                  </div>
          
        </div>
      </Modal>
    </Main>
  );
}



export default EventCreated;
