import {
  Row,
  Col,
  Button,
  Link,
  Typography
} from "antd";
import React, { useState, useContext, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import { DeleteOutlined } from "@ant-design/icons";
import avtarImg from "../assets/images/avtarImg.png";
import useRequest from "../../src/hooks/useRequest"
import { Severty, ShowToast } from "../helper/toast";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
function Notification() {
  const navigate = useNavigate();
  const { request } = useRequest();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [notificationList, setNotificationList] = useState([])
  const [notificationIdToDelete, setNotificationIdToDelete] = useState(null);

  useEffect(() => {

    notificationlist()
    handleCardClick()
  }, [])

  const notificationlist = () => {
    request({
      url: "app/notification/get-list?limit=500",
      method: "GET",
      onSuccess: (data) => {
        console.log("onSuccesss", data.data.docs)
        setNotificationList(data.data.docs)
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const calculateTimeDifference = (created_at) => {
    const createdDate = new Date(created_at);
    const currentDate = new Date();
    const timeDifference = currentDate - createdDate;
    const secondDifference = Math.floor(timeDifference / 1000);
    const minute = 60;
    const hour = 60 * minute;
    const day = 24 * hour;

    let timeAgo;

    if (secondDifference < minute) {
      timeAgo = `${secondDifference} seconds ago`;
    } else if (secondDifference < hour) {
      const minutes = Math.floor(secondDifference / minute);
      timeAgo = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (secondDifference < day) {
      const hours = Math.floor(secondDifference / hour);
      timeAgo = `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const month = String(createdDate.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
      const dayOfMonth = String(createdDate.getDate()).padStart(2, '0'); // Add leading zero if necessary
      const year = createdDate.getFullYear();
      timeAgo = `${month}-${dayOfMonth}-${year}`;
    }
    return timeAgo;
  }

  const handleDeleteBlock = (notificationId) => {
    setNotificationIdToDelete(notificationId);
    setShowDeleteConfirmation(true)
  }

  const confirmDelete = (notificationId) => {
    setShowDeleteConfirmation(true);
    handleDelete(notificationIdToDelete)
  }

  const cancelDelete = () => {
    setShowDeleteConfirmation(false)
  }

  const handleDelete = (notificationId) => {
    request({
      url: `app/notification/delete-notification?notificationId=${notificationId}`,
      method: "DELETE",
      onSuccess: (data) => {
        notificationlist()
        setShowDeleteConfirmation(false)
      },
      onError: (error) => {
        console.log("Error in delete function", error)
      }
    })
  }

  const handleCardClick = (notificationId) => {
    request({
      url: `app/notification/read-notification`,
      method: "PUT",
      onSuccess: (data) => {
        notificationlist()
      },
      onError: (error) => {
        console.log("Error in delete function", error)
      }
    })
  }

  return (
    <Main>
      <section className="home-wrap px-lg-4 px-3 pb-4lg-4 pb-3 container-fluid">
        <div className="row g-3">
          <div className="col-lg-12">
            <div className="pageHeadign d-flex align-items-center gap-3">
              <div className="txt">Notifications</div>
            </div>
          </div>
          <div className="col-12">
  <div className="notificationOuter bg-white rounded-3 overflow-hidden">
    {notificationList && notificationList.length > 0 ? (
      notificationList.map((el, id) => {
        const notificationClasses = `notificationItem p-sm-3 p-2 pb-0 ${el.is_read ? 'read' : 'unread'}`;

        return (
          <div key={id} className={notificationClasses}>
            <div className="d-flex justify-content-between gap-2 border-bottom pb-sm-3 pb-2">
              <div className="d-flex gap-2">
                <div className="imgOuter rounded-circle overflow-hidden" onClick={() => {
                  if (el.type === "Post") {
                    // Navigate to the "/post" page
                    navigate("/post");
                  } else if (el.type === "Friend") {
                    // Navigate to the "friend" page
                    navigate("/friends");
                  }
                }}>
                  <img className="w-100 h-100" src={
                    el?.image
                      ? `https://b2btobacco.s3.amazonaws.com/${el?.image}`
                      : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                        el?.title?.substring(0, 2)
                      )}&size=256`
                  } alt={el?.title?.substring(0, 2)} />
                </div>
                <div className="detail">
                  <div className="dec">{el.title}</div>
                  {/* <div className="dec">{el.message}</div> */}
                  <div className="date mt-1">{calculateTimeDifference(el?.created_at)}</div>
                </div>
              </div>
              <div>
                <Button onClick={() => handleDeleteBlock(el?._id)}><DeleteOutlined /></Button>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="text-center">No Data found</div>
    )}
  </div>
</div>


        </div>
      </section>

      <Modal show={showDeleteConfirmation} onHide={cancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    </Main>
  );
}



export default Notification;
